import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, ErrorSummary, Button, Checkboxes, Input, Select as SimpleSelect, InsetText, ActionLink, Label } from "nhsuk-react-components";
import SelectOther from "../../../components/selectOther/SelectOther.component";
import ImplantInstanceList from "../../../components/implantInstanceList/ImplantInstanceList.component";
import EntitySelect from '../../../components/entitySelect/EntitySelect.component';
import ImplantHistoryDrawer from "../../../components/implantHistoryDrawer/ImplantHistoryDrawer.component";
import {
    listImplantCentres,
    listImplantCentresByIds
} from '../../../actions/implantCentreActions';
import {
    listFollowupCentres,
    listFollowupCentresByIds
} from '../../../actions/followupCentreActions';

const ImplantDetails = ({
    updatedPatient,
    onPatientImplantDetailsChange,
    error,
}) => {
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);



    const handleDeviceListConditionChange = useCallback((deviceList) => {
        onPatientImplantDetailsChange({
            ...updatedPatient,
            abondonedHardware: deviceList.isAbandonedHardware,
            mrConditional: deviceList.isMRIConditional,
            deviceList
        });
    }, [updatedPatient, onPatientImplantDetailsChange]);


    const handleCheckboxChange = useCallback((field, value) => {
        onPatientImplantDetailsChange({
            ...updatedPatient,
            [field]: value
        });
    }, [updatedPatient, onPatientImplantDetailsChange]);

    return (
        <Card>
            <Card.Content>
                <Card.Heading className="nhsuk-heading-m">
                    Implant Details
                </Card.Heading>
                {error && (
                    <ErrorSummary>
                        <ErrorSummary.Title id="error-summary-title">
                            There is a problem
                        </ErrorSummary.Title>
                        <ErrorSummary.Body>
                            <ul className="nhsuk-list nhsuk-list--bullet">
                                {Array.isArray(error) ? error.map((err, index) => (
                                    <li key={index}>{err}</li>
                                )) : <li>{error}</li>}
                            </ul>
                        </ErrorSummary.Body>
                    </ErrorSummary>
                )}

                <ImplantInstanceList
                    patientNHSNumber={updatedPatient.nhsNumber}
                    patientFullName={updatedPatient.forename ? `${updatedPatient.forename} ${updatedPatient.surname}` : ""}
                    onDeviceListConditionChange={handleDeviceListConditionChange}
                />

                <Checkboxes>


                    <Checkboxes.Box
                        conditional={<p>Active High Voltage Therapy.</p>}
                        checked={updatedPatient?.activeHighVoltageTherapy}
                        onChange={(e) => handleCheckboxChange('activeHighVoltageTherapy', e.target.checked)}
                    >
                        Active High Voltage Therapy?
                    </Checkboxes.Box>
                    <Checkboxes.Box
                        conditional={<p>Patient received appropriate HV therapy.</p>}
                        checked={updatedPatient?.patientReceivedAppropriateHVTherapy}
                        onChange={(e) => handleCheckboxChange('patientReceivedAppropriateHVTherapy', e.target.checked)}
                    >
                        Patient received appropriate HV therapy?
                    </Checkboxes.Box>
                    <Checkboxes.Box
                        conditional={<p>Patient received inappropriate HV therapy.</p>}
                        checked={updatedPatient?.patientReceivedInappropriateHVTherapy}
                        onChange={(e) => handleCheckboxChange('patientReceivedInappropriateHVTherapy', e.target.checked)}
                    >
                        Patient received inappropriate HV therapy?
                    </Checkboxes.Box>
                    <Checkboxes.Box
                        conditional={<p>Patient under active advisory.</p>}
                        checked={updatedPatient?.patientUnderActiveAdvisory}
                        onChange={(e) => handleCheckboxChange('patientUnderActiveAdvisory', e.target.checked)}
                    >
                        Patient under active advisory?
                    </Checkboxes.Box>
                </Checkboxes>

                <SelectOther
                    id="bradyprogrammedmode"
                    label="Brady Programmed Mode"
                    name="bradyProgrammedMode"
                    value={updatedPatient?.bradyProgrammedMode}
                    onChange={(e) => onPatientImplantDetailsChange({ ...updatedPatient, bradyProgrammedMode: e.target.value })}
                >
                    <SimpleSelect.Option value="">Select an option</SimpleSelect.Option>
                    <SimpleSelect.Option value="AAIR">AAIR</SimpleSelect.Option>
                    <SimpleSelect.Option value="VVI">VVI</SimpleSelect.Option>
                    <SimpleSelect.Option value="DDD">DDD</SimpleSelect.Option>
                    <SimpleSelect.Option value="AOO">AOO</SimpleSelect.Option>
                    <SimpleSelect.Option value="VDD">VDD</SimpleSelect.Option>
                    <SimpleSelect.Option value="DOO">DOO</SimpleSelect.Option>
                    <SimpleSelect.Option value="VVT">VVT</SimpleSelect.Option>
                </SelectOther>

                <div>
                    
                    {!['AAIR', 'VVI', 'DDD', 'AOO', 'VDD', 'DOO', 'VVT'].includes(updatedPatient?.bradyProgrammedMode) ? (
                        <Input
                            id="input-tachytherapyzonerate"
                            label="Tachy Therapy Zone Rate"
                            name="tachyTherapyZoneRate"
                            type="number"
                            value={updatedPatient?.tachyTherapyZoneRate}
                            onChange={(e) => onPatientImplantDetailsChange({ ...updatedPatient, tachyTherapyZoneRate: e.target.value })}
                        />
                    ) : (
                        <Input
                        id="input-bradyprogrammedlowerrate"
                        label="Brady Programmed Lower Rate"
                        name="bradyProgrammedLowerRate"
                        type="number"
                        value={updatedPatient?.bradyProgrammedLowerRate}
                        onChange={(e) => onPatientImplantDetailsChange({ ...updatedPatient, bradyProgrammedLowerRate: e.target.value })}
                    />
                    )
                
                }
                </div>
                <EntitySelect
                    title={"Implant Centres"}
                    singularEntity="implantCentre"
                    pluralEntity="implantCentres"
                    constantPrefix="IMPLANT_CENTRE"
                    listAction={listImplantCentres}
                    listByIdsAction={listImplantCentresByIds}
                    selectedIds={updatedPatient?.implantCentreIds || []}
                    onChange={(selectedImplantCentres) => {
                        const implantCentreIds = selectedImplantCentres.map(centre => centre.id);
                        onPatientImplantDetailsChange({
                            ...updatedPatient,
                            implantCentreIds,
                            implantCentreEntities: selectedImplantCentres
                        });
                    }}
                    summaryKeys={['name', 'address', 'city', 'country']}
                />

                <EntitySelect
                    title={"Followup Centres"}
                    singularEntity="followupCentre"
                    pluralEntity="followupCentres"
                    constantPrefix="FOLLOWUP_CENTRE"
                    listAction={listFollowupCentres}
                    listByIdsAction={listFollowupCentresByIds}
                    selectedIds={updatedPatient?.followUpCentreIds || []}
                    onChange={(selectedFollowupCentres) => {
                        const followUpCentreIds = selectedFollowupCentres.map(centre => centre.id);
                        onPatientImplantDetailsChange({
                            ...updatedPatient,
                            followUpCentreIds,
                            followUpCentreEntities: selectedFollowupCentres
                        });
                    }}
                    summaryKeys={['name', 'address', 'city', 'country']}
                />

                <InsetText id="implant-history">
                    <p>Browse Practician Notes and Alerts</p>
                    <ActionLink
                        href="#implant-history"
                        onClick={() => {
                            if (updatedPatient.nhsNumber) {
                                setOpenDrawer(true);
                            } else {
                                alert("Please provide a valid NHS number");
                            }
                        }}
                    >
                        Side notes / Alerts
                    </ActionLink>
                </InsetText>
            </Card.Content>

            <ImplantHistoryDrawer
                patientNHSNumber={updatedPatient.nhsNumber}
                patientFullName={updatedPatient.forename ? `${updatedPatient.forename} ${updatedPatient.surname}` : ""}
                patientEmail={updatedPatient.email}
                isOpen={openDrawer}
                closeDrawer={() => setOpenDrawer(false)}
            />
        </Card>
    );
};

export default ImplantDetails;