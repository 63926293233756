import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import card from nhsuk-frontend/packages/components/card/card";
// You can import components from the global module
import { ActionLink, Button, Card, CareCard, ContentsList, WarningCallout, InsetText, BackLink, Tag } from 'nhsuk-react-components';
import { FaCheck, FaD, FaDatabase, FaUser, FaUserGroup, FaUsersViewfinder } from "react-icons/fa6";
import { FcAddressBook, FcBusinessman, FcDataBackup, FcSettings, FcSurvey } from "react-icons/fc";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaBuilding, FaCheckCircle, FaCottonBureau, FaCreditCard, FaCube, FaDotCircle, FaEnvelope, FaFile, FaPassport } from "react-icons/fa";
import FeatureCard from "../components/featureCard/featureCard.component";


function SettingsScreen({ history }) {

    // user info from redux store
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        document.title = "Settings"; 
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            {userInfo && userInfo.roles.includes("UnauthorizedUser") && (
                                <WarningCallout label="Warning callout">
                                    <WarningCallout.Label>Important</WarningCallout.Label>
                                    <p>Hi {userInfo.username},</p>
                                    <p>Your account is being reviewed by an administrator. You will receive a confirmation by email to access the system once your account is approved.</p>
                                </WarningCallout>
                            )}
    
                            <h2 className="mt-0 header-title">My Heart Passport Configuration</h2>
                            <BackLink
                                asElement="a"
                                href="#"
                                onClick={() => history.goBack()}
                            >
                                Return back
                            </BackLink>
    
                            <div className="cards-grid">

                                <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaCube}
                                    title="Aetiologies"
                                    link="/aetiologies"
                                />
                                <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaCube}
                                    title="Symptoms"
                                    link="/symptoms"
                                />
                                <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaCube}
                                    title="Implant Registry"
                                    link="/implants"
                                />
                                <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaCube}
                                    title="Implant Centres"
                                    link="/implant_centres"
                                />
                                <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaCube}
                                    title="Followup Centres"
                                    link="/followup_centres"
                                />
                                <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaCube}
                                    title="ECG Indications"
                                    link="/ecgs"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <h2 className="mt-0 header-title">System Configuration</h2>
                            <div className="cards-grid">
                            <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaBuilding}
                                    title="Organisations"
                                    link="/organisations"
                                />

                                <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaEnvelope}
                                    title="Email Settings"
                                    link="/settings/email"
                                />
                                <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaDatabase}
                                    title="Import/Export"
                                    link="/settings/data_import_export"
                                />
                                <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaFile}
                                    title="Templates"
                                    link="/settings/templates"
                                />
                                <FeatureCard
                                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                                    currentUserRole={userInfo?.roles[0]}
                                    Icon={FaCheckCircle}
                                    title="User Consents"
                                    link="/settings/consents"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default SettingsScreen;