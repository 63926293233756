export const SHORT_URL_LIST_REQUEST = 'SHORT_URL_LIST_REQUEST'
export const SHORT_URL_LIST_SUCCESS = 'SHORT_URL_LIST_SUCCESS'
export const SHORT_URL_LIST_FAIL = 'SHORT_URL_LIST_FAIL'

export const SHORT_URL_DETAILS_REQUEST = 'SHORT_URL_DETAILS_REQUEST'
export const SHORT_URL_DETAILS_SUCCESS = 'SHORT_URL_DETAILS_SUCCESS'
export const SHORT_URL_DETAILS_FAIL = 'SHORT_URL_DETAILS_FAIL'
export const SHORT_URL_DETAILS_RESET = 'SHORT_URL_DETAILS_RESET'

export const SHORT_URL_DELETE_REQUEST = 'SHORT_URL_DELETE_REQUEST'
export const SHORT_URL_DELETE_SUCCESS = 'SHORT_URL_DELETE_SUCCESS'
export const SHORT_URL_DELETE_FAIL = 'SHORT_URL_DELETE_FAIL'

export const SHORT_URL_CREATE_REQUEST = 'SHORT_URL_CREATE_REQUEST'
export const SHORT_URL_CREATE_SUCCESS = 'SHORT_URL_CREATE_SUCCESS'
export const SHORT_URL_CREATE_FAIL = 'SHORT_URL_CREATE_FAIL'
export const SHORT_URL_CREATE_RESET = 'SHORT_URL_CREATE_RESET'


export const SHORT_URL_UPDATE_REQUEST = 'SHORT_URL_UPDATE_REQUEST'
export const SHORT_URL_UPDATE_SUCCESS = 'SHORT_URL_UPDATE_SUCCESS'
export const SHORT_URL_UPDATE_FAIL = 'SHORT_URL_UPDATE_FAIL'
export const SHORT_URL_UPDATE_RESET = 'SHORT_URL_UPDATE_RESET'

export const SHORT_URL_REQUEST = 'SHORT_URL_REQUEST'
export const SHORT_URL_SUCCESS = 'SHORT_URL_SUCCESS'
export const SHORT_URL_FAIL = 'SHORT_URL_FAIL'
export const SHORT_URL_RESET = 'SHORT_URL_RESET'

export const SHORT_URL_COUNT_REQUEST = "SHORT_URL_COUNT_REQUEST";
export const SHORT_URL_COUNT_SUCCESS = "SHORT_URL_COUNT_SUCCESS";
export const SHORT_URL_COUNT_FAIL = "SHORT_URL_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'