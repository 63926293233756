import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Details, Button, DateInput, InsetText, Textarea, ErrorSummary, Select as SimpleSelect, Checkboxes } from 'nhsuk-react-components';
import { listImplantHistoriesByPatientNhsNumber, createImplantHistory, updateImplantHistory, deleteImplantHistory, getImplantHistoryDetails } from '../../actions/implantHistoryActions';
import { getImplantDetailsByBarcode } from '../../actions/implantActions';
// constants 
import { IMPLANT_HISTORY_CREATE_RESET, IMPLANT_HISTORY_UPDATE_RESET } from '../../constants/implantHistoryConstants';
import Pagination from '../pagination/Pagination.component';
import AppSpinner from '../spinner/AppSpinner.component';
import ISODateInput from '../date/ISODateInput.component';

import Callout from '../callout/Callout.component';
import { Checkbox, Drawer } from 'antd';
import { IMPLANT_LIST_REQUEST } from '../../constants/implantConstants';

import { Card } from 'antd';

import SelectOther from '../selectOther/SelectOther.component';
import { formatISODate } from '../../utils/functions';

import EmailSender from '../email/EmailSender.component';

const ImplantHistoryDrawer = ({ patientNHSNumber, patientFullName, patientEmail, isOpen, closeDrawer }) => {
    const dispatch = useDispatch();


    // reset the state of the implantHistoryCreate and implantHistoryUpdate
    useEffect(() => {
        dispatch({ type: IMPLANT_HISTORY_CREATE_RESET });
        dispatch({ type: IMPLANT_HISTORY_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/implantHistory/all?page=1&limit=2&filter=Email&query=gmail
    const [implantHistoryListFilter, setimplantHistoryListFilter] = useState({ query: patientNHSNumber, page: 1, filter: 'patientNHSNumber' });

    // get implantHistory list by patientNhsNumber from redux store
    const implantHistoryListByNhsNumber = useSelector((state) => state.implantHistoryListByNhsNumber);
    const { implantHistories, loading, error, currentPage, totalPages } = implantHistoryListByNhsNumber;

    // get implantHistory create from redux store
    const implantHistoryCreate = useSelector((state) => state.implantHistoryCreate);
    const { success: successCreate, error: implantHistoryCreateErrors, loading: implantHistoryCreateLoading } = implantHistoryCreate;

    // get implantHistory update from redux store
    const implantHistoryUpdate = useSelector((state) => state.implantHistoryUpdate);
    const { success: successUpdate, loading: implantHistoryUpdateProcessing } = implantHistoryUpdate;

    // get implantHistory delete from redux store
    const implantHistoryDelete = useSelector((state) => state.implantHistoryDelete);
    const { success: successDelete } = implantHistoryDelete;

    // get implantHistory details from redux store
    const implantHistoryDetails = useSelector((state) => state.implantHistoryDetails);
    const { implantHistory: implantHistoryDet, loading: implantHistoryDetailsLoading } = implantHistoryDetails;

    // newImplantHistory state
    const [newImplantHistory, setNewImplantHistory] = useState({});
    const [updatedImplantHistory, setUpdatedImplantHistory] = useState({});
    const [editMode, setEditMode] = useState(false);

    // Drawers
    const [openChildDrawer, setOpenChildDrawer] = useState(false);

    const [autoFillDetail, setAutoFillDetail] = useState(true);

    const showChildDrawer = () => {
        setOpenChildDrawer(true);
    };

    const closeChildDrawer = () => {
        setOpenChildDrawer(false);
    };

    // listen to filter changes
    useEffect(() => {
        dispatch(listImplantHistoriesByPatientNhsNumber(patientNHSNumber, implantHistoryListFilter));
    }, [dispatch, implantHistoryListFilter, patientNHSNumber]);

    // handle page change
    const handlePageChange = (newPage) => {
        setimplantHistoryListFilter({ ...implantHistoryListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setimplantHistoryListFilter({ ...implantHistoryListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setimplantHistoryListFilter({ ...implantHistoryListFilter, filter: selectedFilter });
    };

    // listen to implantHistoryDet 
    useEffect(() => {
        if (implantHistoryDet) {
            // use spread operator to copy the implantHistoryDet object
            setUpdatedImplantHistory({ ...implantHistoryDet });
        }
    }, [implantHistoryDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newImplantHistory state
            handleResetNewImplantDetails();
        }
    }, [editMode]);

    // lisen to successCreate
    useEffect(() => {
        if (successCreate) {
            handleResetNewImplantDetails();
            dispatch(listImplantHistoriesByPatientNhsNumber(patientNHSNumber, {}));
        }
    }, [successCreate]);


    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this implantHistory?')) {
            dispatch(deleteImplantHistory(id)).then(() => {
                dispatch(listImplantHistoriesByPatientNhsNumber(patientNHSNumber, {}));
            }
            );
        }
    };

    // handle edit
    const handleEditImplantHistory = (id) => {
        dispatch(getImplantHistoryDetails(id));
        setEditMode(true);
        showChildDrawer();

    };

    // handle create
    const handleCreateImplantHistory = () => {
        dispatch(createImplantHistory(newImplantHistory));
    };

    // handle update
    const handleUpdateImplantHistory = () => {
        dispatch(updateImplantHistory(updatedImplantHistory.id, updatedImplantHistory)).then(() => {
            setUpdatedImplantHistory(updatedImplantHistory);
            setEditMode(true);
            dispatch(getImplantHistoryDetails(updatedImplantHistory.id));
            dispatch(listImplantHistoriesByPatientNhsNumber(patientNHSNumber, {}));
        }
        );
    };
    // handle get implant by barcode
    const handleGetImplantByBarcode = (barcode) => {
        dispatch(getImplantDetailsByBarcode(barcode));
    };

    const handleResetNewImplantDetails = () => {
        setNewImplantHistory({
            patientName: patientFullName ? patientFullName : '',
            patientNHSNumber: patientNHSNumber,
            eventDate: new Date().toISOString(),
            eventType: '',
            eventDetails: '',
            practitionerDetails: '',
            notes: '',
            interventionCategory: ''
        });
    }

    const handleCreateImplantButtonClick = () => {
        handleResetNewImplantDetails();
        setEditMode(false);
        showChildDrawer();
    }



    return (
        <Drawer title="Notes" width={520} closable={false} onClose={closeDrawer} open={isOpen}>
            <InsetText>

                <h3>{patientFullName || "-"}</h3>
                <h4>NHS Number: {patientNHSNumber}</h4>
                <Button
                    onClick={() => {
                        handleCreateImplantButtonClick();
                    }}
                >
                    + Create a new Note
                </Button>
            </InsetText>
            <Fieldset>
                {implantHistories && implantHistories.length > 0 ? (
                    implantHistories.map((implantHistory) => (
                        <Card style={{ marginBottom: 10, backgroundColor: '#feffe6' }}>
                            {implantHistory.isPatientVisible && (
                                <Tag>Visible to patient</Tag>
                            )
                            }
                            {implantHistory.eventType.includes('Implant') &&
                                (
                                    <Tag color="green">Implanted</Tag>
                                )}
                            {implantHistory.eventType.includes('Revision') &&
                                (
                                    <Tag color="orange">Revised</Tag>
                                )}
                            {implantHistory.eventType.includes('Removal') &&
                                (
                                    <Tag color="red">Removed</Tag>
                                )}
                            {implantHistory.eventType.includes('Extraction') &&
                                (
                                    <Tag color="red">Extracted</Tag>
                                )}

                            {" "} on {implantHistory.eventDate.slice(0, 10)} {" at "} {implantHistory.eventDate.slice(11, 16)}

                            <div className="nhsuk-u-padding-top-3"></div>


                            <Details key={implantHistory.id}>
                                <Details.Summary>
                                    <strong style={{ color: implantHistory.eventType.includes('Implant') ? 'green' : implantHistory.eventType.includes('Revision') ? 'orange' : 'red' }}>
                                        {`${implantHistory.eventType} - ${implantHistory.interventionCategory}`}
                                    </strong>
                                </Details.Summary>

                                <Details.Text>
                                    <p>
                                        <Details.Text>
                                            <p>
                                                <strong>Implant Status:</strong> {implantHistory.eventType.includes('Implant') ? <Tag color="green">{implantHistory.eventType}</Tag> : implantHistory.eventType.includes('Revision') ? <Tag color="orange">{implantHistory.eventType}</Tag> : <Tag color="red">{implantHistory.eventType}</Tag>}
                                            </p>
                                            <p>
                                                <strong>Intervention Category:</strong> {implantHistory.interventionCategory}
                                            </p>

                                        </Details.Text>
                                    </p>
                                    <p>
                                        <strong>Date Added:</strong> {formatISODate(implantHistory.eventDate, true)}
                                    </p>
                                    <p>
                                        <strong>Event Details:</strong> {implantHistory.eventDetails || "-"}
                                    </p>
                                    <p>
                                        <strong>Practitioner Details:</strong> {implantHistory.practitionerDetails || "-"}
                                    </p>
                                    <p>
                                        <strong>Notes:</strong> {implantHistory.notes || "-"}
                                    </p>

                                    <div className="d-flex justify-content-between">
                                        <Button
                                            disabled={implantHistoryDetailsLoading}
                                            onClick={() =>
                                                handleEditImplantHistory(implantHistory.id)
                                            }
                                        >
                                            Edit
                                        </Button>
                                        {" "}
                                        <Button
                                            secondary

                                            onClick={() => handleDelete(implantHistory.id)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </Details.Text>
                            </Details>
                        </Card>

                    ))
                ) : (
                    null
                )}

            </Fieldset>
            {loading ? (
                <AppSpinner />
            ) : error ? (
                <p>{error}</p>
            ) : (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                />

            )}

            <Drawer
                title="Note details"
                width={400}
                closable={false}
                onClose={closeChildDrawer}
                open={openChildDrawer}
            >
                {editMode ? (
                    <div>
                        <h2>Edit Note/Alert</h2>
                        <Checkboxes name="checkboxes">

                            <Checkboxes.Box
                                checked={updatedImplantHistory?.isPatientVisible}
                                onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, isPatientVisible: e.target.checked })}
                            >
                                Visible to patient?
                            </Checkboxes.Box>
                        </Checkboxes>

                        <SimpleSelect
                            id="interventionCategory"
                            label="Intervention Category"
                            name="interventionCategory"
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                setUpdatedImplantHistory({ ...updatedImplantHistory, interventionCategory: selectedValue });
                            }}
                            value={updatedImplantHistory?.interventionCategory}
                            required
                        >
                            <SimpleSelect.Option value="">Select an intervention category</SimpleSelect.Option>
                            <SimpleSelect.Option value="No intervention (FU only of pt implanted elsewhere)">No intervention (FU only of pt implanted elsewhere)</SimpleSelect.Option>
                            <SimpleSelect.Option value="First ever pacemaker/ICD system">First ever pacemaker/ICD system</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator change only">Generator change only</SimpleSelect.Option>
                            <SimpleSelect.Option value="New generator and lead(s) revision/replacement">New generator and lead(s) revision/replacement</SimpleSelect.Option>
                            <SimpleSelect.Option value="Upgrade - increased functionality (to DDD, CRT, or ICD)">Upgrade - increased functionality (to DDD, CRT, or ICD)</SimpleSelect.Option>
                            <SimpleSelect.Option value="Downgrade - decreased functionality">Downgrade - decreased functionality</SimpleSelect.Option>
                            <SimpleSelect.Option value="System explant">System explant</SimpleSelect.Option>
                            <SimpleSelect.Option value="Wound revision/generator resite/haematoma">Wound revision/generator resite/haematoma</SimpleSelect.Option>
                            <SimpleSelect.Option value="Lead intervention only">Lead intervention only</SimpleSelect.Option>
                            <SimpleSelect.Option value="Monitor procedure only">Monitor procedure only</SimpleSelect.Option>
                            <SimpleSelect.Option value="Other">Other</SimpleSelect.Option>
                        </SimpleSelect>





                        <Input
                            id="patientName"
                            label="Patient Name"
                            name="patientName"
                            type="text"
                            disabled
                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, patientName: e.target.value })}
                            value={updatedImplantHistory?.patientName}
                            required
                        />
                        <Input
                            id="patientNHSNumber"
                            label="Patient NHS Number"
                            name="patientNHSNumber"
                            disabled
                            type="text"
                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, patientNHSNumber: e.target.value })}
                            value={updatedImplantHistory?.patientNHSNumber}
                            required
                        />
                        <SelectOther
                            id="eventType"
                            label="Event Type"
                            name="eventType"
                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, eventType: e.target.value })}
                            value={updatedImplantHistory?.eventType}
                            required
                        >
                            <SimpleSelect.Option value="">Select an event type</SimpleSelect.Option>
                            <SimpleSelect.Option value="Implant">Implant</SimpleSelect.Option>
                            <SimpleSelect.Option value="Revision">Revision</SimpleSelect.Option>
                            <SimpleSelect.Option value="Removal">Removal</SimpleSelect.Option>
                            <SimpleSelect.Option value="Lead Extraction">Lead Extraction</SimpleSelect.Option>
                            <SimpleSelect.Option value="Lead Implant">Lead Implant</SimpleSelect.Option>
                            <SimpleSelect.Option value="Lead Revision">Lead Revision</SimpleSelect.Option>
                            <SimpleSelect.Option value="Lead Removal">Lead Removal</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator Implant">Generator Implant</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator Revision">Generator Revision</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator Removal">Generator Removal</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator Replacement">Generator Replacement</SimpleSelect.Option>
                            <SimpleSelect.Option value="Patient Report">Patient Report</SimpleSelect.Option>

                        </SelectOther>



                        <ISODateInput
                            id="updateEventDate"
                            label="Event Date"
                            name="eventDate"
                            value={updatedImplantHistory?.eventDate}
                            onChange={(date) => setUpdatedImplantHistory({ ...updatedImplantHistory, eventDate: date })}
                            required
                            includeTime={true}
                        />
                        <Textarea
                            id="eventDetails"
                            label="Event Details"
                            name="eventDetails"
                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, eventDetails: e.target.value })}
                            value={updatedImplantHistory?.eventDetails}
                        />
                        <Textarea
                            id="PractitionerDetails"
                            label="Practitioner Details"
                            name="PractitionerDetails"
                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, practitionerDetails: e.target.value })}
                            value={updatedImplantHistory?.practitionerDetails}
                        />
                        <Textarea
                            id="Notes"
                            label="Notes"
                            name="Notes"
                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, notes: e.target.value })}
                            value={updatedImplantHistory?.notes}
                        />

                        


                        <EmailSender
                            enabled={updatedImplantHistory?.isPatientVisible}
                            emailToId={patientEmail}
                            emailToName={patientFullName}
                            emailSubject={`Implant History - ${updatedImplantHistory?.eventType || "Notification"}`}
                            emailBody={`Implant History - ${updatedImplantHistory?.eventType || "Notification"}. 
An event of type ${updatedImplantHistory?.eventType} occurred on ${formatISODate(updatedImplantHistory?.eventDate, true)}. Event details:
${updatedImplantHistory?.eventDetails || "N/A"}. 
This was recorded by ${updatedImplantHistory?.practitionerDetails}. 
Additional notes: ${updatedImplantHistory?.notes || "N/A"} `}
                            title="Send Patient a Notification"
                            disabledMessage="Not visible to patient"
                            disabledTooltip='This note is not visible to the patient. Please make it visible to the patient to send them a notification.'


                        />
                        {(successCreate || successUpdate) ?
                            <Callout
                                label="Success"
                                header="Success!"
                                autoDismiss={true}
                                dismissTime={5000}
                                time={new Date().toLocaleTimeString()}
                                title="Success!"
                                message={successCreate ? "The note/alert was created successfully." : "The note/alert was updated successfully."}
                                mode="success"
                            />
                            : null
                        }

                        <Button
                            disabled={implantHistoryUpdateProcessing}
                            onClick={handleUpdateImplantHistory}
                        >
                            Save changes
                        </Button>


                    </div>
                ) : (

                    <div>
                        <h2>+ New Note/Alert</h2>
                        {implantHistoryCreateErrors && (
                            <ErrorSummary>
                                <ErrorSummary.Title id="error-summary-title">
                                    There is a problem
                                </ErrorSummary.Title>
                                <ErrorSummary.Body>
                                    <ul>
                                        {implantHistoryCreateErrors.map((error, index) => (
                                            <li key={index}>
                                                {error}
                                            </li>
                                        ))}
                                    </ul>
                                </ErrorSummary.Body>
                            </ErrorSummary>
                        )}

                        <Checkboxes name="checkboxes">

                            <Checkboxes.Box
                                checked={updatedImplantHistory?.isPatientVisible}
                                onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, isPatientVisible: e.target.checked })}
                            >
                                Visible to patient?
                            </Checkboxes.Box>
                        </Checkboxes>


                        <SelectOther
                            id="interventionCategory"
                            label="Intervention Category"
                            name="interventionCategory"
                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, interventionCategory: e.target.value })}
                            value={newImplantHistory?.interventionCategory}
                            required
                        >


                            <SimpleSelect.Option value="">Select an intervention category</SimpleSelect.Option>
                            <SimpleSelect.Option value="First ever pacemaker/ICD system">No intervention (FU only of pt implanted elsewhere)</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator change only">First ever pacemaker/ICD system</SimpleSelect.Option>
                            <SimpleSelect.Option value="New generator and lead(s) revision/replacement">Generator change only</SimpleSelect.Option>
                            <SimpleSelect.Option value="Upgrade - increased functionality (to DDD, CRT, or ICD)">New generator and lead(s) revision/replacement</SimpleSelect.Option>
                            <SimpleSelect.Option value="Downgrade - decreased functionality">Upgrade - increased functionality (to DDD, CRT, or ICD)</SimpleSelect.Option>
                            <SimpleSelect.Option value="System explant">Downgrade - decreased functionality</SimpleSelect.Option>
                            <SimpleSelect.Option value="Wound revision/generator resite/haematoma">System explant</SimpleSelect.Option>
                            <SimpleSelect.Option value="Lead intervention only">Wound revision/generator resite/haematoma</SimpleSelect.Option>
                            <SimpleSelect.Option value="Monitor procedure only">Lead intervention only</SimpleSelect.Option>
                            <SimpleSelect.Option value="Other">Monitor procedure only</SimpleSelect.Option>
                        </SelectOther>



                        <Input
                            id="create-patientName"
                            label="Patient Name"
                            name="createPatientName"
                            disabled
                            type="text"
                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, patientName: e.target.value })}
                            value={newImplantHistory?.patientName || patientFullName}
                            required
                        />
                        <Input
                            id="patientNHSNumber"
                            label="Patient NHS Number"
                            name="patientNHSNumber"
                            disabled
                            type="text"
                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, patientNHSNumber: e.target.value })}
                            value={newImplantHistory?.patientNHSNumber || patientNHSNumber}
                            required
                        />
                        <SelectOther
                            id="eventType"
                            label="Event Type"
                            name="eventType"
                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, eventType: e.target.value })}
                            value={newImplantHistory?.eventType}
                            required
                        >
                            <SimpleSelect.Option value="">Select an event type</SimpleSelect.Option>
                            <SimpleSelect.Option value="Implant">Implant</SimpleSelect.Option>
                            <SimpleSelect.Option value="Revision">Revision</SimpleSelect.Option>
                            <SimpleSelect.Option value="Removal">Removal</SimpleSelect.Option>
                            <SimpleSelect.Option value="Lead Extraction">Lead Extraction</SimpleSelect.Option>
                            <SimpleSelect.Option value="Lead Implant">Lead Implant</SimpleSelect.Option>
                            <SimpleSelect.Option value="Lead Revision">Lead Revision</SimpleSelect.Option>
                            <SimpleSelect.Option value="Lead Removal">Lead Removal</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator Replacement">Lead Replacement</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator Implant">Generator Implant</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator Revision">Generator Revision</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator Removal">Generator Removal</SimpleSelect.Option>
                            <SimpleSelect.Option value="Generator Replacement">Generator Replacement</SimpleSelect.Option>
                            <SimpleSelect.Option value="Patient Report">Patient Report</SimpleSelect.Option>
                        </SelectOther>

                        <ISODateInput
                            id="eventDate"
                            label="Event Date"
                            name="eventDate"
                            value={newImplantHistory?.eventDate}
                            onChange={(date) => setNewImplantHistory({ ...newImplantHistory, eventDate: date })}
                            required
                            includeTime={true}
                        />
                        <Textarea
                            id="eventDetails"
                            label="Event Details"
                            name="eventDetails"
                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, eventDetails: e.target.value })}
                            value={newImplantHistory?.eventDetails}
                        />
                        <Textarea
                            id="PractitionerDetails"
                            label="Practitioner Details"
                            name="PractitionerDetails"
                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, practitionerDetails: e.target.value })}
                            valfue={newImplantHistory?.practitionerDetails}
                        />
                        <Textarea
                            id="Notes"
                            label="Notes"
                            name="Notes"
                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, notes: e.target.value })}
                            value={newImplantHistory?.notes}
                        />

                        {(successCreate || successUpdate) && (
                            <Callout
                                label="Success"
                                header="Success!"
                                autoDismiss={true}
                                dismissTime={5000}
                                time={new Date().toLocaleTimeString()}
                                title="Success!"
                                message={successCreate ? "The implantHistory was created successfully." : "The implantHistory was updated successfully."}
                                mode="success"
                            />
                        )}

                        {implantHistoryCreateErrors && (
                            <Callout
                                label="Error"
                                header="There is a problem"
                                autoDismiss={true}
                                dismissTime={5000}
                                time={new Date().toLocaleTimeString()}
                                title="There is a problem"
                                message={"An error occurred while creating the note/alert. Please scroll up to see the error message."}
                                mode="error"
                            />
                        )}



                        <Button
                            disabled={implantHistoryCreateLoading}
                            onClick={handleCreateImplantHistory}
                        >
                            Create a new note
                        </Button>
                    </div>
                )}


            </Drawer>
        </Drawer>);
}


export default ImplantHistoryDrawer;