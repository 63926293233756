import React, { useState, useEffect } from 'react';
import Modal from '../modal/Modal.component';

const Callout = ({ 
    mode = 'info', 
    autoDismiss = false, 
    dismissTime = 5000, 
    header, 
    message,
    infoMessage,
    actionHandler, 
    actionLinkEnabled = false, 
    actionLabel 
}) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isDismissing, setIsDismissing] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);

    const colors = {
        success: {
            bg: '#DFF2EA',
            border: '#268756',
            text: '#0B4A2D',
            icon: '✓'
        },
        warning: {
            bg: '#FFF7E5',
            border: '#FFC107',
            text: '#663C00',
            icon: '!'
        },
        error: {
            bg: '#FBE9E7',
            border: '#D32F2F',
            text: '#711111',
            icon: '×'
        },
        info: {
            bg: '#E3F2FD',
            border: '#2196F3',
            text: '#0D3C61',
            icon: 'i'
        }
    };

    const currentTheme = colors[mode];

    useEffect(() => {
        if (autoDismiss) {
            const timeout = setTimeout(() => {
                handleDismiss();
            }, dismissTime);
            return () => clearTimeout(timeout);
        }
    }, [autoDismiss, dismissTime]);

    const handleDismiss = (e) => {
        e?.stopPropagation(); // Stop click event from bubbling if event exists
        setIsDismissing(true);
        setTimeout(() => {
            setIsVisible(false);
        }, 300);
    };

    const handleCardClick = () => {
        if (infoMessage) {
            setShowInfoModal(true);
        }
    };

    if (!isVisible) return null;

    return (
        <>
            <div
                onClick={handleCardClick}
                style={{
                    position: 'relative',
                    padding: '16px 40px 16px 56px',
                    borderRadius: '8px',
                    backgroundColor: currentTheme.bg,
                    borderLeft: `4px solid ${currentTheme.border}`,
                    color: currentTheme.text,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    marginBottom: '16px',
                    transition: 'all 0.3s ease',
                    opacity: isDismissing ? 0 : 1,
                    transform: isDismissing ? 'translateX(10px)' : 'translateX(0)',
                    overflow: 'hidden',
                    cursor: infoMessage ? 'pointer' : 'default',
                    ...(infoMessage && {
                        ':hover': {
                            boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                            transform: 'translateY(-1px)'
                        }
                    })
                }}
            >
                {/* Icon */}
                <div
                    style={{
                        position: 'absolute',
                        left: '16px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        backgroundColor: currentTheme.border,
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    {infoMessage ? '?' : currentTheme.icon}
                </div>

                {/* Close button */}
                <button
                    onClick={handleDismiss}
                    type="button"
                    style={{
                        position: 'absolute',
                        top: '12px',
                        right: '12px',
                        background: 'none',
                        border: 'none',
                        color: currentTheme.text,
                        opacity: 0.6,
                        cursor: 'pointer',
                        padding: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'opacity 0.2s ease',
                        zIndex: 1, // Ensure it's above the clickable card
                        ':hover': {
                            opacity: 1
                        }
                    }}
                >
                    <span style={{ fontSize: '18px' }}>×</span>
                </button>

                {/* Content */}
                <div>
                    {header && (
                        <div style={{
                            fontSize: '16px',
                            fontWeight: '600',
                            marginBottom: message ? '4px' : 0
                        }}>
                            {header}
                        </div>
                    )}
                    {message && (
                        <div style={{
                            fontSize: '14px',
                            opacity: 0.9
                        }}>
                            {message}
                        </div>
                    )}
                    {actionLinkEnabled && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent card click
                                actionHandler();
                            }}
                            style={{
                                background: 'none',
                                border: 'none',
                                color: currentTheme.border,
                                padding: '8px 0',
                                marginTop: '8px',
                                cursor: 'pointer',
                                fontWeight: '500',
                                fontSize: '14px',
                                transition: 'opacity 0.2s ease',
                                opacity: 0.9,
                                zIndex: 1, // Ensure it's above the clickable card
                                ':hover': {
                                    opacity: 1,
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            {actionLabel}
                        </button>
                    )}
                </div>
            </div>

            {/* Info Modal */}
            <Modal
                show={showInfoModal}
                handleClose={() => setShowInfoModal(false)}
                theme="light"
            >
                <div style={{ padding: '20px' }}>
                    <h3 style={{ 
                        color: currentTheme.border, 
                        marginBottom: '15px',
                        fontSize: '20px'
                    }}>
                        {header}
                    </h3>
                    <p style={{ 
                        fontSize: '16px',
                        lineHeight: '1.6',
                        color: '#333'
                    }}>
                        {infoMessage}
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default Callout;