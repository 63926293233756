import {

    ORGANISATION_LIST_REQUEST,
    ORGANISATION_LIST_SUCCESS,
    ORGANISATION_LIST_FAIL,

    ORGANISATION_DETAILS_REQUEST,
    ORGANISATION_DETAILS_SUCCESS,
    ORGANISATION_DETAILS_FAIL,

    ORGANISATION_DELETE_REQUEST,
    ORGANISATION_DELETE_SUCCESS,
    ORGANISATION_DELETE_FAIL,

    ORGANISATION_CREATE_REQUEST,
    ORGANISATION_CREATE_SUCCESS,
    ORGANISATION_CREATE_FAIL,
    ORGANISATION_CREATE_RESET,

    ORGANISATION_UPDATE_REQUEST,
    ORGANISATION_UPDATE_SUCCESS,
    ORGANISATION_UPDATE_FAIL,
    ORGANISATION_UPDATE_RESET,

    ORGANISATION_COUNT_SUCCESS,
    ORGANISATION_COUNT_REQUEST,
    ORGANISATION_COUNT_FAIL,

    SET_CURRENT_PAGE,

} from "../constants/organisationConstants";

const initialState = {
    organisations: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const organisationListReducer = (
    state = {
        organisations: []
    }, action) => {
    switch (action.type) {
        case ORGANISATION_LIST_REQUEST:
            return { loading: true, organisations: [] };
        case ORGANISATION_LIST_SUCCESS:
            // our state will have a list of organisations after the request
            return {
                loading: false,
                organisations: action.payload,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case ORGANISATION_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const organisationCountReducer = (state = { organisationCounts: [] }, action) => {
  switch (action.type) {
    case ORGANISATION_COUNT_REQUEST:
      return { loading: true, organisationCounts: [] };
    case ORGANISATION_COUNT_SUCCESS:
      return { loading: false, organisationCounts: action.payload };
    case ORGANISATION_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const organisationDetailsReducer = (
    state = {
        organisation: { comments: [] }
    }, action) => {
    switch (action.type) {
        case ORGANISATION_DETAILS_REQUEST:
            return { loading: true, ...state };
        case ORGANISATION_DETAILS_SUCCESS:
            // our state will have a list of organisations after the request
            return { loading: false, organisation: action.payload };
        case ORGANISATION_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const organisationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ORGANISATION_DELETE_REQUEST:
            return { loading: true }

        case ORGANISATION_DELETE_SUCCESS:
            return { loading: false, success: true }

        case ORGANISATION_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const organisationCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ORGANISATION_CREATE_REQUEST:
            return { loading: true }

        case ORGANISATION_CREATE_SUCCESS:
            return { loading: false, success: true, organisation: action.payload }

        case ORGANISATION_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case ORGANISATION_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const organisationUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case ORGANISATION_UPDATE_REQUEST:
            return { loading: true }

        case ORGANISATION_UPDATE_SUCCESS:
            return { loading: false, success: true, organisation: action.payload }

        case ORGANISATION_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case ORGANISATION_UPDATE_RESET:
            return { organisation: {} }

        default:
            return state
    }
}