import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEMPLATE_DETAILS_REQUEST } from '../../constants/templateConstants';
import { getTemplateDetailsByName } from '../../actions/templateActions';
import { Button } from "nhsuk-react-components";
import axios from 'axios';

// Import html2pdf library
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';

const PhysicalCard = ({ emailId, recipientFullName, nhsNumber, birthdate, etiologies, symptoms, ecgIndications, mriConditional, generator, leads }) => {
  // reset states
  const dispatch = useDispatch();

  // get the physicalCard template from the store
  const templateDetailsByName = useSelector((state) => state.templateDetailsByName);
  const { loading, error, template } = templateDetailsByName;

  const [card, setCard] = useState({});
  const [zoomLevel, setZoomLevel] = useState(100); // initial zoom level
  const [sendingEmail, setSendingEmail] = useState(false);

  // dispatch the action to get the template
  useEffect(() => {
    dispatch(getTemplateDetailsByName('physicalCard'));
  }, [dispatch]);

  // listen to template
  useEffect(() => {
    if (template && template.htmlContent) {
      // replace props with template values
      var cardContent = template.htmlContent;
      cardContent = cardContent.replace('{recipientFullName}', recipientFullName);
      cardContent = cardContent.replace('{nhsNumber}', nhsNumber);
      cardContent = cardContent.replace('{birthdate}', birthdate);
      cardContent = cardContent.replace('{etiologies}', etiologies || '-');
      cardContent = cardContent.replace('{symptoms}', symptoms || '-');
      cardContent = cardContent.replace('{ecgIndications}', ecgIndications || '-');
      cardContent = cardContent.replace('{mriConditional}', mriConditional);
      cardContent = cardContent.replace('{generator}', generator || '-');
      cardContent = cardContent.replace('{leads}', leads || '-');
      setCard(cardContent);
    }
  }, [template, nhsNumber, birthdate, etiologies, symptoms, ecgIndications, mriConditional, generator, leads]);

  const generatePDF = () => {
    return new Promise((resolve, reject) => {
      const element = document.getElementById('card-content');
      const options = {
        filename: 'physical_card.pdf',
        jsPDF: { unit: 'mm', format: [172, 108.5], orientation: 'l' },
        Image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
      };

      html2pdf().from(element).set(options).outputPdf('blob')
        .then(pdf => {
          resolve(pdf);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const downloadPDF = async () => {
    try {
      const pdf = await generatePDF();
      const url = URL.createObjectURL(pdf);
      const link = document.createElement('a');
      link
        .setAttribute('href', url);
      link

        .setAttribute('download', 'physical_card.pdf');
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };


  const sendEmail = async () => {
    const pdf = await generatePDF();

    const pdfBase64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(pdf);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });

    const email = {
      "EmailToId": emailId,
      "EmailToName": recipientFullName,
      "EmailSubject": "Your Physical Card",
      "EmailBody": `Dear ${recipientFullName},<br> Please find your physical card attached.<br><br> Best regards,<br>Your Healthcare Team`,
      "Attachments": [
      {
        "Content": pdfBase64,
        "FileName": nhsNumber + "_physical_card.pdf",
        "ContentType": "application/pdf"
      }
      ]
    };

    try {
      setSendingEmail(true);
      await axios.post("/api/v1/auth/sendemail", email, {
        headers: { 'Content-Type': 'application/json' }
      });
      alert("Email sent successfully!");
    } catch (error) {
      console.error(error);
      alert("Failed to send email: " + error.message);
    } finally {
      setSendingEmail(false);
    }
  };


  const zoomStyle = {
    transform: `scale(${zoomLevel / 100})`,
    transformOrigin: 'top left',
  };

  return (
    <React.Fragment>
      <div>
        <Button onClick={downloadPDF}>Download PDF</Button>
        {'  '}
        <Button onClick={sendEmail} disabled={sendingEmail}>
          {sendingEmail ? "Sending..." : "Send as Email"}
        </Button>

      </div>
      {(loading || !card) ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h3>{error}</h3>
      ) : (
        <div id="card-content" style={zoomStyle} className="card-content" dangerouslySetInnerHTML={{ __html: card }} />
      )}
    </React.Fragment>
  );
};

export default PhysicalCard;
