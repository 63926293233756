import {

    SYMPTOM_LIST_REQUEST,
    SYMPTOM_LIST_SUCCESS,
    SYMPTOM_LIST_FAIL,

    SYMPTOM_DETAILS_REQUEST,
    SYMPTOM_DETAILS_SUCCESS,
    SYMPTOM_DETAILS_FAIL,

    SYMPTOM_DELETE_REQUEST,
    SYMPTOM_DELETE_SUCCESS,
    SYMPTOM_DELETE_FAIL,

    SYMPTOM_CREATE_REQUEST,
    SYMPTOM_CREATE_SUCCESS,
    SYMPTOM_CREATE_FAIL,
    SYMPTOM_CREATE_RESET,

    SYMPTOM_UPDATE_REQUEST,
    SYMPTOM_UPDATE_SUCCESS,
    SYMPTOM_UPDATE_FAIL,
    SYMPTOM_UPDATE_RESET,

    SYMPTOM_COUNT_SUCCESS,
    SYMPTOM_COUNT_REQUEST,
    SYMPTOM_COUNT_FAIL,

    SYMPTOM_LIST_BYIDS_REQUEST,
    SYMPTOM_LIST_BYIDS_SUCCESS,
    SYMPTOM_LIST_BYIDS_FAIL,
    SYMPTOM_LIST_BYIDS_RESET,

    SET_CURRENT_PAGE,

} from "../constants/symptomConstants";

const initialState = {
    symptoms: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const symptomListReducer = (
    state = {
        symptoms: []
    }, action) => {
    switch (action.type) {
        case SYMPTOM_LIST_REQUEST:
            return { loading: true, symptoms: [] };
        case SYMPTOM_LIST_SUCCESS:
            // our state will have a list of symptoms after the request
            return {
                loading: false,
                symptoms: action.payload.symptoms,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case SYMPTOM_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const symptomListByIDsReducer = (
    state = {
        symptoms: []
    }, action) => {
    switch (action.type) {
        case SYMPTOM_LIST_BYIDS_REQUEST:
            return { loading: true, symptoms: [] };
        case SYMPTOM_LIST_BYIDS_SUCCESS:
            return { loading: false, symptoms: action.payload };
        case SYMPTOM_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case SYMPTOM_LIST_BYIDS_RESET:
            return { symptoms: [] };
        default:
            return state;
    }
}


export const symptomCountReducer = (state = { symptomCounts: [] }, action) => {
  switch (action.type) {
    case SYMPTOM_COUNT_REQUEST:
      return { loading: true, symptomCounts: [] };
    case SYMPTOM_COUNT_SUCCESS:
      return { loading: false, symptomCounts: action.payload };
    case SYMPTOM_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const symptomDetailsReducer = (
    state = {
        symptom: { comments: [] }
    }, action) => {
    switch (action.type) {
        case SYMPTOM_DETAILS_REQUEST:
            return { loading: true, ...state };
        case SYMPTOM_DETAILS_SUCCESS:
            // our state will have a list of symptoms after the request
            return { loading: false, symptom: action.payload };
        case SYMPTOM_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const symptomDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SYMPTOM_DELETE_REQUEST:
            return { loading: true }

        case SYMPTOM_DELETE_SUCCESS:
            return { loading: false, success: true }

        case SYMPTOM_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const symptomCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SYMPTOM_CREATE_REQUEST:
            return { loading: true }

        case SYMPTOM_CREATE_SUCCESS:
            return { loading: false, success: true, symptom: action.payload }

        case SYMPTOM_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case SYMPTOM_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const symptomUpdateReducer = (state = { symptom: {} }, action) => {
    switch (action.type) {
        case SYMPTOM_UPDATE_REQUEST:
            return { loading: true }

        case SYMPTOM_UPDATE_SUCCESS:
            return { loading: false, success: true, symptom: action.payload }

        case SYMPTOM_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SYMPTOM_UPDATE_RESET:
            return { symptom: {} }

        default:
            return state
    }
}