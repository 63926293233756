import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import Header from '../../components/header/Header.component'
import Footer from '../../components/footer/Footer.component'

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const appState = useSelector(state => state.app);
    
    return (
        <div>
            <Header />
            <div className="nhsuk-width-container-fluid" style={{padding: '20px'}}>
                <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-full">
                            {children}
                        </div>
                    </div>
                </main>
            </div>            
            <Footer />
        </div>
    );
};

const mapStateToProps = state => ({
    app: state.app
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
