export const IMPLANT_HISTORY_LIST_REQUEST = 'IMPLANT_HISTORY_LIST_REQUEST'
export const IMPLANT_HISTORY_LIST_SUCCESS = 'IMPLANT_HISTORY_LIST_SUCCESS'
export const IMPLANT_HISTORY_LIST_FAIL = 'IMPLANT_HISTORY_LIST_FAIL'


export const IMPLANT_HISTORY_DETAILS_REQUEST = 'IMPLANT_HISTORY_DETAILS_REQUEST'
export const IMPLANT_HISTORY_DETAILS_SUCCESS = 'IMPLANT_HISTORY_DETAILS_SUCCESS'
export const IMPLANT_HISTORY_DETAILS_FAIL = 'IMPLANT_HISTORY_DETAILS_FAIL'


export const IMPLANT_HISTORY_DELETE_REQUEST = 'IMPLANT_HISTORY_DELETE_REQUEST'
export const IMPLANT_HISTORY_DELETE_SUCCESS = 'IMPLANT_HISTORY_DELETE_SUCCESS'
export const IMPLANT_HISTORY_DELETE_FAIL = 'IMPLANT_HISTORY_DELETE_FAIL'

export const IMPLANT_HISTORY_CREATE_REQUEST = 'IMPLANT_HISTORY_CREATE_REQUEST'
export const IMPLANT_HISTORY_CREATE_SUCCESS = 'IMPLANT_HISTORY_CREATE_SUCCESS'
export const IMPLANT_HISTORY_CREATE_FAIL = 'IMPLANT_HISTORY_CREATE_FAIL'
export const IMPLANT_HISTORY_CREATE_RESET = 'IMPLANT_HISTORY_CREATE_RESET'


export const IMPLANT_HISTORY_UPDATE_REQUEST = 'IMPLANT_HISTORY_UPDATE_REQUEST'
export const IMPLANT_HISTORY_UPDATE_SUCCESS = 'IMPLANT_HISTORY_UPDATE_SUCCESS'
export const IMPLANT_HISTORY_UPDATE_FAIL = 'IMPLANT_HISTORY_UPDATE_FAIL'
export const IMPLANT_HISTORY_UPDATE_RESET = 'IMPLANT_HISTORY_UPDATE_RESET'

export const IMPLANT_HISTORY_REQUEST = 'IMPLANT_HISTORY_REQUEST'
export const IMPLANT_HISTORY_SUCCESS = 'IMPLANT_HISTORY_SUCCESS'
export const IMPLANT_HISTORY_FAIL = 'IMPLANT_HISTORY_FAIL'
export const IMPLANT_HISTORY_RESET = 'IMPLANT_HISTORY_RESET'

export const IMPLANT_HISTORY_COUNT_REQUEST = "IMPLANT_HISTORY_COUNT_REQUEST";
export const IMPLANT_HISTORY_COUNT_SUCCESS = "IMPLANT_HISTORY_COUNT_SUCCESS";
export const IMPLANT_HISTORY_COUNT_FAIL = "IMPLANT_HISTORY_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'