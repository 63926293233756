import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from "@ramonak/react-progress-bar";
import { Container, Row, Col } from 'react-bootstrap';
import { Table, BackLink, Card, Details, Button, Textarea, ErrorSummary, Checkboxes, Select, Input } from 'nhsuk-react-components';
import { createOrUpdateImplant } from '../../actions/implantActions';
import { loadImplantDto } from '../../actions/implantActions';
import { IMPLANT_CREATE_RESET } from '../../constants/implantConstants';
import { useCSVReader } from 'react-papaparse';
import { styles } from '../../utils/functions';
import axios from 'axios';
import Papa from 'papaparse';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export default function DataImportExportScreen({ history }) {
    const dispatch = useDispatch();
    const { CSVReader } = useCSVReader();

    const [deviceMappings, setDeviceMappings] = useState([]);
    const [csvRows, setCsvRows] = useState([]);
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [override, setOverride] = useState(true);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [importLogs, setImportLogs] = useState([]);
    const [currentRecord, setCurrentRecord] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [stopImport, setStopImport] = useState(false);
    const [importing, setImporting] = useState(false);
    const [mappingTableGenerated, setMappingTableGenerated] = useState(false);
    const [exportProgress, setExportProgress] = useState(0);

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const implantCreateOrUpdate = useSelector(state => state.implantCreateOrUpdate);
    const { error: implantCreateOrUpdateError, implant } = implantCreateOrUpdate;

    const implantDto = useSelector(state => state.implantDto);
    const { loading: implantDtoLoading, error: implantDtoError, implantDto: newImplantDto } = implantDto;

    useEffect(() => {
        document.title = "Data Import/Export";
        dispatch(loadImplantDto());
    }, [dispatch]);


    const generateMappingTable = useCallback((dto, headers) => {
        if (!dto || !dto.data || Object.keys(dto.data).length === 0 || !headers || headers.length === 0) {
            console.error('Invalid DTO or CSV headers:', { dto, headers });
            return;
        }

        const dtoProperties = Object.keys(dto.data);
        const newDeviceMappings = dtoProperties.map(dtoProperty => {
            const matchingCsvHeader = headers.find(header =>
                header.toLowerCase().replace(/[^a-z0-9]/g, '') === dtoProperty.toLowerCase().replace(/[^a-z0-9]/g, '')
            );
            let defaultValue = '';

            // Check if the property is a datetime field
            if (dto.data[dtoProperty] === 'DateTime') {
                defaultValue = new Date().toISOString();
            }

            return {
                dtoProperty,
                csvHeader: matchingCsvHeader || '',
                defaultValue,
                type: dto.data[dtoProperty]
            };
        });

        setDeviceMappings(newDeviceMappings);
        setMappingTableGenerated(true);
    }, []);


    useEffect(() => {
        if (newImplantDto && csvHeaders.length > 0) {
            generateMappingTable(newImplantDto, csvHeaders);
        }
    }, [newImplantDto, csvHeaders, generateMappingTable]);

    const handleMappingChange = (index, field, value) => {
        setDeviceMappings(prevMappings => {
            const updatedMappings = [...prevMappings];
            updatedMappings[index] = { ...updatedMappings[index], [field]: value.trim() };
            return updatedMappings;
        });
    };

    // listen to errors
    useEffect(() => {
        if (implantCreateOrUpdateError) {
            setImportLogs(prevLogs => [...prevLogs, {
                barcode: implant?.barcode,
                modelName: implant?.modelName,
                dateOfUpload: new Date().toISOString(),
                error: implantCreateOrUpdateError
            }]);
        }
    }, [implantCreateOrUpdateError]);



    const createImplantRecord = async () => {
        if (!deviceMappings || deviceMappings.length === 0 || !csvRows || csvRows.length === 0) {
            console.error('No data to import');
            return;
        }

        setImporting(true);
        setStopImport(false);
        setTotalRecords(csvRows.length);
        setCurrentRecord(0);
        setCurrentProgress(0);
        setImportLogs([]);

        for (let rowIndex = 0; rowIndex < csvRows.length; rowIndex++) {
            if (stopImport) {
                break;
            }
            

            const csvRow = csvRows[rowIndex];
            const rowDict = Object.fromEntries(csvHeaders.map((header, i) => [header.trim(), csvRow[i]?.trim()]));
            const newImplant = deviceMappings.reduce((acc, mapping) => {
                if (mapping.dtoProperty === 'id') {
                    // Skip the id field
                    return acc;
                }

                let value = mapping.csvHeader ? rowDict[mapping.csvHeader] : mapping.defaultValue;

                if (mapping.dtoProperty === 'implantStatusChangedDate') {
                    // Convert the date to ISO format
                    var date = new Date(value);
                    // if the date is invalid, set it to current date
                    if (!date || isNaN(date.getTime())) {
                        date = new Date();
                    }
                    value = date.toISOString();
                } else if (mapping.dtoProperty === 'implantStatusChanged' || mapping.dtoProperty === 'mrConditional') {
                    // Convert string 'true'/'false' to boolean
                    value = value?.toLowerCase() === 'true';
                }

                acc[mapping.dtoProperty] = value;
                return acc;
            }, {});

            try {
                await dispatch(createOrUpdateImplant(newImplant.barcode, newImplant));
            } catch (error) {
                const errorLog = {
                    barcode: newImplant.barcode,
                    modelName: newImplant.modelName,
                    dateOfUpload: new Date().toISOString(),
                    error: error.message
                };
                // Accumulate logs correctly
                setImportLogs(prevLogs => [...prevLogs, errorLog]);
            }
            
            setCurrentRecord(rowIndex + 1);
            setCurrentProgress(Math.round(((rowIndex + 1) / csvRows.length) * 100));

            await sleep(50);
        }

        setImporting(false);
    };

    const handleCsvExport = async () => {
        try {
            setExportProgress(0);
            const response = await axios.get('/api/v1/implant/export', {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setExportProgress(percentCompleted);
                },
            });

            const csvText = await response.data.text();
            const jsonData = Papa.parse(csvText, { header: true }).data;
            const sortedCsvData = Papa.unparse(jsonData, {
                quotes: true,
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ",",
                header: true,
                newline: "\r\n"
            });

            const blob = new Blob([sortedCsvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `implant_list_export_${new Date().toISOString().slice(0, 10)}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Export error:', error);
        } finally {
            setExportProgress(0);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Data Import/Export</h2>
                                </div>
                                <BackLink asElement="a" href="#" onClick={() => history.goBack()}>
                                    Return back
                                </BackLink>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Content>
                                    <Card.Description>
                                        <h3>Import Devices</h3>
                                        {implantDtoLoading && <p>Loading implant DTO...</p>}
                                        {implantDtoError && <ErrorSummary><p>Error loading implant DTO: {implantDtoError}</p></ErrorSummary>}
                                        {!implantDtoLoading && !implantDtoError && newImplantDto && (
                                            <>
                                                <Checkboxes name="checkboxes">
                                                    <Checkboxes.Box
                                                        id="override"
                                                        name="override"
                                                        value="override"
                                                        checked={override}
                                                        onChange={() => setOverride(!override)}
                                                    >
                                                        Override existing records based on barcode
                                                    </Checkboxes.Box>
                                                </Checkboxes>

                                                <CSVReader
                                                    onUploadAccepted={(results, file) => {
                                                        const fileSizeInMB = file.size / (1024 * 1024);
                                                        if (fileSizeInMB > 10) {
                                                            alert('File size exceeds the limit of 10 MB');
                                                            return;
                                                        }
                                                        const headers = results.data[0];
                                                        setCsvHeaders(headers);
                                                        setCsvRows(results.data.slice(1));
                                                    }}
                                                >
                                                    {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
                                                        <>
                                                            <div style={styles.csvReader}>
                                                                <button type='button' {...getRootProps()} style={styles.browseFile}>
                                                                    Browse file
                                                                </button>
                                                                <div style={styles.acceptedFile}>
                                                                    {acceptedFile && `${acceptedFile.name} (${(acceptedFile.size / (1024 * 1024)).toFixed(2)} MB)`}
                                                                </div>
                                                                <button {...getRemoveFileProps()} style={styles.remove}>
                                                                    Remove
                                                                </button>
                                                            </div>
                                                            <ProgressBar style={styles.progressBarBackgroundColor} />
                                                        </>
                                                    )}
                                                </CSVReader>

                                                {mappingTableGenerated && (
                                                    <Table>
                                                        <Table.Head>
                                                            <Table.Row>
                                                                <Table.Cell>Property</Table.Cell>
                                                                <Table.Cell>CSV Header Columns</Table.Cell>
                                                                <Table.Cell>Default Value (if not mapped)</Table.Cell>
                                                            </Table.Row>
                                                        </Table.Head>
                                                        <Table.Body>
                                                            {deviceMappings.map((mapping, index) => (
                                                                <Table.Row key={index}>
                                                                    <Table.Cell>
                                                                        {mapping.dtoProperty} <br /> <small>{newImplantDto.data[mapping.dtoProperty]}</small>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Select
                                                                            value={mapping.csvHeader}
                                                                            onChange={(e) => handleMappingChange(index, 'csvHeader', e.target.value)}
                                                                        >
                                                                            <Select.Option value="">Not mapped</Select.Option>
                                                                            {csvHeaders.map((csvHeader, idx) => (
                                                                                <Select.Option key={idx} value={csvHeader.trim()}>
                                                                                    {csvHeader}
                                                                                </Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Input
                                                                            type="text"
                                                                            name="default"
                                                                            value={mapping.defaultValue}
                                                                            onChange={(e) => handleMappingChange(index, 'defaultValue', e.target.value)}
                                                                        />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            ))}
                                                        </Table.Body>
                                                    </Table>
                                                )}
                                            </>
                                        )}
                                    </Card.Description>
                                    <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                                        <Button onClick={createImplantRecord} disabled={importing || !mappingTableGenerated}>
                                            Import
                                        </Button>
                                        <Button secondary onClick={() => { setStopImport(true); window.location.reload(); }} disabled={!importing}>
                                            Stop Import
                                        </Button>
                                    </div>

                                    <ProgressBar completed={currentProgress} />
                                    <p>Processed {currentRecord} of {totalRecords} records</p>
                                    <Details>
                                        <Details.Summary>View Logs</Details.Summary>
                                        <Details.Text>
                                            <Textarea
                                                name="failedRecords"
                                                rows={10}
                                                cols={50}
                                                readOnly
                                                value={importLogs.map((log) =>
                                                    `Barcode: ${log.barcode}, Model: ${log.modelName}, Upload Date: ${log.dateOfUpload}, Error: ${log.error}`
                                                ).join("\n")}
                                            />
                                        </Details.Text>
                                    </Details>
                                </Card.Content>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Content>
                                    <Card.Description>
                                        <h3>Export Devices</h3>
                                        <p>Export devices to CSV. You can download up to 50K records</p>
                                        <Button onClick={handleCsvExport} disabled={exportProgress > 0}>
                                            Export All Devices
                                        </Button>
                                        {exportProgress > 0 && (
                                            <div style={{ marginTop: '10px' }}>
                                                <ProgressBar completed={exportProgress} />
                                                <p>Exporting: {exportProgress}% complete</p>
                                            </div>
                                        )}
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

// Helper functions (can be moved to a separate file if preferred)
const csvToJson = (csvData) => {
    return Papa.parse(csvData, { header: true }).data;
};

const jsonToCsv = (jsonData) => {
    return Papa.unparse(jsonData, {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n"
    });
};