import React, { useState } from 'react';

const Item = ({ mode, children }) => {
    const colors = {
        success: {
            bg: '#DFF2EA',
            color: '#218838',
            border: '#28a745',
            icon: '✓'
        },
        error: {
            bg: '#FBE9E7',
            color: '#D32F2F',
            border: '#dc3545',
            icon: '✕'
        }
    };

    const theme = colors[mode] || colors.error;

    return (
        <li
            style={{
                color: theme.color,
                backgroundColor: theme.bg,
                padding: '12px 16px',
                borderRadius: '8px',
                margin: '8px 0',
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                transition: 'all 0.2s ease',
                cursor: 'default',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                border: `1px solid ${theme.border}15`,
                fontSize: '14px',
            }}
        >
            <span
                style={{
                    backgroundColor: theme.color,
                    color: 'white',
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '12px',
                    flexShrink: 0,
                }}
            >
                {theme.icon}
            </span>
            <span style={{ flex: 1 }}>{children}</span>
        </li>
    );
};

const Checklist = ({ heading, children, expand = true }) => {
    const [expanded, setExpanded] = useState(expand);

    const toggleExpanded = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    };

    return (
        <div
            itemID='checkList'
            style={{
                border: '1px solid rgba(0,94,184,0.1)',
                borderRadius: '12px',
                backgroundColor: 'white',
                position: 'relative',
                overflow: 'hidden',
                boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
                transition: 'all 0.3s ease',
            }}
        >
            <div
                style={{
                    backgroundColor: '#005eb8',
                    padding: '16px 20px',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid rgba(255,255,255,0.1)',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s ease',
                    ':hover': {
                        backgroundColor: '#004e96',
                    }
                }}
                onClick={toggleExpanded}
            >
                <span style={{ 
                    fontSize: '16px',
                    fontWeight: '500',
                }}>{heading}</span>
                <button
                    onClick={toggleExpanded}
                    style={{
                        background: 'rgba(255,255,255,0.1)',
                        border: 'none',
                        color: 'white',
                        padding: '6px 12px',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        transition: 'all 0.2s ease',
                        ':hover': {
                            background: 'rgba(255,255,255,0.2)',
                        }
                    }}
                >
                    <span style={{
                        transform: `rotate(${expanded ? '180deg' : '0deg'})`,
                        transition: 'transform 0.3s ease',
                        display: 'inline-block',
                    }}>
                        ▼
                    </span>
                    {expanded ? 'Collapse' : 'Expand'}
                </button>
            </div>
            <div
                style={{
                    maxHeight: expanded ? '1000px' : '0',
                    opacity: expanded ? 1 : 0,
                    overflow: 'hidden',
                    transition: 'all 0.3s ease',
                    padding: expanded ? '16px 20px' : '0 20px',
                }}
            >
                <ul style={{
                    listStyleType: 'none',
                    padding: 0,
                    margin: 0,
                }}>
                    {children}
                </ul>
            </div>
        </div>
    );
};

Checklist.Item = Item;

export default Checklist;