import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { FaUser, FaThList, FaQrcode } from "react-icons/fa";
import {
    Input, Button, WarningCallout, Select, Radios, BackLink, Tag,
    Card, Details, Fieldset, ActionLink, Checkboxes, SummaryList
} from "nhsuk-react-components";

import { convertNewlinesToBreaks } from "../../utils/functions";
import Pagination from "../../components/pagination/Pagination.component";

// Actions
import { getPatientByIdentityId, updatePatientConsentIds } from "../../actions/patientActions";
import { listEcgsByIds } from "../../actions/ecgActions";
import { listSymptomsByIds } from "../../actions/symptomActions";
import { listAetiologiesByIds } from "../../actions/aetiologyActions";
import { listFollowupCentresByIds } from "../../actions/followupCentreActions";
import { listImplantCentresByIds } from "../../actions/implantCentreActions";
import { listImplantsByIds } from "../../actions/implantActions";
import { listConsents } from "../../actions/consentActions";
import { getShortUrlDetailsbyUserId } from "../../actions/shortUrlActions";
import { listImplantHistoriesByPatientNhsNumber } from '../../actions/implantHistoryActions';

// Components
import Modal from "../../components/modal/Modal.component";
import QRCode from "react-qr-code";
import Callout from "../../components/callout/Callout.component";
import ImplantInstanceListReadOnly from "../../components/implantInstanceList/ImplantInstanceListReadOnly.component";

// Constants and Utils
import { PATIENT_UPDATE_RESET } from "../../constants/patientConstants";
import { convertToComponentDate } from "../../utils/functions";
import { Skeleton } from "antd";
import { formatISODate } from "../../utils/functions";
import ReadOnlyClinicalAlerts from "../../components/ClinicalAlerts/ReadOnlyClinicalAlerts.component";

import ConsentManagement from "./common/ConsentManagement";

export default function PatientProfileScreen({ history }) {
    const dispatch = useDispatch();

    // Document title
    useEffect(() => {
        document.title = "My Heart Passport";
        dispatch({ type: PATIENT_UPDATE_RESET });
    }, [dispatch]);

    // State
    const [targetDevice, setTargetDevice] = useState("android");
    const [consentModalShow, setConsentModalShow] = useState(false);
    const [genericModalShow, setGenericModalShow] = useState(false);
    const [consentModalContent, setConsentModalContent] = useState();
    const [genericModalContent, setGenericModalContent] = useState();
    const [passUrl, setPassUrl] = useState("");
    const [selectedData, setSelectedData] = useState({
        ecgs: [],
        symptoms: [],
        aetiologies: [],
        implants: [],
        implantCentres: [],
        followupCentres: [],
        consents: []
    });

    const [alertsFilter, setAlertsFilter] = useState({
        query: '',
        page: 1,
        filter: 'patientNHSNumber',
        limit: 5
    });

    const implantHistoryListByNhsNumber = useSelector((state) => state.implantHistoryListByNhsNumber);
    const {
        implantHistories,
        loading: loadingAlerts,
        currentPage: alertsCurrentPage,
        totalPages: alertsTotalPages
    } = implantHistoryListByNhsNumber;

    // Redux selectors
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const patientDetails = useSelector((state) => state.patientDetails);
    const { loading, error, patient } = patientDetails;

    const patientUpdateConsentIds = useSelector((state) => state.patientUpdateConsentIds);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = patientUpdateConsentIds;

    const consentList = useSelector((state) => state.consentList);
    const { consents } = consentList;

    const shortUrlDetailsByUserId = useSelector((state) => state.shortUrlDetailsByUserId);
    const { shortUrl: shortUrlByUserId } = shortUrlDetailsByUserId;

    const ecgListByIDs = useSelector((state) => state.ecgListByIDs);
    const { ecgs: selectedEcgs = [] } = ecgListByIDs;

    const symptomListByIDs = useSelector((state) => state.symptomListByIDs);
    const { symptoms: selectedSymptoms = [] } = symptomListByIDs;

    const aetiologyListByIDs = useSelector((state) => state.aetiologyListByIDs);
    const { aetiologies: selectedAetiologies = [] } = aetiologyListByIDs;


    const followupCentreListByIDs = useSelector((state) => state.followupCentreListByIDs);
    const { followupCentres: selectedFollowupCentres = [] } = followupCentreListByIDs;


    const implantCentreListByIDs = useSelector((state) => state.implantCentreListByIDs);
    const { implantCentres: selectedImplantCentres = [] } = implantCentreListByIDs;

    // Data loading effects
    useEffect(() => {
        if (userInfo?.userId) {
            dispatch(getPatientByIdentityId(userInfo.userId));
        }
    }, [dispatch, userInfo?.userId]);

    useEffect(() => {
        if (patient?.nhsNumber) {
            dispatch(listImplantHistoriesByPatientNhsNumber(patient.nhsNumber, alertsFilter));
        }
    }, [dispatch, patient?.nhsNumber, alertsFilter]);

    useEffect(() => {
        if (patient) {
            // Load only required data using ByIds actions
            if (patient.ecgIds?.length > 0) {
                dispatch(listEcgsByIds(patient.ecgIds));
            }
            if (patient.symptomIds?.length > 0) {
                dispatch(listSymptomsByIds(patient.symptomIds));
            }
            if (patient.aetiologyIds?.length > 0) {
                dispatch(listAetiologiesByIds(patient.aetiologyIds));
            }
            if (patient.followUpCentreIds?.length > 0) {
                dispatch(listFollowupCentresByIds(patient.followUpCentreIds));
            }
            if (patient.implantCentreIds?.length > 0) {
                dispatch(listImplantCentresByIds(patient.implantCentreIds));
            }
            if (patient.implantIds?.length > 0) {
                dispatch(listImplantsByIds(patient.implantIds));
            }

            dispatch(getShortUrlDetailsbyUserId(patient.id));
            dispatch(listConsents());
        }
    }, [dispatch, patient]);

    // URL Effect
    useEffect(() => {
        if (shortUrlByUserId?.shortCode) {
            setPassUrl(`/api/v1/ShortUrl/code/${shortUrlByUserId.shortCode}/${targetDevice}`);
        } else {
            setPassUrl("");
        }
    }, [shortUrlByUserId, targetDevice]);

    // Modal handlers
    const handleConsentClick = (consentId) => {
        const consent = consents.find(consent => consent.id === consentId);
        setConsentModalContent(consent);
        setConsentModalShow(true);
    };

    const handleEntityClick = (entity) => {
        if (!entity) return;
        setGenericModalContent(entity);
        setGenericModalShow(true);
    };

    // Constants for property filtering
    const consentPropertiesToIgnore = ["id", "patientConsents"];
    const genericPropertiesToIgnore = [
        "id", "patientEcgs", "patientSymptoms", "patientAetiologies",
        "patientImplants", "patientImplantCentres", "patientFollowUpCentres",
        "patientConsents", "patient"
    ];

    // Helper function for consent updates
    const handleConsentUpdate = () => {
        if (patient?.id) {
            dispatch(updatePatientConsentIds(patient.id, patient));
            setConsentModalShow(false);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {userInfo && !userInfo.roles.includes("Patient") ? (
                        <WarningCallout label="Warning callout">
                            <WarningCallout.Label>Patient information</WarningCallout.Label>
                            <p>Hi {userInfo.username},</p>
                            <p>Please note that access to this page requires "Patient" privilege. If you are a patient, kindly contact an administrator to have your account set up accordingly.</p>
                        </WarningCallout>
                    ) : (
                        <div className="nhsuk-grid-row">
                            <div className="welcome-title d-flex justify-content-between align-items-center">
                                <h3>My Profile</h3>
                                <BackLink asElement="a" href="#" onClick={() => history.goBack()}>
                                    Return back
                                </BackLink>
                            </div>

                            {/* Left Column - Patient Information */}
                            <div className="nhsuk-grid-column-one-half">
                                <div className="welcome-box">
                                    {patient?.identityUserId && (
                                        <ConsentManagement
                                            patient={patient}
                                        />
                                    )}


                                    {/* Patient Details Card */}
                                    <Card>
                                        <Card.Content>
                                            {loading && !patient ? (
                                                <Card.Heading className="nhsuk-heading-m">
                                                    Fetching patient details...
                                                </Card.Heading>
                                            ) : patient?.identityUserId ? (
                                                <>
                                                    <Card.Heading className="nhsuk-heading-m">
                                                        <div className="icon-container">
                                                            <FaUser size={30} />
                                                        </div>
                                                        {patient.forename} {patient.surname}
                                                    </Card.Heading>

                                                    <SummaryList>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Identity User</SummaryList.Key>
                                                            <SummaryList.Value>{patient?.identityUserId}</SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>NHS Number</SummaryList.Key>
                                                            <SummaryList.Value>{patient?.nhsNumber}</SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Date of Birth</SummaryList.Key>
                                                            <SummaryList.Value>
                                                                {patient?.dateOfBirth ? new Date(patient.dateOfBirth).toLocaleDateString() : '-'}
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Email</SummaryList.Key>
                                                            <SummaryList.Value>{patient?.email || '-'}</SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Phone Number</SummaryList.Key>
                                                            <SummaryList.Value>{patient?.phoneNumber || '-'}</SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>MR Conditional</SummaryList.Key>
                                                            <SummaryList.Value>{patient?.mrConditional ? "Yes" : "No"}</SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Abandoned Hardware</SummaryList.Key>
                                                            <SummaryList.Value>{patient?.abandonedHardware ? "Yes" : "No"}</SummaryList.Value>
                                                        </SummaryList.Row>

                                                        {/* ECGs */}
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>ECGs</SummaryList.Key>
                                                            <SummaryList.Value>
                                                                <ul className="nhsuk-list">
                                                                    {selectedEcgs?.map(ecg => (
                                                                        <li key={ecg.id}>
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); handleEntityClick(ecg); }}>
                                                                                {ecg.name}
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>

                                                        {/* Symptoms */}
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Symptoms</SummaryList.Key>
                                                            <SummaryList.Value>
                                                                <ul className="nhsuk-list">
                                                                    {selectedSymptoms?.map(symptom => (
                                                                        <li key={symptom.id}>
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); handleEntityClick(symptom); }}>
                                                                                {symptom.name}
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>

                                                        {/* Aetiologies */}
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Aetiologies</SummaryList.Key>
                                                            <SummaryList.Value>
                                                                <ul className="nhsuk-list">
                                                                    {selectedAetiologies?.map(aetiology => (
                                                                        <li key={aetiology.id}>
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); handleEntityClick(aetiology); }}>
                                                                                {aetiology.name}
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>

                                                        {/* Implant Centres */}
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Implant Centres</SummaryList.Key>
                                                            <SummaryList.Value>
                                                                <ul className="nhsuk-list">
                                                                    {selectedImplantCentres?.map(centre => (
                                                                        <li key={centre.id}>
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); handleEntityClick(centre); }}>
                                                                                {centre.name}
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>

                                                        {/* Followup Centres */}
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Followup Centres</SummaryList.Key>
                                                            <SummaryList.Value>
                                                                <ul className="nhsuk-list">
                                                                    {selectedFollowupCentres?.map(centre => (
                                                                        <li key={centre.id}>
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); handleEntityClick(centre); }}>
                                                                                {centre.name}
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </SummaryList.Value>
                                                        </SummaryList.Row>
                                                        {/* Clinical Details */}
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Brady Programmed Mode</SummaryList.Key>
                                                            <SummaryList.Value>{patient?.bradyProgrammedMode || '-'}</SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Brady Programmed Lower Rate</SummaryList.Key>
                                                            <SummaryList.Value>{patient?.bradyProgrammedLowerRate || '-'}</SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Tachy Therapy Zone Rate</SummaryList.Key>
                                                            <SummaryList.Value>{patient?.tachyTherapyZoneRate || '-'}</SummaryList.Value>
                                                        </SummaryList.Row>
                                                        <SummaryList.Row>
                                                            <SummaryList.Key>Active High Voltage Therapy</SummaryList.Key>
                                                            <SummaryList.Value>{patient?.activeHighVoltageTherapy ? "Yes" : "No"}</SummaryList.Value>
                                                        </SummaryList.Row>
                                                    </SummaryList>

                                                    <h4>My Implants</h4>
                                                    <ImplantInstanceListReadOnly
                                                        patientNHSNumber={patient.nhsNumber}
                                                    />

                                                    {/* Patient Summary Information */}
                                                    <SummaryList>
                                                        {/* Add your existing SummaryList.Row components here */}
                                                    </SummaryList>
                                                </>
                                            ) : (
                                                <Card.Description>
                                                    Please contact an administrator to have your account set up accordingly.
                                                </Card.Description>
                                            )}
                                        </Card.Content>
                                    </Card>
                                </div>
                            </div>

                            {/* Right Column - Pass Generation */}
                            <div className="nhsuk-grid-column-one-third" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Card style={{ padding: "10px" }}>
                                    <Card.Description>
                                        <Fieldset>
                                            <Radios name="device" inline id="inline-device" hint="Select the target device.">
                                                <Radios.Radio
                                                    value="android"
                                                    checked={targetDevice === "android"}
                                                    onChange={(e) => setTargetDevice(e.target.value)}
                                                >
                                                    Android
                                                </Radios.Radio>
                                                <Radios.Radio
                                                    value="ios"
                                                    onChange={(e) => setTargetDevice(e.target.value)}
                                                >
                                                    iOS
                                                </Radios.Radio>
                                            </Radios>
                                        </Fieldset>

                                        {passUrl ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                <div>
                                                    <div className="nhsuk-u-padding-top-3"></div>
                                                    <h2 style={{ textAlign: 'center' }}>Scan to Download Pass</h2>
                                                    <QRCode
                                                        size={256}
                                                        style={{ height: "auto", maxWidth: "100%", width: "100%", cursor: "pointer" }}
                                                        value={passUrl.includes(window.location.origin) ? passUrl : window.location.origin + passUrl}
                                                        viewBox={`0 0 256 256`}
                                                        onClick={() => window.open(passUrl, '_blank')}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div>
                                                    <p style={{ textAlign: 'center' }}><FaQrcode size={200} /></p>
                                                    <Callout
                                                        label="Warning"
                                                        mode="warning"
                                                        header="No pass generated"
                                                        message="Your organisation will generate a pass for you shortly. Please check back later."
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <ReadOnlyClinicalAlerts
                                            patientNHSNumber={patient?.nhsNumber}
                                            patientName={`${patient?.forename} ${patient?.surname}`}
                                            limit={5}
                                            showOnlyPatientVisible={true}
                                        />



                                    </Card.Description>
                                </Card>

                            </div>
                        </div>
                    )}
                </Container>
            </div>

            {/* Modals */}
            <Modal show={genericModalShow} handleClose={() => setGenericModalShow(false)}>
                {genericModalContent && (
                    <>
                        <SummaryList>
                            {Object.entries(genericModalContent)
                                .filter(([key]) => !genericPropertiesToIgnore.includes(key))
                                .map(([key, value]) => (
                                    <SummaryList.Row key={key}>
                                        <SummaryList.Key>
                                            {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                        </SummaryList.Key>
                                        <SummaryList.Value>
                                            {value?.toString() || "-"}
                                        </SummaryList.Value>
                                    </SummaryList.Row>
                                ))}
                        </SummaryList>
                        <Button onClick={() => setGenericModalShow(false)}>Close</Button>
                    </>
                )}
            </Modal>
        </React.Fragment>
    );
}