import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import { listUsers, getIdentityUserDetails } from '../../actions/userActions';
import Modal from '../modal/Modal.component';
import CompactUserCreation from './CompactUserCreation.component';

const IdentityUserSelect = ({
  onChange,
  value,
  primarySearchProp = 'userName',
  secondarySearchProp = 'email',
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const userList = useSelector((state) => state.userList);
  const { loading: loadingUsers, users, error: usersError } = userList;

  const identityUserDetails = useSelector((state) => state.identityUserDetails);
  const { identityUser } = identityUserDetails;

  useEffect(() => {
    dispatch(listUsers({ page: 1, limit: 10 }));
    dispatch(getIdentityUserDetails(value));
  }, [dispatch, value]);

  const debouncedSearch = debounce((searchValue) => {
    if (searchValue.length >= 2) {
      dispatch(listUsers({ query: searchValue, page: 1, limit: 10, filter: primarySearchProp }));
    }
  }, 300);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    debouncedSearch(newValue);
  };

  const handleChange = (selectedOption) => {
    if (selectedOption && selectedOption.value === 'create_new') {
      setModalOpen(true);
    } else if (selectedOption) {
      onChange({
        identityUserId: selectedOption.value,
        email: selectedOption.email
      });
    } else {
      onChange(null);
    }
  };

  const handleUserCreated = () => {
    // Refresh the user list
    dispatch(listUsers({ page: 1, limit: 10 }));
  };

  const closeModal = () => {
    setModalOpen(false);
    // Refresh the user list when modal is closed, in case a user was created
    dispatch(listUsers({ page: 1, limit: 10 }));
  };

  const createNewOption = {
    value: 'create_new',
    label: '+ Create New Patient Account',
  };

  const options = [
    createNewOption,
    ...(users ? users.map(user => ({
      value: user.id,
      label: `${user[primarySearchProp]} | ${user[secondarySearchProp]}`,
      email: user[secondarySearchProp]
    })) : [])
  ];

  const selectedValue = identityUser ? {
    value: identityUser.id,
    label: `${identityUser[primarySearchProp]} | ${identityUser[secondarySearchProp]}`,
    email: identityUser[secondarySearchProp]
  } : null;

  return (
    <div className="nhsuk-form-group">
      <label className="nhsuk-label" htmlFor="select-user">
        Email
      </label>
      <Select
        id="select-user"
        value={selectedValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        isLoading={loadingUsers}
        isClearable
        placeholder="Search user or create new"
        noOptionsMessage={() => "No users found"}
        inputValue={inputValue}
        filterOption={(option, input) => {
          if (option.value === 'create_new') return true;
          if (!input) return true;
          return option.label.toLowerCase().includes(input.toLowerCase());
        }}
      />
      {usersError && <p style={{ color: 'red' }}>{usersError}</p>}

      <Modal show={modalOpen} handleClose={closeModal}>
        <CompactUserCreation onUserCreated={handleUserCreated} onClose={closeModal} />
      </Modal>
    </div>
  );
};

export default IdentityUserSelect;