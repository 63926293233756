import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { WarningCallout, InsetText } from "nhsuk-react-components";
import { logout } from "../actions/userActions";
import { Button } from "react-bootstrap";

function TwoFACodeRequestScreen({ history }) {
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['codeRequestCount', 'codeRequestBlockTime']);

    useEffect(() => {
        document.title = "2-Factor Authentication";
    }, []);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const [code, setCode] = useState('');
    const [TFAMessage, setTFAMessage] = useState('');
    const [sendingCode, setSendingEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        if (!userInfo || !userInfo.email) {
            history.push("/login");
        } else {
            setEmail(userInfo.email);
            checkBlockStatus();
            handle2FACodeRequest(userInfo.email);
        }
    }, [history, userInfo]);

    const checkBlockStatus = () => {
        const blockTime = cookies.codeRequestBlockTime;
        if (blockTime && new Date(blockTime) > new Date()) {
            setIsBlocked(true);
            const timeLeft = Math.ceil((new Date(blockTime) - new Date()) / (1000 * 60));
            setTFAMessage(`Too many attempts. Please try again in ${timeLeft} minutes.`);
        } else {
            setIsBlocked(false);
            setCookie('codeRequestCount', 0, { path: '/' });
            setCookie('codeRequestBlockTime', '', { path: '/' });
        }
    };

    const handle2FACodeRequest = async (email) => {
        if (isBlocked) return;

        const currentCount = cookies.codeRequestCount || 0;
        if (currentCount >= 5) {
            const blockTime = new Date(new Date().getTime() + 60 * 60 * 1000); // 1 hour from now
            setCookie('codeRequestBlockTime', blockTime.toISOString(), { path: '/' });
            setIsBlocked(true);
            setTFAMessage("Too many attempts. Please try again in 1 hour.");
            return;
        }

        setSendingEmail(true);
        setTFAMessage(`Sending code to ${email} ...`);
        try {
            const response = await axios.post(`/api/v1/auth/sendverificationcode`, {
                "email": email
            });

            setSendingEmail(false);
            setTFAMessage(`Code successfully sent to ${userInfo.email}.`);
            setCookie('codeRequestCount', currentCount + 1, { path: '/' });
        } catch (error) {
            console.log(error);
            setSendingEmail(false);
            setTFAMessage("2FA code request failed.");
        }
    };

    const handle2FACodeSubmission = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`/api/v1/auth/verifycode`, {
                "email": email,
                "code": code
            });

            setLoading(false);
            setTFAMessage(" ✅ 2FA code verified successfully. Redirecting shortly ...");
            localStorage.setItem("2fauthenticated", "true");

            setTimeout(() => {
                history.push("/");
            }, 2000);

        } catch (error) {
            setLoading(false);
            setTFAMessage("❌ 2FA code verification failed.");
        }
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <React.Fragment>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <h1>2-Factor Authentication</h1>
                            <InsetText>
                                <p>
                                    Please enter the code sent to your email address <strong>{userInfo.email}</strong> to continue.
                                    If you haven't received the code, please check your spam folder or click the "Send/Resend code" button below.
                                    Note that the code is case-sensitive, so make sure to enter it exactly as it appears in the email.
                                </p>
                            </InsetText>
                            <a href="#" onClick={() => handle2FACodeRequest(email)} disabled={sendingCode || isBlocked}>
                                {sendingCode ? 'Sending code ...' : isBlocked ? 'Blocked' : 'Send/Resend code'}
                            </a>
                            {TFAMessage && <p>{TFAMessage}</p>}
                            <form onSubmit={handle2FACodeSubmission} className="nhsuk-form-group">
                                <label className="nhsuk-label" htmlFor="code">
                                    Code
                                </label>
                                <input
                                    className="nhsuk-input"
                                    id="code"
                                    name="code"
                                    type="text"
                                    onChange={(e) => setCode(e.target.value)}
                                    value={code}
                                    required
                                />

                                <div className="nhsuk-u-padding-bottom-5"></div>

                                <div className="nhsuk-grid-row">
                                    <div className="nhsuk-width-container">
                                        <button className="nhsuk-button" data-module="nhsuk-button" type="submit" style={{ width: '100%' }} disabled={loading} onClick={handle2FACodeSubmission}>
                                            {loading ? 'Verifying ...' : 'Verify'}
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <Link to="/" onClick={handleLogout}>
                                Logout
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </React.Fragment>
    );
}

export default TwoFACodeRequestScreen;