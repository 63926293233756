import React, { useState, useEffect } from 'react';
import { Input } from "nhsuk-react-components";

const ISODateInput = ({ id, label, name, value, onChange, required, includeTime = false }) => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('12:00');

  useEffect(() => {
    if (value) {
      const dateObj = new Date(value);
      setDate(dateObj.toISOString().split('T')[0]);
      setTime(dateObj.toISOString().split('T')[1].substring(0, 5));
    }
  }, [value]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setDate(newDate);
    updateValue(newDate, time);
  };

  const handleTimeChange = (e) => {
    const newTime = e.target.value;
    setTime(newTime);
    updateValue(date, newTime);
  };

  const updateValue = (newDate, newTime) => {
    if (newDate) {
      const dateTimeString = `${newDate}T${includeTime ? newTime : '12:00'}:00.000Z`;
      const dateObj = new Date(dateTimeString);
      if (!isNaN(dateObj.getTime())) {
        onChange(dateObj.toISOString());
        console.log(dateObj.toISOString());
      }
    } else {
      onChange(null);
    }
  };


  return (
    <div>
      <Input
        id={`${id}-date`}
        label={label}
        name={`${name}-date`}
        type="date"
        onChange={handleDateChange}
        value={date}
        required={required}
      />
      {includeTime && (
        <Input
          id={`${id}-time`}
          label="Time"
          name={`${name}-time`}
          type="time"
          onChange={handleTimeChange}
          value={time}
          required={required}
        />
      )}
    </div>
  );
};

export default ISODateInput;