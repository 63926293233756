import React, { useState } from 'react';
import { ActionLink, Input, Textarea, Tag, InsetText, Button } from 'nhsuk-react-components';
import { Spin, Tooltip } from 'antd';
import { 
  NotificationOutlined, 
  UserOutlined, 
  MailOutlined, 
  CloseCircleOutlined 
} from '@ant-design/icons';
import axios from 'axios';
import Modal from '../modal/Modal.component';
import Callout from '../callout/Callout.component';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

const EmailSender = ({
  emailToId,
  emailToName,
  emailSubject,
  emailBody,
  onSuccess,
  onError,
  onFinally,
  title,
  triggerText,
  enabled = true,
  disabledMessage = "You don't have permission to send emails",
  disabledTooltip = "This action is currently unavailable"
}) => {
  const [isSending, setIsSending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalEmailSubject, setModalEmailSubject] = useState(emailSubject || '');
  const [modalEmailBody, setModalEmailBody] = useState(emailBody || '');
  const [modalAttachment, setModalAttachment] = useState(null);
  const [attachmentError, setAttachmentError] = useState('');
  const [showCallout, setShowCallout] = useState(false);
  const [calloutData, setCalloutData] = useState({
    type: '',
    message: ''
  });

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setAttachmentError('');
    
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setAttachmentError('File size must be less than 5MB');
        e.target.value = '';
        return;
      }
      setModalAttachment(file);
    }
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    if (!enabled) {
      setCalloutData({
        type: 'error',
        message: disabledMessage
      });
      setShowCallout(true);
      return;
    }

    setIsSending(true);
    setShowCallout(false);

    try {
      let attachmentContent = null;
      if (modalAttachment) {
        attachmentContent = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(modalAttachment);
          reader.onload = () => resolve(reader.result.split(',')[1]);
          reader.onerror = (error) => reject(error);
        });
      }

      const emailData = {
        emailToId,
        emailToName,
        emailSubject: modalEmailSubject,
        emailBody: modalEmailBody,
        ...(attachmentContent && {
          attachments: [{
            content: attachmentContent,
            fileName: modalAttachment.name,
            contentType: modalAttachment.type
          }]
        })
      };

      await axios.post("/api/v1/auth/sendemail", emailData, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      setCalloutData({
        type: 'success',
        message: 'Email sent successfully!'
      });
      setShowCallout(true);
      
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
      setCalloutData({
        type: 'error',
        message: 'Failed to send email. Please try again.'
      });
      setShowCallout(true);
      if (onError) onError(error);
    } finally {
      setIsSending(false);
      if (onFinally) onFinally();
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalEmailSubject(emailSubject || '');
    setModalEmailBody(emailBody || '');
    setModalAttachment(null);
    setAttachmentError('');
    setShowCallout(false);
  };

  const NotifyLink = () => (
    <span 
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: '0.5rem',
        opacity: enabled ? (isSending ? 0.7 : 1) : 0.5
      }}
    >

      {isSending ? 'Sending...' : (triggerText || title)}
    </span>
  );

  return (
    <>
      <Tooltip title={!enabled ? disabledTooltip : ''}>
        <span>
          <ActionLink
            onClick={(e) => {
              e.preventDefault();
              if (!isSending) setShowModal(true);
            }}
            style={{ 
              cursor: enabled && !isSending ? 'pointer' : 'not-allowed',
              pointerEvents: 'auto'
            }}
          >
            <NotifyLink />
          </ActionLink>
        </span>
      </Tooltip>

{/* Replace the existing modal content with this */}
<Modal
  show={showModal}
  handleClose={handleModalClose}
  blur={true}
  theme="light"
  style={{ width: '650px', maxWidth: '90vw' }}
>
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
  }}>
    {/* Header */}
    <div >
      <h3 className="text-xl font-semibold">Send Email Notification</h3>
    </div>

    {/* Main Content - Scrollable */}
    <div style={{ 
      flex: '1 1 auto',
      overflowY: 'auto',
    }}>

      <div className="space-y-4">
        {/* Your existing form fields */}
        <Input
          label="Recipient"
          value={emailToName}
          readOnly
          icon={<UserOutlined />}
        />
        <Input
          label="Email"
          value={emailToId}
          readOnly
          icon={<MailOutlined />}
        />
        <Input
          label="Subject"
          value={modalEmailSubject}
          onChange={(e) => setModalEmailSubject(e.target.value)}
          required
        />
        <Textarea
          label="Message"
          value={modalEmailBody}
          onChange={(e) => setModalEmailBody(e.target.value)}
          required
          style={{ minHeight: '150px' }}
        />
        {/* File attachment section */}
        <div>
          <Input
            type="file"
            label="Attachment"
            onChange={handleFileChange}
            error={attachmentError}
            hint="Max size: 5MB"
          />
          {modalAttachment && (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginTop: '8px',
              padding: '8px',
              backgroundColor: '#f0f4f5',
              borderRadius: '4px'
            }}>
              <Tag>{modalAttachment.name}</Tag>
              <ActionLink
                onClick={(e) => {
                  e.preventDefault();
                  setModalAttachment(null);
                }}
                style={{ color: '#d5281b' }}
              >
                <CloseCircleOutlined style={{ fontSize: '16px' }} />
              </ActionLink>
            </div>
          )}
          {attachmentError && (
            <div style={{ color: '#d5281b', fontSize: '14px', marginTop: '4px' }}>
              {attachmentError}
            </div>
          )}
        </div>
      </div>
      {showCallout && (
        <div className="mb-4">
          <Callout
            label={calloutData.type === 'success' ? 'Success' : 'Error'}
            header={calloutData.type === 'success' ? 'Success!' : 'Error!'}
            autoDismiss={true}
            dismissTime={5000}
            time={new Date().toLocaleTimeString()}
            title={calloutData.type === 'success' ? 'Success!' : 'Error!'}
            message={calloutData.message}
            mode={calloutData.type}
          />
        </div>
      )}

    </div>

    {/* Footer - Fixed at bottom */}
    <div style={{ 
      borderTop: '1px solid #f0f4f5',
      padding: '1rem 1.5rem',
      marginTop: 'auto',
      backgroundColor: '#ffffff',
      display: 'flex', 
      justifyContent: 'flex-end', 
      gap: '1rem'
    }}>
        
      <Button
        secondary
        onClick={(e) => {
          e.preventDefault();
          handleModalClose();
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={handleSendEmail}
        disabled={isSending || !modalEmailSubject || !modalEmailBody}
        style={{
          opacity: (isSending || !modalEmailSubject || !modalEmailBody) ? 0.5 : 1,
          cursor: (isSending || !modalEmailSubject || !modalEmailBody) ? 'not-allowed' : 'pointer'
        }}
      >
        {isSending ? 'Sending...' : 'Send Email'}
      </Button>
    </div>
  </div>
</Modal>    </>
  );
};

export default EmailSender;