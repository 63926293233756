import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useHistory } from "react-router-dom";
import { parseJwt } from "../../utils/parseJWT";
import axios from "axios";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {

  const history = useHistory();

  useEffect(() => {
    // Axios interceptor for handling 401 errors
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          // Perform logout action here (clear user info, redirect to login page, etc.)
          localStorage.removeItem("userInfo");
          // remove 2fa auth 
          localStorage.removeItem("2fauthenticated");
          // redirect to login page
          history.push("/login");
        } else if (error.response && error.response.status === 403) {
          // Redirect to unauthorized page
          history.push("/unauthorized");
        }
        return Promise.reject(error);
      }
    );

    // Check if token is expired
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      const decodedJwt = parseJwt(userInfo.token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        // Token is expired, remove user info and redirect to login page
        localStorage.removeItem("userInfo");
        localStorage.removeItem("2fauthenticated");
        window.location.href = "/login";
      }
    }

    return () => {
      // Cleanup: Eject the interceptor upon component unmounting
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const hasRequiredRole = (userRoles, requiredRoles) => {
    return requiredRoles.some(role => userRoles.includes(role));
  };


  return (
    <Route
      {...rest}
      render={props => {

        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const is2FAuthenticated = localStorage.getItem("2fauthenticated");

        if (isAuthProtected && rest.roles && userInfo && !hasRequiredRole(userInfo.roles, rest.roles)) {
          return <Redirect to={{ pathname: "/unauthorized", state: { from: props.location } }} />;
        }
        if (isAuthProtected && (!userInfo)) {
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }
        if (isAuthProtected && is2FAuthenticated === "false") {
          // already logged in
          // redirect to dashboard
          return <Redirect to={{ pathname: "/2fauth", state: { from: props.location } }} />;
        }
        // Render the component within the specified layout
        return <Layout><Component {...props} /></Layout>;
      }
      }
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;