import {

    TEMPLATE_LIST_REQUEST,
    TEMPLATE_LIST_SUCCESS,
    TEMPLATE_LIST_FAIL,

    TEMPLATE_DETAILS_REQUEST,
    TEMPLATE_DETAILS_SUCCESS,
    TEMPLATE_DETAILS_FAIL,

    TEMPLATE_DELETE_REQUEST,
    TEMPLATE_DELETE_SUCCESS,
    TEMPLATE_DELETE_FAIL,

    TEMPLATE_CREATE_REQUEST,
    TEMPLATE_CREATE_SUCCESS,
    TEMPLATE_CREATE_FAIL,
    TEMPLATE_CREATE_RESET,

    TEMPLATE_UPDATE_REQUEST,
    TEMPLATE_UPDATE_SUCCESS,
    TEMPLATE_UPDATE_FAIL,
    TEMPLATE_UPDATE_RESET,

    TEMPLATE_COUNT_SUCCESS,
    TEMPLATE_COUNT_REQUEST,
    TEMPLATE_COUNT_FAIL,

    SET_CURRENT_PAGE,

} from "../constants/templateConstants";

const initialState = {
    templates: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const templateListReducer = (
    state = {
        templates: []
    }, action) => {
    switch (action.type) {
        case TEMPLATE_LIST_REQUEST:
            return { loading: true, templates: [] };
        case TEMPLATE_LIST_SUCCESS:
            // our state will have a list of templates after the request
            return {
                loading: false,
                templates: action.payload.templates,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
                
            };

        case TEMPLATE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const templateCountReducer = (state = { templateCounts: [] }, action) => {
  switch (action.type) {
    case TEMPLATE_COUNT_REQUEST:
      return { loading: true, templateCounts: [] };
    case TEMPLATE_COUNT_SUCCESS:
      return { loading: false, templateCounts: action.payload };
    case TEMPLATE_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const templateDetailsReducer = (
    state = {
        template: {}
    }, action) => {
    switch (action.type) {
        case TEMPLATE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case TEMPLATE_DETAILS_SUCCESS:
            // our state will have a list of templates after the request
            return { loading: false, template: action.payload };
        case TEMPLATE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const templateDetailsByNameReducer = (
    state = {
        template: { }
    }, action) => {
    switch (action.type) {
        case TEMPLATE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case TEMPLATE_DETAILS_SUCCESS:
            // our state will have a list of templates after the request
            return { loading: false, template: action.payload };
        case TEMPLATE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}


export const templateDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TEMPLATE_DELETE_REQUEST:
            return { loading: true }

        case TEMPLATE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case TEMPLATE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const templateCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TEMPLATE_CREATE_REQUEST:
            return { loading: true }

        case TEMPLATE_CREATE_SUCCESS:
            return { loading: false, success: true, template: action.payload }

        case TEMPLATE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case TEMPLATE_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const templateUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case TEMPLATE_UPDATE_REQUEST:
            return { loading: true }

        case TEMPLATE_UPDATE_SUCCESS:
            return { loading: false, success: true, template: action.payload }

        case TEMPLATE_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case TEMPLATE_UPDATE_RESET:
            return { template: {} }

        default:
            return state
    }
}