import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { WarningCallout } from "nhsuk-react-components";


function ResetPasswordScreen({ history, location }) {

    useEffect(() => {
        document.title = "Reset Password"; 
    }, []);


    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [resetMessage, setResetMessage] = useState('');
    const [loading, setLoading] = useState(false);


    // listen to location
    useEffect(() => {

        // get the token from the url
        const searchParams = new URLSearchParams(location.search);
        // dont replace by spaces in the token
        const token = searchParams.get('token');
        const email = searchParams.get('email');
        setEmail(email);
        setToken(token);


    }, [location]);




    // dispatch login function defined in userActions
    const handleResetPassword = async () => {
        if (!password || password.length < 6) {
            alert("Password must be at least 6 characters long.");
            return;
        }

        setLoading(true);

        try {
            const resetRequest = {
                "email": email,
                "password": password,
                "token": token
            }


            await axios.post(`/api/v1/auth/resetpassword`, 
                resetRequest
            ).then((response) => {
                setLoading(false);
                setResetMessage("Password reset successful.");
                alert("Password reset successful.");
                // Wait for 2 seconds before redirecting
                setTimeout(() => {
                    history.push("/login");
                }, 2000);


            }).catch((error) => {
                setLoading(false);
                setResetMessage("An error occurred. Please try again later.");
                console.log(error);
                alert("An error occurred. Please try again later.");
            }
                
            );
    } catch (error) {
        setLoading(false);
        setResetMessage("An error occurred. Please try again later.");
        alert("An error occurred. Please try again later.");
    }
}

        return (
            <React.Fragment>

                <div className="nhsuk-width-container">

                    <main className="nhsuk-main-wrapper" id="maincontent" role="main">

                        <div className="nhsuk-grid-row">
                            <div className="nhsuk-grid-column-two-thirds">

                                <h1>Password Reset</h1>
                                {resetMessage ? <p>{resetMessage}</p> : null}

                                <form onSubmit={handleResetPassword} className="nhsuk-form-group">

                                    <div className="nhsuk-u-padding-bottom-5"></div>

                                    <label className="nhsuk-label" for="password">
                                        New Password
                                    </label>
                                    <input
                                        className="nhsuk-input"
                                        id="password"
                                        name="password"
                                        type="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        required
                                    />

                                    <div className="nhsuk-u-padding-bottom-5"></div>

                                    <div className="nhsuk-grid-row">
                                        <div className="nhsuk-width-container">
                                            <button onClick={handleResetPassword} className="nhsuk-button" data-module="nhsuk-button" type="submit" style={{ width: '100%' }} disabled={loading}>
                                                {loading ? 'Authenticating ...' : 'ResetPassword'}
                                            </button>

                                        </div>
                                    </div>

                                </form>



                            </div>

                        </div>

                    </main>
                </div >
            </React.Fragment >
        )
    }

    export default ResetPasswordScreen;