import React from "react";

function Footer() {
    const version = process.env.REACT_APP_VERSION || "UNKNOWN";

    return (
        <footer role="contentinfo">
            <div className="nhsuk-footer-container">
                <div className="nhsuk-width-container">
                    <h2 className="nhsuk-u-visually-hidden">Support links</h2>
                    <ul className="nhsuk-footer__list">
                        <li className="nhsuk-footer__list-item nhsuk-footer-default__list-item"><a className="nhsuk-footer__list-item-link" href="#">Cookie policy</a></li>
                        <li className="nhsuk-footer__list-item nhsuk-footer-default__list-item"><a className="nhsuk-footer__list-item-link" href="#">Get in touch</a></li>
                        <li className="nhsuk-footer__list-item nhsuk-footer-default__list-item"><a className="nhsuk-footer__list-item-link" href="#">Terms and conditions</a></li>
                        <li className="nhsuk-footer__list-item nhsuk-footer-default__list-item"><a className="nhsuk-footer__list-item-link" href="#">Your privacy</a></li>
                    </ul>
                    <div>
                        <p className="nhsuk-footer__copyright">© Crown copyright - Build {version}</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;