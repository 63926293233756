// given LoginScreen.js 
// generate similarely RegisterScreen

import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIdentityUserDetails, updateIdentityUser, resetPassword } from "../../actions/userActions";
import { listOrganisations } from "../../actions/organisationActions";
import { Input, Button, Select, WarningCallout, BackLink, Card, DoDontList, ErrorSummary, Tag } from "nhsuk-react-components";
import { List } from 'react-content-loader'
import Callout from "../../components/callout/Callout.component";
import Checklist from "../../components/checklist/Checklist.component";
import { UPDATE_USER_PASSWORD_RESET, IDENTITY_USER_UPDATE_RESET } from "../../constants/userConstants";
import axios from "axios";


function EditUserScreen({ match, history }) {

    const dispatch = useDispatch();

    const userId = match.params._id;

    // reset state after success
    useEffect(() => {
        dispatch({ type: IDENTITY_USER_UPDATE_RESET });
    }, [dispatch]);

    // define new state for identityUser
    const [newIdentityUser, setNewIdentityUser] = useState({});

    // new password
    const [newPassword, setNewPassword] = useState("");

    const [sendingEmailConfirmationLink, setSendingEmailConfirmationLink] = useState(false);
    const [userEmailConfirmationSentSucess, setUserEmailConfirmationSentSuccess] = useState(false);
    const [userEmailConfirmationSentError, setUserEmailConfirmationSentError] = useState(false);

    // identityUserDetails
    const identityUserDetails = useSelector(state => state.identityUserDetails);
    const { loading, error, identityUser } = identityUserDetails;

    // updateIdentityUser 
    const identityUserUpdate = useSelector(state => state.identityUserUpdate);
    const { loading: loadingUpdateIdentityUser, error: errorUpdateIdentityUser, success: successUpdateIdentityUser } = identityUserUpdate;

    // List organisations
    const organisationList = useSelector(state => state.organisationList);
    const { loading: loadingOrganisations, error: errorOrganisations, organisations } = organisationList;

    // updatePassword
    const updatePassword = useSelector(state => state.updatePassword);
    const { loading: loadingUpdatePassword, error: errorUpdatePassword, success: successUpdatePassword } = updatePassword;

    // Error message summary
    const [errorMessage, setErrorMessage] = useState("");

    // Fetch identityUser details and set state
    useEffect(() => {
        dispatch(getIdentityUserDetails(userId));
    }, [dispatch, userId]);

    // Fetch organisations
    useEffect(() => {
        dispatch(listOrganisations());

    }, [dispatch]);

    // listen to identityUserDetails changes
    useEffect(() => {
        if (identityUser && !loading) {
            // get claims or default to an empty array if undefined/null
            const claims = identityUser.claims || [];

            // get FirstName, LastName, PhoneNumber, OrganizationId from claims
            const FirstName = claims.find(claim => claim.type.includes("claims/name"));
            const LastName = claims.find(claim => claim.type.includes("claims/surname"));
            const PhoneNumber = claims.find(claim => claim.type.includes("claims/mobilephone"));
            const OrganizationId = claims.find(claim => claim.type.includes("claims/groupsid"));


            // set newIdentityUser with default values if claims or specific claims are not found
            setNewIdentityUser({
                ...identityUser,
                FirstName: FirstName ? FirstName.value : "",
                LastName: LastName ? LastName.value : "",
                PhoneNumber: PhoneNumber ? PhoneNumber.value : "",
                OrganizationId: OrganizationId ? OrganizationId.value : ""
            });
        }
    }, [identityUser, loading]);


    // handle update identityUser
    const handleUpdateIdentityUser = async () => {
        dispatch(updateIdentityUser(userId, newIdentityUser)).then(() => {
            // once a user is updated, fetch the updated user details
            dispatch(getIdentityUserDetails(userId));
        });
    };

    // handle update password
    const handleUpdatePassword = async () => {
        if (!newPassword || newPassword.length < 6) {
            alert("Password must be at least 6 characters");
            return;
        }
        dispatch(resetPassword(userId, newPassword));
    };

    // handle resend email confirmation link
    const hanfleResentEmailConfirmationLink = (e) => {
        e.preventDefault();
        setSendingEmailConfirmationLink(true);
        // post request to resend email confirmation link to /api/v1/auth/resendemailconfirmationlink {Email: email}
        axios
            .post("/api/v1/auth/resendemailconfirmationlink", { Email: newIdentityUser.email })
            .then((res) => {
                // if success (200)
                // set userInfo.emailConfirmed to true
                if (res.status === 200) {
                    setUserEmailConfirmationSentSuccess(true);
                    setUserEmailConfirmationSentError(false);
                    setSendingEmailConfirmationLink(false);
                }
            })
            .catch((err) => {
                // if error
                // show error message
                setUserEmailConfirmationSentSuccess(false);
                setUserEmailConfirmationSentError(true);
                setSendingEmailConfirmationLink(false);
            });

    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-one-half">
                            <div className="welcome-box">

                                <div className="welcome-title">
                                    <h3>User Identity Information</h3>
                                    <BackLink
                                        asElement="a"
                                        href="#"
                                        onClick={() => history.goBack()}
                                    >
                                        Return back
                                    </BackLink>
                                    <Card style={{ padding: "20px" }}>
                                        <Card.Heading>
                                            <h3></h3>
                                        </Card.Heading>
                                        <Card.Description>
                                            {errorUpdateIdentityUser && (
                                                <ErrorSummary
                                                    aria-labelledby="error-summary-title"
                                                    role="alert"
                                                    tabIndex={-1}
                                                    title="There is a problem"
                                                >
                                                    <p> {errorUpdateIdentityUser} </p>
                                                </ErrorSummary>
                                            )}


                                            {loading ? (
                                                <h4>Loading...</h4>

                                            ) : error ? (
                                                <ErrorSummary
                                                    aria-labelledby="error-summary-title"
                                                    role="alert"
                                                    tabIndex={-1}
                                                    title="There is a problem"
                                                >
                                                    <p> {error} </p>
                                                </ErrorSummary>

                                            ) : (
                                                (newIdentityUser && newIdentityUser.roles) ? (
                                                    <React.Fragment>
                                                        <h4>Identity Information</h4>
                                                        <WarningCallout>
                                                            <WarningCallout.Label>Important</WarningCallout.Label>
                                                            <p>
                                                                If you modify any of the information below, an email will be sent to the user to notify them of the change.
                                                            </p>
                                                        </WarningCallout>
                                                        <Input
                                                            id="input-email"
                                                            label="Email"
                                                            name="email"
                                                            value={newIdentityUser.email}
                                                            onChange={(e) => setNewIdentityUser({ ...newIdentityUser, email: e.target.value })}
                                                            readOnly
                                                            required
                                                        />

                                                        <Input
                                                            id="input-username"
                                                            label="Username"
                                                            name="username"
                                                            onChange={(e) => setNewIdentityUser({ ...newIdentityUser, userName: e.target.value })}
                                                            value={newIdentityUser.userName}
                                                            required
                                                        />
                                                        <Input
                                                            id="input-firstname"
                                                            label="First Name"
                                                            name="firstname"
                                                            onChange={(e) => setNewIdentityUser({ ...newIdentityUser, FirstName: e.target.value })}
                                                            value={newIdentityUser.FirstName}
                                                            required
                                                        />

                                                        <Input
                                                            id="input-lastname"
                                                            label="Last Name"
                                                            name="lastname"
                                                            onChange={(e) => setNewIdentityUser({ ...newIdentityUser, LastName: e.target.value })}
                                                            value={newIdentityUser.LastName}
                                                            required
                                                        />
                                                        <Input
                                                            id="input-phonenumber"
                                                            label="Phone Number"
                                                            name="phonenumber"
                                                            onChange={(e) => setNewIdentityUser({ ...newIdentityUser, PhoneNumber: e.target.value })}
                                                            value={newIdentityUser.PhoneNumber}
                                                            required
                                                        />
                                                        <Select
                                                            id="select-organization"
                                                            label="Organisation"
                                                            selected
                                                            value={newIdentityUser.OrganizationId}
                                                            onChange={(e) => setNewIdentityUser({ ...newIdentityUser, OrganizationId: e.target.value })}
                                                            required
                                                        >
                                                            {loadingOrganisations ? (
                                                                <List style={{ marginBottom: "30px" }} />
                                                            ) : errorOrganisations ? (
                                                                <h3>{errorOrganisations}</h3>
                                                            ) : (
                                                                (organisations) ? (
                                                                    organisations.map((organisation) => (
                                                                        <Select.Option key={organisation.id} value={organisation.id}>
                                                                            {organisation.name}
                                                                        </Select.Option>
                                                                    ))
                                                                ) : (
                                                                    <h3>No organisations found</h3>
                                                                )
                                                            )
                                                            }
                                                        </Select>
                                                        <Select
                                                            id="select-user-role"
                                                            label="User Role"
                                                            selected
                                                            value={newIdentityUser.roles[0]}
                                                            onChange={(e) => setNewIdentityUser({ ...newIdentityUser, roles: [e.target.value] })}
                                                        >
                                                            <Select.Option value="SuperUser">
                                                                Super User
                                                            </Select.Option>
                                                            <Select.Option value="TrustUserManager">
                                                                Trust User Manager
                                                            </Select.Option>
                                                            <Select.Option value="CardiologyStaff">
                                                                Cardiology Staff
                                                            </Select.Option>
                                                            <Select.Option value="Patient">
                                                                Patient
                                                            </Select.Option>
                                                            <Select.Option value="UnauthorizedUser">
                                                                Unauthorized User
                                                            </Select.Option>
                                                        </Select>


                                                    </React.Fragment>
                                                ) : (
                                                    <h3>Could'nt fetch user's Information</h3>
                                                )
                                            )

                                            }


                                        </Card.Description>
                                        <Button
                                            type="submit"
                                            disabled={loading || loadingUpdateIdentityUser}
                                            onClick={handleUpdateIdentityUser}
                                        >
                                            {loadingUpdateIdentityUser ? (
                                                <span>Updating...</span>
                                            ) : (
                                                <span>Update</span>
                                            )}
                                        </Button>

                                        {successUpdateIdentityUser && (
                                            <Callout
                                                mode="success"
                                                header="Success!"
                                                title="Success callout"
                                                label="Success"
                                                message="User updated successfully."
                                            />
                                        )}

                                    </Card>

                                    <Card style={{ padding: "20px" }}>
                                        <Card.Heading>
                                            <h3>Change Password</h3>
                                        </Card.Heading>
                                        <Card.Description>
                                            <Input
                                                id="input-password"
                                                disabled={loading || loadingUpdatePassword}
                                                label="New Password"
                                                name="password"
                                                type="password"
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                value={newPassword}
                                                required
                                            />
                                        </Card.Description>
                                        <Button
                                            type="submit"
                                            disabled={loading || loadingUpdatePassword}
                                            onClick={handleUpdatePassword}
                                        >
                                            {loadingUpdatePassword ? (
                                                <span>Updating...</span>
                                            ) : (
                                                <span>Update</span>
                                            )}
                                        </Button>
                                        {successUpdatePassword && (
                                            <Callout
                                                mode="success"
                                                header="Success!"
                                                title="Success callout"
                                                label="Success"
                                                message="Password updated successfully."
                                            />
                                        )}

                                    </Card>


                                </div>
                            </div>

                        </div>
                        <div className="nhsuk-grid-column-one-half">
                            {!loading ? (

                                <Checklist heading="Account checklist">


                                    <Checklist.Item mode={newIdentityUser.userName ? "success" : "error"}>
                                        Account created: {newIdentityUser.userName ? "Yes" : "No"}
                                    </Checklist.Item>
                                    <Checklist.Item mode={newIdentityUser?.roles?.includes("UnauthorizedUser") ? "error" : "success"}>
                                        User role setup: {newIdentityUser?.roles?.length > 0 ? (newIdentityUser.roles.includes("UnauthorizedUser") ? "No" : <Tag>{newIdentityUser.roles[0]}</Tag>) : "N/A"}
                                    </Checklist.Item>
                                    <Checklist.Item mode={newIdentityUser.emailConfirmed ? "success" : "error"}>
                                        Email confirmed: {newIdentityUser.emailConfirmed ? "Yes" : "No"} {" "}
                                        {!newIdentityUser.emailConfirmed && (
                                            <a href="#" onClick={hanfleResentEmailConfirmationLink}>{sendingEmailConfirmationLink ? "Sending email..." : "Resend"}</a>
                                        )}
                                    </Checklist.Item>

                                </Checklist>
                            ) : (
                                <h5>Loading...</h5>
                            )}
                            <div className="nhsuk-u-padding-top-5"></div>
                            {userEmailConfirmationSentSucess && (
                                <Callout
                                    mode="success"
                                    header="Email confirmation sent"
                                    title="Success callout"
                                    label="Success"
                                    message={`A confirmation email has been sent to ${newIdentityUser.email}.`}
                                />
                            )}

                            {userEmailConfirmationSentError && (
                                <Callout
                                    mode="error"
                                    header="Email confirmation not sent"
                                    title="Error callout"
                                    label="Error"
                                    message={`An error occured while sending the confirmation email to ${newIdentityUser.email}. Please try again later.`}
                                />
                            )}


                        </div>

                    </div>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default EditUserScreen;