import React from 'react';

import { Skeleton } from 'antd';

const AppSpinner = () => {

    return (
        <div>
            <div className="nhsuk-u-padding-top-3"></div>
            <Skeleton />
        </div>
    );

}


export default AppSpinner;

