import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,

    IDENTITY_USER_DETAILS_REQUEST,
    IDENTITY_USER_DETAILS_SUCCESS,
    IDENTITY_USER_DETAILS_FAIL,
    IDENTITY_USER_DETAILS_RESET,

    IDENTITY_USER_UPDATE_REQUEST,
    IDENTITY_USER_UPDATE_SUCCESS,
    IDENTITY_USER_UPDATE_FAIL,

    IDENTITY_USER_CREATE_FAIL,
    IDENTITY_USER_CREATE_REQUEST,
    IDENTITY_USER_CREATE_RESET,
    IDENTITY_USER_CREATE_SUCCESS,

    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_RESET,

    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,

    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,

    GOOGLE_AUTH_FAIL,
    GOOGLE_AUTH_SUCCESS,

    AUTHENTICATED_REQUEST,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    UPDATE_USER_PASSWORD_REQUEST,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAIL,
    UPDATE_USER_PASSWORD_RESET,
    IDENTITY_USER_UPDATE_RESET,

} from '../constants/userConstants'


export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }

        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload, success: true}

        case GOOGLE_AUTH_SUCCESS:
            return { loading: false, userInfo: action.payload }

        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }

        case GOOGLE_AUTH_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}



export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }

        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload, success: true }

        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}


export const userDetailsReducer = (state = { profile: {} }, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return { ...state, loading: true }

        case USER_DETAILS_SUCCESS:
            return { loading: false, profile: action.payload.data }

        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        case USER_DETAILS_RESET:
            return { profile: {} }

        default:
            return state
    }
}

export const identityUserCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case IDENTITY_USER_CREATE_REQUEST:
            return { loading: true }
        
        case IDENTITY_USER_CREATE_SUCCESS:
            return { loading: false, success: true, identityUser: action.payload }
        
        case IDENTITY_USER_CREATE_FAIL:
            return { loading: false, error: action.payload }
        
        case IDENTITY_USER_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const identityUserDetailsReducer = (state = { identityUser: {} }, action) => {
    switch (action.type) {
        case IDENTITY_USER_DETAILS_REQUEST:
            return { ...state, loading: true }

        case IDENTITY_USER_DETAILS_SUCCESS:
            return { loading: false, identityUser: action.payload }

        case IDENTITY_USER_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        case IDENTITY_USER_DETAILS_RESET:
            return { identityUser: {} }

        default:
            return state
    }
}



export const userUpdateReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_UPDATE_REQUEST:
            return { loading: true }

        case USER_UPDATE_SUCCESS:
            return { loading: false, success: true, user: action.payload }

        case USER_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case USER_UPDATE_RESET:
            return { user: {} }

        default:
            return state
    }
}

export const resetPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_USER_PASSWORD_REQUEST:
            return { loading: true }

        case UPDATE_USER_PASSWORD_SUCCESS:
            return { loading: false, success: true }

        case UPDATE_USER_PASSWORD_FAIL:
            return { loading: false, error: action.payload }
        
        case UPDATE_USER_PASSWORD_RESET:
            return {}

        default:
            return state
    }
}

// update identityUser details, the PUT request is sent to /api/v1/user/id
export const identityUserUpdateReducer = (state = { identityUser: {} }, action) => {
    switch (action.type) {
        case IDENTITY_USER_UPDATE_REQUEST:
            return { loading: true }

        case IDENTITY_USER_UPDATE_SUCCESS:
            return { loading: false, success: true, identityUser: action.payload }

        // Example handling in the reducer
        case IDENTITY_USER_UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case IDENTITY_USER_UPDATE_RESET:
            return { identityUser: {} }

        default:
            return state
    }
}


export const userListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { loading: true }

        case USER_LIST_SUCCESS:
            return {
                loading: false,
                users: action.payload.users,
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages
            }

        case USER_LIST_FAIL:
            return { loading: false, users: action.payload }

        case USER_LIST_RESET:
            return { users: [] }
        default:
            return state
    }
}


export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_DELETE_REQUEST:
            return { loading: true }

        case USER_DELETE_SUCCESS:
            return { loading: false, success: true }

        case USER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}
export const userAuthenticationCheckReducer = (state = {}, action) => {
    switch (action.type) {

        case AUTHENTICATED_REQUEST:
            return { loading: true }

        case AUTHENTICATED_SUCCESS:
            return { loading: false, success: true, state: action.payload } // profile already parsed in actions

        case AUTHENTICATED_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}