import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary, Checkboxes } from 'nhsuk-react-components';
import { listSmtpConfigs, createSmtpConfig, updateSmtpConfig, deleteSmtpConfig, getSmtpConfigDetails } from '../../actions/smtpConfigActions';
// constants 
import { SMTP_CONFIG_CREATE_RESET, SMTP_CONFIG_UPDATE_RESET } from '../../constants/smtpConfigConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';
import axios from 'axios';

// debouncer 
import { debounce } from 'lodash';

export default function SmtpConfigCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "SMTP Config"; 
    }, []);


    // reset the state of the smtpConfigCreate and smtpConfigUpdate
    useEffect(() => {
        dispatch({ type: SMTP_CONFIG_CREATE_RESET });
        dispatch({ type: SMTP_CONFIG_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/smtpConfig/all?page=1&limit=2&filter=Email&query=gmail
    const [smtpConfigListFilter, setsmtpConfigListFilter] = useState({ query: '', page: 1, filter: 'Name' });

    // get smtpConfig list from redux store
    const smtpConfigList = useSelector((state) => state.smtpConfigList);
    const { loading, error, smtpConfigs, currentPage, totalPages } = smtpConfigList;

    // get smtpConfig create from redux store
    const smtpConfigCreate = useSelector((state) => state.smtpConfigCreate);
    const { success: successCreate, error: smtpConfigCreateErrors } = smtpConfigCreate;

    // get smtpConfig update from redux store
    const smtpConfigUpdate = useSelector((state) => state.smtpConfigUpdate);
    const { success: successUpdate, loading: smtpConfigUpdateProcessing } = smtpConfigUpdate;

    // get smtpConfig delete from redux store
    const smtpConfigDelete = useSelector((state) => state.smtpConfigDelete);
    const { success: successDelete } = smtpConfigDelete;

    // get smtpConfig details from redux store
    const smtpConfigDetails = useSelector((state) => state.smtpConfigDetails);
    const { smtpConfig: smtpConfigDet, loading: smtpConfigDetailsLoading } = smtpConfigDetails;

    // newSmtpConfig state
    const [newSmtpConfig, setNewSmtpConfig] = useState({});
    const [updatedSmtpConfig, setUpdatedSmtpConfig] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [testEmailStatus, setTestEmailStatus] = useState();
    const [testingEmail, setTestingEmail] = useState(false);
    const [testEmailTo, setTestEmailTo] = useState();


    // listen to filter changes
    useEffect(() => {
        dispatch(listSmtpConfigs(smtpConfigListFilter));
    }, [dispatch, smtpConfigListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setsmtpConfigListFilter({ ...smtpConfigListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setsmtpConfigListFilter({ ...smtpConfigListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setsmtpConfigListFilter({ ...smtpConfigListFilter, filter: selectedFilter });
    };

    // get all smtpConfigs
    useEffect(() => {
        dispatch(listSmtpConfigs({}));
    }, [dispatch]);

    // listen to smtpConfigDet 
    useEffect(() => {
        if (smtpConfigDet) {
            // use spread operator to copy the smtpConfigDet object
            setUpdatedSmtpConfig({ ...smtpConfigDet });

        }
    }, [smtpConfigDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newSmtpConfig state
            setNewSmtpConfig({
                name: '',
                host: '',
                port: 587,
                username: '',
                password: '',
                enableSsl: false,
                isDefault: false,
                fromAddress: '',
                fromName: '',
                description: '',
            });
        }
    }, [editMode]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this smtpConfig?')) {
            dispatch(deleteSmtpConfig(id)).then(() => {
                dispatch(listSmtpConfigs({}));
            }
            );
        }
    };

    // handle edit
    const handleEditSmtpConfig = (id) => {
        
        dispatch(getSmtpConfigDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateSmtpConfig = () => {
        dispatch(createSmtpConfig(newSmtpConfig)).then(() => {
            setUpdatedSmtpConfig(newSmtpConfig);
            setEditMode(false);
            dispatch(listSmtpConfigs({}));
        }
        );
    };

    // handle update
    const handleUpdateSmtpConfig = () => {
        dispatch(updateSmtpConfig(updatedSmtpConfig.id, updatedSmtpConfig)).then(() => {

            setUpdatedSmtpConfig(updatedSmtpConfig);
            setEditMode(true);
            dispatch(getSmtpConfigDetails(updatedSmtpConfig.id));
        }
        );
    };

    const handleTestEmail = async () => {
        // status has to be reset to null
            setTestEmailStatus();
        setTestingEmail(true);
        const emailData = {
            EmailToId: testEmailTo ? testEmailTo : "iayoub@yandex.com",
            EmailToName: "Barts NHS Trust Test",
            EmailSubject: "Subject Test",
            EmailBody: "This is a test",
        };

        try {

            const response = await axios.post("/api/v1/auth/testemail", emailData);


            setTestEmailStatus(response.data);

        } catch (error) {
            console.log(error);
            setTestEmailStatus("error");
        } finally {
            setTestingEmail(false);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Email Server Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the SMTP Servers. You can create, update and delete SMTP Servers in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">

                            {smtpConfigCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {smtpConfigCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {smtpConfigCreateErrors.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null
                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Test Default SMTP Server
                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="testEmailTo"
                                            label="Test Email To"
                                            name="testEmailTo"
                                            type="text"
                                            onChange={(e) => setTestEmailTo(e.target.value)}
                                            value={testEmailTo ? testEmailTo : ''}
                                            required
                                        />
                                    <Button id="testEmailLink" className="nhsuk-button" onClick={handleTestEmail}>
                                            {testingEmail ? "Testing Connection..." : "► Test Connection"}
                                        </Button>

                                    </Card.Description>

                                    {testEmailStatus && (
                                        <Callout
                                            label={testEmailStatus ? "Success" : "Error"}
                                            header={testEmailStatus ? "Success!" : "Error!"}
                                            autoDismiss={true}
                                            dismissTime={5000}
                                            time={new Date().toLocaleTimeString()}
                                            title={testEmailStatus ? "Success!" : "Error!"}
                                            message={testEmailStatus ? "Email was sent successfully" : "There was an error sending the email"}
                                            mode={testEmailStatus ? "success" : "error"}
                                        />
                                    )}
                                </Card.Content>
                            </Card>
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-smtpConfigs"
                                        label="Search SMTP Servers"
                                        name="search-smtpConfigs"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {smtpConfigs && smtpConfigs.length > 0 ? (
                                            smtpConfigs.map((smtpConfig) => (
                                                <Details key={smtpConfig.id}>
                                                    <Details.Summary>
                                                        {smtpConfig.name}
                                                    </Details.Summary>
                                                    <Details.Text>
                                                        <p>
                                                            <strong>Name:</strong> {smtpConfig.name}
                                                        </p>
                                                        <p>
                                                            <strong>Host:</strong> {smtpConfig.host}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={smtpConfigDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditSmtpConfig(smtpConfig.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(smtpConfig.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit SMTP Config
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an SMTP server. If you want to create a new SMTP server, please click the "Create SmtpConfig" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create SMTP Server
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name"
                                            label="Name"
                                            name="name"
                                            type="text"
                                            onChange={(e) => setUpdatedSmtpConfig({ ...updatedSmtpConfig, name: e.target.value })}
                                            value={updatedSmtpConfig.name ? updatedSmtpConfig.name : ''}
                                            required
                                        />
                                        <Input
                                            id="host"
                                            label="Host"
                                            name="host"
                                            type="text"
                                            onChange={(e) => setUpdatedSmtpConfig({ ...updatedSmtpConfig, host: e.target.value })}
                                            value={updatedSmtpConfig.host ? updatedSmtpConfig.host : ''}
                                            required
                                        />
                                        <Input
                                            id="port"
                                            label="Port"
                                            name="port"
                                            type="text"
                                            onChange={(e) => setUpdatedSmtpConfig({ ...updatedSmtpConfig, port: e.target.value })}
                                            value={updatedSmtpConfig.port ? updatedSmtpConfig.port : ''}
                                            required
                                        />
                                        <Input
                                            id="username"
                                            label="Username"
                                            name="username"
                                            type="text"
                                            onChange={(e) => setUpdatedSmtpConfig({ ...updatedSmtpConfig, username: e.target.value })}
                                            value={updatedSmtpConfig.username ? updatedSmtpConfig.username : ''}
                                            required
                                        />
                                        <Input
                                            id="password"
                                            label="Password"
                                            name="password"
                                            type="password"
                                            onChange={(e) => setUpdatedSmtpConfig({ ...updatedSmtpConfig, password: e.target.value })}
                                            value={updatedSmtpConfig.password ? updatedSmtpConfig.password : ''}
                                            required
                                        />
                                        <Checkboxes name="checkboxes" hint="Connection information">
                                            <Checkboxes.Box
                                                conditional={<p>Use this server as a default email server</p>}
                                                id="default"
                                                name="default"
                                                value="default"
                                                checked={updatedSmtpConfig.isDefault ? updatedSmtpConfig.isDefault : false}
                                                onChange={(e) => setUpdatedSmtpConfig({ ...updatedSmtpConfig, isDefault: e.target.checked })}
                                            >
                                                Make Default
                                            </Checkboxes.Box>
                                            <Checkboxes.Box
                                                conditional={<p>Use TLS Encrypted Connection to send emails</p>}
                                                id="useSSL"
                                                name="useSSL"
                                                value="useSSL"
                                                checked={updatedSmtpConfig.enableSsl ? updatedSmtpConfig.enableSsl : false}
                                                onChange={(e) => setUpdatedSmtpConfig({ ...updatedSmtpConfig, enableSsl: e.target.checked })}
                                            >
                                                TLS Enabled
                                            </Checkboxes.Box>
                                        </Checkboxes>

                                        <Input
                                            id="fromAddress"
                                            label="From Address"
                                            name="fromAddress"
                                            type="text"
                                            onChange={(e) => setUpdatedSmtpConfig({ ...updatedSmtpConfig, fromAddress: e.target.value })}
                                            value={updatedSmtpConfig.fromAddress ? updatedSmtpConfig.fromAddress : ''}
                                            required
                                        />
                                        <Input
                                            id="fromName"
                                            label="From Name"
                                            name="fromName"
                                            type="text"
                                            onChange={(e) => setUpdatedSmtpConfig({ ...updatedSmtpConfig, fromName: e.target.value })}
                                            value={updatedSmtpConfig.fromName ? updatedSmtpConfig.fromName : ''}
                                            required
                                        />

                                        <Textarea
                                            id="description"
                                            label="Description"
                                            name="description"
                                            onChange={(e) => setUpdatedSmtpConfig({ ...updatedSmtpConfig, description: e.target.value })}
                                            value={updatedSmtpConfig.description ? updatedSmtpConfig.description : ''}
                                        />


                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The smtpConfig was created successfully." : "The smtpConfig was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }



                                    </Card.Description>
                                    <Button
                                        disabled={smtpConfigUpdateProcessing}
                                        onClick={handleUpdateSmtpConfig}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new SMTP Config
                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name"
                                            label="Name"
                                            name="name"
                                            type="text"
                                            onChange={(e) => setNewSmtpConfig({ ...newSmtpConfig, name: e.target.value })}
                                            value={newSmtpConfig.name ? newSmtpConfig.name : ''}
                                            required
                                        />
                                        <Input
                                            id="host"
                                            label="Host"
                                            name="host"
                                            type="text"
                                            onChange={(e) => setNewSmtpConfig({ ...newSmtpConfig, host: e.target.value })}
                                            value={newSmtpConfig.host ? newSmtpConfig.host : ''}
                                            required
                                        />
                                        <Input
                                            id="port"
                                            label="Port"
                                            name="port"
                                            type="text"
                                            onChange={(e) => setNewSmtpConfig({ ...newSmtpConfig, port: e.target.value })}
                                            value={newSmtpConfig.port ? newSmtpConfig.port : ''}
                                            required
                                        />
                                        <Input
                                            id="username"
                                            label="Username"
                                            name="username"
                                            type="text"
                                            onChange={(e) => setNewSmtpConfig({ ...newSmtpConfig, username: e.target.value })}
                                            value={newSmtpConfig.username ? newSmtpConfig.username : ''}
                                            required
                                        />
                                        <Input
                                            id="password"
                                            label="Password"
                                            name="password"
                                            type="password"
                                            onChange={(e) => setNewSmtpConfig({ ...newSmtpConfig, password: e.target.value })}
                                            value={newSmtpConfig.password ? newSmtpConfig.password : ''}
                                            required
                                        />
                                        <Checkboxes name="checkboxes" hint="Connection information">
                                            <Checkboxes.Box
                                                conditional={<p>Use this server as a default email server</p>}
                                                id="default"
                                                name="default"
                                                value="default"
                                                checked={newSmtpConfig.isDefault ? newSmtpConfig.isDefault : false}
                                                onChange={(e) => setNewSmtpConfig({ ...newSmtpConfig, isDefault: e.target.checked })}
                                            >
                                                Make Default
                                            </Checkboxes.Box>
                                            <Checkboxes.Box
                                                conditional={<p>Use TLS Encrypted Connection to send emails</p>}
                                                id="useSSL"
                                                name="useSSL"
                                                value="useSSL"
                                                checked={newSmtpConfig.enableSsl ? newSmtpConfig.enableSsl : false}
                                                onChange={(e) => setNewSmtpConfig({ ...newSmtpConfig, enableSsl: e.target.checked })}
                                            >
                                                TLS Enabled
                                            </Checkboxes.Box>
                                        </Checkboxes>

                                        <Input
                                            id="fromAddress"
                                            label="From Address"
                                            name="fromAddress"
                                            type="text"
                                            onChange={(e) => setNewSmtpConfig({ ...newSmtpConfig, fromAddress: e.target.value })}
                                            value={newSmtpConfig.fromAddress ? newSmtpConfig.fromAddress : ''}
                                            required
                                        />
                                        <Input
                                            id="fromName"
                                            label="From Name"
                                            name="fromName"
                                            type="text"
                                            onChange={(e) => setNewSmtpConfig({ ...newSmtpConfig, fromName: e.target.value })}
                                            value={newSmtpConfig.fromName ? newSmtpConfig.fromName : ''}
                                            required
                                        />

                                        <Textarea
                                            id="description"
                                            label="Description"
                                            name="description"
                                            onChange={(e) => setNewSmtpConfig({ ...newSmtpConfig, description: e.target.value })}
                                            value={newSmtpConfig.description ? newSmtpConfig.description : ''}
                                        />


                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The smtpConfig was created successfully." : "The smtpConfig was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }


                                    </Card.Description>

                                    <Button
                                        disabled={smtpConfigUpdateProcessing}
                                        onClick={handleCreateSmtpConfig}
                                    >
                                        Create a new SMTP Config
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






