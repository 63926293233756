import React, { useState, useEffect } from 'react';
import { Select, Input } from 'nhsuk-react-components';

const SelectOther = ({ label, value, onChange, children }) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [showInput, setShowInput] = useState(value === 'Other');
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    if (!children.some(option => option.props.value === value)) {
      // If the provided value doesn't match any Select.Option, set to 'Other'
      setSelectedOption('Other');
      setShowInput(true);
      setInputValue(value); // Show the provided value in the input
    } else {
      setSelectedOption(value);
      setShowInput(value === 'Other');
      setInputValue(value === 'Other' ? inputValue : '');
    }
  }, [value, children]);

  const handleOptionChange = (selectedValue) => {
    setSelectedOption(selectedValue);
    setShowInput(selectedValue === 'Other');
    setInputValue('Other');
    onChange({ target: { value: selectedValue } });
  };
    
  const handleInputChange = (newInputValue) => {
    setInputValue(newInputValue);
    onChange({ target: { value: newInputValue } });
  };


  return (
    <div>
      <Select label={label} value={selectedOption} onChange={(e) => handleOptionChange(e.target.value)}>
        {children}
        <Select.Option value="Other">Other</Select.Option>
      </Select>
      {showInput && <Input type="text" value={inputValue} onChange={(e) => handleInputChange(e.target.value)} />}
    </div>
  );
};

export default SelectOther;
