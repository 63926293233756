import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { WarningCallout, BackLink, Card, SummaryList } from "nhsuk-react-components";
import { getPatientEmergencyDetails } from "../../actions/patientActions";
import { listAetiologiesByIds } from '../../actions/aetiologyActions';
import { listEcgsByIds } from '../../actions/ecgActions';
import { listSymptomsByIds } from '../../actions/symptomActions';
import { listFollowupCentresByIds } from '../../actions/followupCentreActions';
import { listImplantCentresByIds } from '../../actions/implantCentreActions';
import { listImplantInstancesByPatientNhsNumber } from "../../actions/implantInstanceActions";
import { listConsentsByIds } from "../../actions/consentActions";
import Modal from "../../components/modal/Modal.component";
import ImplantInstanceListReadOnly from "../../components/implantInstanceList/ImplantInstanceListReadOnly.component";
import Checklist from "../../components/checklist/Checklist.component";
import { convertToComponentDate } from "../../utils/functions";

export default function PatientEmergencyAccessScreen({ history, match }) {
    const dispatch = useDispatch();
    const patientId = match.params._id;

    const excludedKeys = ["id", "patient", "patientConsents", "patientSymtoms", "patientAetiologies", "patientEcgs", "patientImplantCentres", "patientFollowUpCentres"];

    const [patient, setPatient] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [genericModalShow, setGenericModalShow] = useState(false);
    const [genericModalContent, setGenericModalContent] = useState(null);

    const patientEmergencyDetails = useSelector((state) => state.patientEmergencyDetails);
    const { loading, error, patient: fetchedPatient } = patientEmergencyDetails;

    const aetiologyListByIDs = useSelector((state) => state.aetiologyListByIDs);
    const { aetiologies } = aetiologyListByIDs;

    const ecgListByIDs = useSelector((state) => state.ecgListByIDs);
    const { ecgs } = ecgListByIDs;

    const symptomListByIDs = useSelector((state) => state.symptomListByIDs);
    const { symptoms } = symptomListByIDs;

    const followupCentreListByIDs = useSelector((state) => state.followupCentreListByIDs);
    const { followupCentres } = followupCentreListByIDs;

    const implantCentreListByIDs = useSelector((state) => state.implantCentreListByIDs);
    const { implantCentres } = implantCentreListByIDs;

    const implantInstanceListByNhsNumber = useSelector((state) => state.implantInstanceListByNhsNumber);
    const { implantInstances } = implantInstanceListByNhsNumber;

    const consentListByIDs = useSelector((state) => state.consentListByIDs);
    const { consents } = consentListByIDs;



    useEffect(() => {
        document.title = "Emergency Access";
        dispatch(getPatientEmergencyDetails(patientId));
    }, [dispatch, patientId]);

    useEffect(() => {
        if (fetchedPatient && fetchedPatient.id === patientId) {
            setPatient(fetchedPatient);
            setDateOfBirth(convertToComponentDate(fetchedPatient.dateOfBirth));
            dispatch(listAetiologiesByIds(fetchedPatient.aetiologyIds));
            dispatch(listEcgsByIds(fetchedPatient.ecgIds));
            dispatch(listSymptomsByIds(fetchedPatient.symptomIds));
            dispatch(listFollowupCentresByIds(fetchedPatient.followUpCentreIds));
            dispatch(listImplantCentresByIds(fetchedPatient.implantCentreIds));
            dispatch(listImplantInstancesByPatientNhsNumber(fetchedPatient.nhsNumber));
            dispatch(listConsentsByIds(fetchedPatient.consentIds));
        }
    }, [dispatch, fetchedPatient]);

    const handleEntityClick = (entity) => {
        if (!entity) return;
        setGenericModalContent(entity);
        setGenericModalShow(true);
    };

    const closeGenericModal = () => {
        setGenericModalShow(false);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!patient) return <div>No patient data available</div>;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h1>Emergency Access</h1>
                    <WarningCallout label="Warning callout">
                        <WarningCallout.Label>Important Note</WarningCallout.Label>
                        <p>Unauthorized access to emergency patient data is strictly prohibited. Clinicians, ensure that your code usage is solely for emergency purposes. Any misuse or unauthorized access will result in severe consequences. Prioritize patient privacy and adhere to ethical coding practices.</p>
                    </WarningCallout>
                    <BackLink href="#" onClick={() => window.location.href = '/'}>Go back</BackLink>
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-one-half">
                            <Card>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        <FaUser size={30} />
                                        {patient.forename} {patient.surname}
                                    </Card.Heading>
                                    <SummaryList>
                                        <SummaryList.Row>
                                            <SummaryList.Key>NHS Number</SummaryList.Key>
                                            <SummaryList.Value>{patient.nhsNumber}</SummaryList.Value>
                                        </SummaryList.Row>
                                        <SummaryList.Row>
                                            <SummaryList.Key>Date of Birth</SummaryList.Key>
                                            <SummaryList.Value>{dateOfBirth?.day}/{dateOfBirth?.month}/{dateOfBirth?.year}</SummaryList.Value>
                                        </SummaryList.Row>
                                        <SummaryList.Row>
                                            <SummaryList.Key>Abandoned Hardware</SummaryList.Key>
                                            <SummaryList.Value>{patient.abondonedHardware ? "Yes" : "No"}</SummaryList.Value>
                                        </SummaryList.Row>
                                        <SummaryList.Row>
                                            <SummaryList.Key>MR Conditional</SummaryList.Key>
                                            <SummaryList.Value>{patient.mrConditional ? "Yes" : "No"}</SummaryList.Value>
                                        </SummaryList.Row>
                                        {/* Render other patient details similarly */}
                                    </SummaryList>
                                    {/* Render lists for aetiologies, symptoms, ecgs, implant centres, and followup centres */}
                                    {[
                                        { title: "Aetiologies", items: aetiologies },
                                        { title: "Symptoms", items: symptoms },
                                        { title: "ECGs", items: ecgs },
                                        { title: "Implant Centres", items: implantCentres },
                                        { title: "Followup Centres", items: followupCentres }
                                    ].map(({ title, items }) => (
                                        <SummaryList.Row key={title}>
                                            <SummaryList.Key>{title}</SummaryList.Key>
                                            <SummaryList.Value>
                                                <ul>
                                                    {items?.map(item => (
                                                        <li key={item.id}>
                                                            <Link to="#" onClick={() => handleEntityClick(item)}>
                                                                {item.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </SummaryList.Value>
                                        </SummaryList.Row>
                                    ))}
                                </Card.Content>
                            </Card>
                        </div>
                        <div className="nhsuk-grid-column-one-half">
                            <Card style={{ padding: "30px" }}>
                                <Card.Heading className="nhsuk-heading-m">Implants</Card.Heading>
                                <Card.Description>
                                    <ImplantInstanceListReadOnly patientNHSNumber={patient.nhsNumber} />
                                    <div className="nhsuk-u-padding-top-3"></div>
                                    <h3>Patient consents</h3>
                                    <Checklist name="checklist" hint="Consent information." heading="This patient allows:" expand={true}>
                                        {consents?.map(consent => (
                                            <Checklist.Item
                                                key={consent.id}
                                                mode="success"
                                                id={consent.id}
                                                name={consent.consentType}
                                            >
                                                <Link to="#" onClick={() => handleEntityClick(consent)}>
                                                    {consent.consentType}
                                                </Link>
                                            </Checklist.Item>
                                        ))}
                                    </Checklist>
                                </Card.Description>
                            </Card>
                        </div>
                    </div>
                </Container>
            </div>
            <Modal show={genericModalShow} handleClose={closeGenericModal}>
                {genericModalContent && (
                    <SummaryList>
                        {Object.entries(genericModalContent).map(([key, value]) => (
                            !excludedKeys.includes(key) && (
                                <SummaryList.Row key={key}>
                                    <SummaryList.Key>
                                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                    </SummaryList.Key>
                                    <SummaryList.Value>
                                        {value ? value.toString() : "-"}
                                    </SummaryList.Value>
                                </SummaryList.Row>
                            )
                        ))}
                    </SummaryList>
                )}
            </Modal>
        </React.Fragment>
    );
}