export const TEMPLATE_LIST_REQUEST = 'TEMPLATE_LIST_REQUEST'
export const TEMPLATE_LIST_SUCCESS = 'TEMPLATE_LIST_SUCCESS'
export const TEMPLATE_LIST_FAIL = 'TEMPLATE_LIST_FAIL'


export const TEMPLATE_DETAILS_REQUEST = 'TEMPLATE_DETAILS_REQUEST'
export const TEMPLATE_DETAILS_SUCCESS = 'TEMPLATE_DETAILS_SUCCESS'
export const TEMPLATE_DETAILS_FAIL = 'TEMPLATE_DETAILS_FAIL'


export const TEMPLATE_DELETE_REQUEST = 'TEMPLATE_DELETE_REQUEST'
export const TEMPLATE_DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS'
export const TEMPLATE_DELETE_FAIL = 'TEMPLATE_DELETE_FAIL'

export const TEMPLATE_CREATE_REQUEST = 'TEMPLATE_CREATE_REQUEST'
export const TEMPLATE_CREATE_SUCCESS = 'TEMPLATE_CREATE_SUCCESS'
export const TEMPLATE_CREATE_FAIL = 'TEMPLATE_CREATE_FAIL'
export const TEMPLATE_CREATE_RESET = 'TEMPLATE_CREATE_RESET'


export const TEMPLATE_UPDATE_REQUEST = 'TEMPLATE_UPDATE_REQUEST'
export const TEMPLATE_UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS'
export const TEMPLATE_UPDATE_FAIL = 'TEMPLATE_UPDATE_FAIL'
export const TEMPLATE_UPDATE_RESET = 'TEMPLATE_UPDATE_RESET'

export const TEMPLATE_REQUEST = 'TEMPLATE_REQUEST'
export const TEMPLATE_SUCCESS = 'TEMPLATE_SUCCESS'
export const TEMPLATE_FAIL = 'TEMPLATE_FAIL'
export const TEMPLATE_RESET = 'TEMPLATE_RESET'

export const TEMPLATE_COUNT_REQUEST = "TEMPLATE_COUNT_REQUEST";
export const TEMPLATE_COUNT_SUCCESS = "TEMPLATE_COUNT_SUCCESS";
export const TEMPLATE_COUNT_FAIL = "TEMPLATE_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'