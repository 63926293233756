export const IMPLANT_INSTANCE_LIST_REQUEST = 'IMPLANT_INSTANCE_LIST_REQUEST'
export const IMPLANT_INSTANCE_LIST_SUCCESS = 'IMPLANT_INSTANCE_LIST_SUCCESS'
export const IMPLANT_INSTANCE_LIST_FAIL = 'IMPLANT_INSTANCE_LIST_FAIL'


export const IMPLANT_INSTANCE_DETAILS_REQUEST = 'IMPLANT_INSTANCE_DETAILS_REQUEST'
export const IMPLANT_INSTANCE_DETAILS_SUCCESS = 'IMPLANT_INSTANCE_DETAILS_SUCCESS'
export const IMPLANT_INSTANCE_DETAILS_FAIL = 'IMPLANT_INSTANCE_DETAILS_FAIL'


export const IMPLANT_INSTANCE_DELETE_REQUEST = 'IMPLANT_INSTANCE_DELETE_REQUEST'
export const IMPLANT_INSTANCE_DELETE_SUCCESS = 'IMPLANT_INSTANCE_DELETE_SUCCESS'
export const IMPLANT_INSTANCE_DELETE_FAIL = 'IMPLANT_INSTANCE_DELETE_FAIL'

export const IMPLANT_INSTANCE_CREATE_REQUEST = 'IMPLANT_INSTANCE_CREATE_REQUEST'
export const IMPLANT_INSTANCE_CREATE_SUCCESS = 'IMPLANT_INSTANCE_CREATE_SUCCESS'
export const IMPLANT_INSTANCE_CREATE_FAIL = 'IMPLANT_INSTANCE_CREATE_FAIL'
export const IMPLANT_INSTANCE_CREATE_RESET = 'IMPLANT_INSTANCE_CREATE_RESET'


export const IMPLANT_INSTANCE_UPDATE_REQUEST = 'IMPLANT_INSTANCE_UPDATE_REQUEST'
export const IMPLANT_INSTANCE_UPDATE_SUCCESS = 'IMPLANT_INSTANCE_UPDATE_SUCCESS'
export const IMPLANT_INSTANCE_UPDATE_FAIL = 'IMPLANT_INSTANCE_UPDATE_FAIL'
export const IMPLANT_INSTANCE_UPDATE_RESET = 'IMPLANT_INSTANCE_UPDATE_RESET'

export const IMPLANT_INSTANCE_REQUEST = 'IMPLANT_INSTANCE_REQUEST'
export const IMPLANT_INSTANCE_SUCCESS = 'IMPLANT_INSTANCE_SUCCESS'
export const IMPLANT_INSTANCE_FAIL = 'IMPLANT_INSTANCE_FAIL'
export const IMPLANT_INSTANCE_RESET = 'IMPLANT_INSTANCE_RESET'

export const IMPLANT_INSTANCE_COUNT_REQUEST = "IMPLANT_INSTANCE_COUNT_REQUEST";
export const IMPLANT_INSTANCE_COUNT_SUCCESS = "IMPLANT_INSTANCE_COUNT_SUCCESS";
export const IMPLANT_INSTANCE_COUNT_FAIL = "IMPLANT_INSTANCE_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'