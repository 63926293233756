import React from 'react';
import { Pagination } from "nhsuk-react-components";

const NavigationButtons = ({ currentStep, steps, setCurrentStep }) => {
  return (
    <div  style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
      {currentStep > 0 ? (
        <Pagination.Link
          href="#"
          onClick={() => setCurrentStep(currentStep - 1)}
          previous
        >
          {steps[currentStep - 1].title}
        </Pagination.Link>
      ) : <div></div>}
      {currentStep < steps.length - 1 && (
        <Pagination.Link
          href="#"
          onClick={() => setCurrentStep(currentStep + 1)}
          next
        >
          {steps[currentStep + 1].title}
        </Pagination.Link>
      )}
    </div>
  );
};

export default NavigationButtons;