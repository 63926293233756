import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary } from 'nhsuk-react-components';
import { listAetiologies, createAetiology, updateAetiology, deleteAetiology, getAetiologyDetails } from '../../actions/aetiologyActions';
// constants 
import { AETIOLOGY_CREATE_RESET, AETIOLOGY_UPDATE_RESET } from '../../constants/aetiologyConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';

// debouncer 
import { debounce } from 'lodash';

export default function AetiologyCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    // reset the state of the aetiologyCreate and aetiologyUpdate
    useEffect(() => {
        dispatch({ type: AETIOLOGY_CREATE_RESET });
        dispatch({ type: AETIOLOGY_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/aetiology/all?page=1&limit=2&filter=Email&query=gmail
    const [aetiologyListFilter, setaetiologyListFilter] = useState({ query: '', page: 1, filter: 'Name' });

    // get aetiology list from redux store
    const aetiologyList = useSelector((state) => state.aetiologyList);
    const { loading, error, aetiologies, currentPage, totalPages } = aetiologyList;

    // get aetiology create from redux store
    const aetiologyCreate = useSelector((state) => state.aetiologyCreate);
    const { success: successCreate, error: aetiologyCreateErrors } = aetiologyCreate;

    // get aetiology update from redux store
    const aetiologyUpdate = useSelector((state) => state.aetiologyUpdate);
    const { success: successUpdate, loading: aetiologyUpdateProcessing } = aetiologyUpdate;

    // get aetiology delete from redux store
    const aetiologyDelete = useSelector((state) => state.aetiologyDelete);
    const { success: successDelete } = aetiologyDelete;

    // get aetiology details from redux store
    const aetiologyDetails = useSelector((state) => state.aetiologyDetails);
    const { aetiology: aetiologyDet, loading: aetiologyDetailsLoading } = aetiologyDetails;

    // newAetiology state
    const [newAetiology, setNewAetiology] = useState({});
    const [updatedAetiology, setUpdatedAetiology] = useState({});
    const [editMode, setEditMode] = useState(false);


    // listen to filter changes
    useEffect(() => {
        dispatch(listAetiologies(aetiologyListFilter));
    }, [dispatch, aetiologyListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setaetiologyListFilter({ ...aetiologyListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setaetiologyListFilter({ ...aetiologyListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setaetiologyListFilter({ ...aetiologyListFilter, filter: selectedFilter });
    };

    // get all aetiologies
    useEffect(() => {
        dispatch(listAetiologies({}));
    }, [dispatch]);

    // listen to aetiologyDet 
    useEffect(() => {
        if (aetiologyDet) {
            // use spread operator to copy the aetiologyDet object
            setUpdatedAetiology({ ...aetiologyDet });
        }
    }, [aetiologyDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newAetiology state
            setNewAetiology({
                name: '',
                description: '',
            });
        }
    }, [editMode]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this aetiology?')) {
            dispatch(deleteAetiology(id)).then(() => {
                dispatch(listAetiologies({}));
            }
            );
        }
    };

    // handle edit
    const handleEditAetiology = (id) => {
        dispatch(getAetiologyDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateAetiology = () => {
        dispatch(createAetiology(newAetiology)).then(() => {
            setUpdatedAetiology(newAetiology);
            setEditMode(false);
            dispatch(listAetiologies({}));
        }
        );
    };

    // handle update
    const handleUpdateAetiology = () => {
        dispatch(updateAetiology(updatedAetiology.id, updatedAetiology)).then(() => {

            setUpdatedAetiology(updatedAetiology);
            setEditMode(true);
            dispatch(getAetiologyDetails(updatedAetiology.id));
        }
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Aetiology Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the aetiologies. You can create, update and delete aetiologies in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">

                            {aetiologyCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {aetiologyCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {aetiologyCreateErrors.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null

                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-aetiologies"
                                        label="Search aetiologies"
                                        name="search-aetiologies"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {aetiologies && aetiologies.length > 0 ? (
                                            aetiologies.map((aetiology) => (
                                                <Details key={aetiology._id}>
                                                    <Details.Summary>
                                                        {aetiology.name}
                                                    </Details.Summary>
                                                    <Details.Text>
                                                        <p>
                                                            <strong>Description:</strong> {aetiology.description}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={aetiologyDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditAetiology(aetiology.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(aetiology.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit Aetiology
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an aetiology. If you want to create a new aetiology, please click the "Create Aetiology" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create Aetiology
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name"
                                            label="Name"
                                            name="name"
                                            type="text"
                                            onChange={(e) => setUpdatedAetiology({ ...updatedAetiology, name: e.target.value })}
                                            value={updatedAetiology.name ? updatedAetiology.name : ''}
                                            required
                                        />
                                        <Textarea
                                            id="description"
                                            label="Description"
                                            name="description"
                                            onChange={(e) => setUpdatedAetiology({ ...updatedAetiology, description: e.target.value })}
                                            value={updatedAetiology.description ? updatedAetiology.description : ''}
                                        />


                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The aetiology was created successfully." : "The aetiology was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={aetiologyUpdateProcessing}
                                        onClick={handleUpdateAetiology}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new Aetiology
                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name-create"
                                            label="Name"
                                            name="name-create"
                                            type="text"
                                            onChange={(e) => setNewAetiology({ ...newAetiology, name: e.target.value })}
                                            value={newAetiology?.name}
                                            required
                                        />
                                        <Textarea
                                            id="description-create"
                                            label="Description"
                                            name="description-create"
                                            onChange={(e) => setNewAetiology({ ...newAetiology, description: e.target.value })}
                                            value={newAetiology?.description}
                                        />

                                    </Card.Description>

                                    <Button
                                        disabled={aetiologyUpdateProcessing}
                                        onClick={handleCreateAetiology}
                                    >
                                        Create Aetiology
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






