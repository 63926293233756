import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import card from nhsuk-frontend/packages/components/card/card";
// You can import components from the global module
import { ActionLink, Button, Card, CareCard, ContentsList, WarningCallout } from 'nhsuk-react-components';
import { FaDatabase, FaMagnifyingGlass, FaBoxArchive, FaUserPlus, FaGauge, FaTriangleExclamation, FaUserGroup, FaGear, FaBook } from "react-icons/fa6";
import { FcAddressBook, FcBusinessman, FcDataBackup, FcElectroDevices, FcSettings, FcSurvey } from "react-icons/fc";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaAutoprefixer, FaBuilding, FaChevronCircleUp, FaCottonBureau, FaCreditCard, FaDotCircle, FaHeadSideVirus, FaIoxhost, FaPassport } from "react-icons/fa";
import Callout from "../components/callout/Callout.component";
import FeatureCard from "../components/featureCard/featureCard.component";
import axios from "axios";

function DashboadScreen(props) {

  // user info from redux store
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [userEmailConfirmed, setUserEmailConfirmed] = useState(false);
  const [sendingEmailConfirmationLink, setSendingEmailConfirmationLink] = useState(false);
  const [userEmailConfirmationSentSucess, setUserEmailConfirmationSentSuccess] = useState(false);
  const [userEmailConfirmationSentError, setUserEmailConfirmationSentError] = useState(false);
  // listen to userInfo changes
  useEffect(() => {
    if (userInfo) {
      setUserEmailConfirmed(userInfo.emailConfirmed);
    }
  }, [userInfo]);

  useEffect(() => {
    document.title = "Dashboard";
  }, []);


  // handle resend email confirmation link
  const hanfleResentEmailConfirmationLink = (e) => {
    e.preventDefault();
    // post request to resend email confirmation link to /api/v1/auth/resendemailconfirmationlink {Email: email}
    setSendingEmailConfirmationLink(true);
    axios
      .post("/api/v1/auth/resendemailconfirmationlink", { Email: userInfo.email })
      .then((res) => {
        // if success (200)
        // set userInfo.emailConfirmed to true
        if (res.status === 200) {
          setUserEmailConfirmationSentSuccess(true);
          setUserEmailConfirmationSentError(false);
          setSendingEmailConfirmationLink(false);
        }
      })
      .catch((err) => {
        // if error
        // show error message
        setUserEmailConfirmationSentSuccess(false);
        setUserEmailConfirmationSentError(true);
        setSendingEmailConfirmationLink(false);
      });

  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
            
              {!userEmailConfirmed && (
                <Callout
                  mode="warning"
                  header="Email not confirmed"
                  title="Warning callout"
                  label="Important"
                  actionLinkEnabled={true}
                  actionLabel={sendingEmailConfirmationLink ? "Sending..." : "Resend confirmation email"}
                  actionHandler={hanfleResentEmailConfirmationLink}
                  message={`Your account is not confirmed yet. Please check your email and confirm your account. If you haven't received the email, 
                    please click on this link to resend the confirmation email.`}
                />
              )}

              {userEmailConfirmationSentSucess && (
                <Callout
                  mode="success"
                  header="Email confirmation sent"
                  title="Success callout"
                  label="Success"
                  message={`A confirmation email has been sent to ${userInfo.email}. Please check your email and confirm your account.`}
                />
              )}

              {userEmailConfirmationSentError && (
                <Callout
                  mode="error"
                  header="Email confirmation not sent"
                  title="Error callout"
                  label="Error"
                  message={`An error occured while sending the confirmation email to ${userInfo.email}. Please try again later.`}
                />
              )}

              {userInfo && userInfo.roles.includes("UnauthorizedUser") && (
                <WarningCallout label="Warning callout">
                  <WarningCallout.Label>Important</WarningCallout.Label>
                  <p>Hi {userInfo.username},</p>
                  <p>Your account is being reviewed by an administrator. You will receive a confirmation by email to access the system once your account is approved.</p>
                </WarningCallout>
              )}

              {userInfo && userInfo.roles.includes("Patient") && (!userInfo.consentIds || userInfo.consentIds.length < 1) && (
                <Callout
                  mode="warning"
                  actionLinkEnabled={true}
                  header="Emergency access consent required"
                  title="Warning callout"
                  label="Important"
                  message={`Emergency access allows clinicians from other organizations to quickly access your patient data and implant device information in case of an emergency. This ensures that they have the necessary information to provide you with the best possible care. If you have not granted emergency access consent, please contact your GP.`}
                  actionHandler={() => { props.history.push("/myprofile") }}
                  actionLabel="Go to My Profile"

                />

              )}
              <div className="cards-grid">

                <FeatureCard
                  roles={["Patient"]}
                  currentUserRole={userInfo?.roles[0]}
                  Icon={FaCreditCard}
                  title="My Heart Passport"
                  link="/myprofile"
                />

                <FeatureCard
                  roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                  currentUserRole={userInfo?.roles[0]}
                  Icon={FaMagnifyingGlass}
                  title="Find Patients"
                  link="/patients"
                />
                <FeatureCard
                  roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                  currentUserRole={userInfo?.roles[0]}
                  Icon={FaUserPlus}
                  title="Add Patient"
                  link="/patients/create"
                />



              </div>
            </Col>
          </Row>
          <Row>
            {userInfo && (userInfo.roles.includes("CardiologyStaff") || userInfo.roles.includes("SuperUser") || userInfo.roles.includes("TrustUserManager")) && (
              <Col xl={12}>
                <h3>Service Management</h3>
                <div className="cards-grid">
                  <FeatureCard
                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                    currentUserRole={userInfo?.roles[0]}
                    Icon={FaGauge}
                    title="Service Dashboard"
                    link="/service_dashboard"
                  />

                  <FeatureCard
                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                    currentUserRole={userInfo?.roles[0]}
                    Icon={FaBook}
                    title="Implant Registry"
                    link="/implants"
                  />

                  <FeatureCard
                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                    currentUserRole={userInfo?.roles[0]}
                    Icon={FaTriangleExclamation}
                    title="Implant Alerts"
                    link="/implant_alerts"
                  />
                  <FeatureCard
                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                    currentUserRole={userInfo?.roles[0]}
                    Icon={FaUserGroup}
                    title="User Management"
                    link="/user_management"
                  />
                  <FeatureCard
                    roles={["CardiologyStaff", "SuperUser", "TrustUserManager"]}
                    currentUserRole={userInfo?.roles[0]}
                    Icon={FaGear}
                    title="Settings"
                    link="/settings"
                  />

                </div>


              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default DashboadScreen;