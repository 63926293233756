import {

    ECG_LIST_REQUEST,
    ECG_LIST_SUCCESS,
    ECG_LIST_FAIL,

    ECG_DETAILS_REQUEST,
    ECG_DETAILS_SUCCESS,
    ECG_DETAILS_FAIL,

    ECG_DELETE_REQUEST,
    ECG_DELETE_SUCCESS,
    ECG_DELETE_FAIL,

    ECG_CREATE_REQUEST,
    ECG_CREATE_SUCCESS,
    ECG_CREATE_FAIL,
    ECG_CREATE_RESET,

    ECG_UPDATE_REQUEST,
    ECG_UPDATE_SUCCESS,
    ECG_UPDATE_FAIL,
    ECG_UPDATE_RESET,

    ECG_COUNT_SUCCESS,
    ECG_COUNT_REQUEST,
    ECG_COUNT_FAIL,

    ECG_LIST_BYIDS_REQUEST,
    ECG_LIST_BYIDS_SUCCESS,
    ECG_LIST_BYIDS_FAIL,
    ECG_LIST_BYIDS_RESET,

    SET_CURRENT_PAGE,

} from "../constants/ecgConstants";

const initialState = {
    ecgs: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const ecgListReducer = (
    state = {
        ecgs: []
    }, action) => {
    switch (action.type) {
        case ECG_LIST_REQUEST:
            return { loading: true, ecgs: [] };
        case ECG_LIST_SUCCESS:
            // our state will have a list of ecgs after the request
            return {
                loading: false,
                ecgs: action.payload.ecgs,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case ECG_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const ecgListByIDsReducer = (
    state = {
        ecgs: []
    }, action) => {
    switch (action.type) {
        case ECG_LIST_BYIDS_REQUEST:
            return { loading: true, ecgs: [] };
        case ECG_LIST_BYIDS_SUCCESS:
            return { loading: false, ecgs: action.payload };
        case ECG_LIST_BYIDS_FAIL:
            return { loading: false, error: action.payload };
        case ECG_LIST_BYIDS_RESET:
            return { ecgs: [] };
        default:
            return state;
    }
}

export const ecgCountReducer = (state = { ecgCounts: [] }, action) => {
  switch (action.type) {
    case ECG_COUNT_REQUEST:
      return { loading: true, ecgCounts: [] };
    case ECG_COUNT_SUCCESS:
      return { loading: false, ecgCounts: action.payload };
    case ECG_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ecgDetailsReducer = (
    state = {
        ecg: { comments: [] }
    }, action) => {
    switch (action.type) {
        case ECG_DETAILS_REQUEST:
            return { loading: true, ...state };
        case ECG_DETAILS_SUCCESS:
            // our state will have a list of ecgs after the request
            return { loading: false, ecg: action.payload };
        case ECG_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const ecgDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ECG_DELETE_REQUEST:
            return { loading: true }

        case ECG_DELETE_SUCCESS:
            return { loading: false, success: true }

        case ECG_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const ecgCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ECG_CREATE_REQUEST:
            return { loading: true }

        case ECG_CREATE_SUCCESS:
            return { loading: false, success: true, ecg: action.payload }

        case ECG_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case ECG_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const ecgUpdateReducer = (state = { ecg: {} }, action) => {
    switch (action.type) {
        case ECG_UPDATE_REQUEST:
            return { loading: true }

        case ECG_UPDATE_SUCCESS:
            return { loading: false, success: true, ecg: action.payload }

        case ECG_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case ECG_UPDATE_RESET:
            return { ecg: {} }

        default:
            return state
    }
}