import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from "./store";

import { Provider } from "react-redux";
// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
// non auth layout 
import NonAuthLayout from "./components/layout/NonAuthLayout.component";
// import homescreen 
import Layout from "./components/layout/Layout.component";
// import not found screen
import NotFoundScreen from "./screens/NotFoundScreen";

import 'nhsuk-frontend/dist/nhsuk.css';
import './assets/css/nhsuk.css';
import 'react-responsive-modal/styles.css';


const App = () => (

  <Provider store={store}>
    <Router>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            exact
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            roles={route.roles}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
        <Route path="*" component={NotFoundScreen} />
      </Switch>

    </Router>
  </Provider>
);


export default App;