import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary, Select, Checkboxes } from 'nhsuk-react-components';
import { listImplantInstances, createImplantInstance, updateImplantInstance, deleteImplantInstance, getImplantInstanceDetails, listImplantInstancesByPatientNhsNumber } from '../../actions/implantInstanceActions';
import { getImplantDetailsByBarcode } from '../../actions/implantActions';

// constants 
import { IMPLANT_INSTANCE_CREATE_RESET, IMPLANT_INSTANCE_UPDATE_RESET } from '../../constants/implantInstanceConstants';
import Pagination from '../pagination/Pagination.component';
import AppSpinner from '../spinner/AppSpinner.component';

import Callout from '../callout/Callout.component';
import SelectOther from '../selectOther/SelectOther.component';
import Modal from '../modal/Modal.component';
import { formatISODate } from '../../utils/functions';


const ImplantInstanceListReadOnly = ({ patientNHSNumber }) => {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();


    // reset the state of the implantInstanceCreate and implantInstanceUpdate
    useEffect(() => {
        dispatch({ type: IMPLANT_INSTANCE_CREATE_RESET });
        dispatch({ type: IMPLANT_INSTANCE_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/implantInstance/all?page=1&limit=2&filter=Email&query=gmail
    const [implantInstanceListFilter, setimplantInstanceListFilter] = useState({ query: '', page: 1, filter: 'patientNHSNumber' });

    // get implantInstance list from redux store
    const implantInstanceListByNhsNumber = useSelector((state) => state.implantInstanceListByNhsNumber);
    const { implantInstances, loading, error, currentPage, totalPages } = implantInstanceListByNhsNumber;

    // get implantInstance create from redux store
    const implantInstanceCreate = useSelector((state) => state.implantInstanceCreate);
    const { success: successCreate, error: implantInstanceCreateErrors } = implantInstanceCreate;

    // get implantInstance update from redux store
    const implantInstanceUpdate = useSelector((state) => state.implantInstanceUpdate);
    const { success: successUpdate, loading: implantInstanceUpdateProcessing } = implantInstanceUpdate;

    // get implantInstance delete from redux store
    const implantInstanceDelete = useSelector((state) => state.implantInstanceDelete);
    const { success: successDelete } = implantInstanceDelete;

    // get implantInstance details from redux store
    const implantInstanceDetails = useSelector((state) => state.implantInstanceDetails);
    const { implantInstance: implantInstanceDet, loading: implantInstanceDetailsLoading } = implantInstanceDetails;

    // get implant details from redux store
    const implantDetailsByBarcode = useSelector((state) => state.implantDetailsByBarcode);
    const { implant, loading: loadingImplantDetailsByBarcode, error: errorImplantDetailsByBarcode, success: successImplantDetailsByBarcode } = implantDetailsByBarcode;

    // newImplantInstance state
    const [newImplantInstance, setNewImplantInstance] = useState({});
    const [updatedImplantInstance, setUpdatedImplantInstance] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [isLead, setIsLead] = useState(true);
    const [isGenerator, setIsGenerator] = useState(true);
    const [autoFillDetail, setAutoFillDetail] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isMRIConditional, setIsMRIConditional] = useState(false);
    const [isAbandonedHardware, setIsAbandonedHardware] = useState(false);
    const [deviceListCondition, setDeviceListCondition] = useState({ isAbandonedHardware: false, isMRIConditional: false });


    // listen to filter changes
    useEffect(() => {
        dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber, implantInstanceListFilter));
    }, [dispatch, implantInstanceListFilter, patientNHSNumber]);

    // handle page change
    const handlePageChange = (newPage) => {
        setimplantInstanceListFilter({ ...implantInstanceListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setimplantInstanceListFilter({ ...implantInstanceListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setimplantInstanceListFilter({ ...implantInstanceListFilter, filter: selectedFilter });
    };

    // listen to implantInstanceDet 
    useEffect(() => {
        if (implantInstanceDet) {
            // use spread operator to copy the implantInstanceDet object
            setUpdatedImplantInstance({ ...implantInstanceDet });
        }
    }, [implantInstanceDet]);

    // listen to Implant details
    useEffect(() => {
        if (implant) {
            setNewImplantInstance({
                ...newImplantInstance,
                modelName: implant.modelName,
                modelNumber: implant.modelNumber,
                barcode: implant.barcode,
                manufacturer: implant.manufacturer,
                modelCategory: implant.modelCategory,
                type: implant.type,
                eventDate: new Date().toISOString(),
            });
        } else {
            setNewImplantInstance({
                ...newImplantInstance,
                modelName: '',
                modelNumber: '',
                barcode: '',
                manufacturer: '',
                modelCategory: '',
                type: '',
                eventDate: new Date().toISOString(),
            });
        }
    }, [implant]);

    // listen to implantInstances
    useEffect(() => {
        if (implantInstances) {
            // if at least one implant is MRI conditional, then the patient is MRI conditional
            const isMRIConditional = implantInstances.some(implantInstance => implantInstance.mrConditional && implantInstance.status.includes("Implanted"));
            const isAbandonedHardware = implantInstances.some(implantInstance => implantInstance.abandonedHardware);
            const deviceList = implantInstances.map(implantInstance => implantInstance.barcode);
            setIsMRIConditional(isMRIConditional);
            setIsAbandonedHardware(isAbandonedHardware);
            setDeviceListCondition({ isAbandonedHardware: isAbandonedHardware, isMRIConditional: isMRIConditional, deviceList: deviceList });
        }
    }, [implantInstances]);



    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this implantInstance?')) {
            dispatch(deleteImplantInstance(id)).then(() => {
                dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber, {}));
            }
            );
        }
    };

    // handle edit
    const handleEditImplantInstance = (id) => {
        dispatch(getImplantInstanceDetails(id));
        setEditMode(true);
        setShowModal(true);

    };

    // handle create
    const handleCreateImplantInstance = () => {
        dispatch(createImplantInstance(newImplantInstance));
    };

    // handle update
    const handleUpdateImplantInstance = () => {
        dispatch(updateImplantInstance(updatedImplantInstance.id, updatedImplantInstance)).then(() => {
            setUpdatedImplantInstance(updatedImplantInstance);
            setEditMode(true);
            dispatch(getImplantInstanceDetails(updatedImplantInstance.id));
            dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber, {}));
        }
        );
    };
    // handle get implant by barcode
    const handleGetImplantByBarcode = (barcode) => {
        dispatch(getImplantDetailsByBarcode(barcode));
    };


    return (
        <React.Fragment>

{isMRIConditional && (
                <Callout
                    label="MRI Conditional"
                    header="MRI Conditional"
                    time={new Date().toLocaleTimeString()}
                    title="MRI Conditional"
                    message="At least one of the implanted components is MRI conditional."
                    infoMessage={
                        <>
                            <p>MRI conditional means that the device has been shown to pose no known hazards in a specified MRI environment with specified conditions of use. Field conditions that define the specified MRI environment include field strength, spatial gradient, dB/dt (time rate of change of the magnetic field), radio frequency (RF) fields, and specific absorption rate (SAR).</p>

                            <h4 style={{ marginTop: '20px', marginBottom: '10px' }}>Affected Devices:</h4>
                            <div style={{ marginLeft: '10px' }}>
                                {implantInstances
                                    .filter(device => device.mrConditional && device.status.includes("Implanted"))
                                    .map(device => (
                                        <div key={device.id} style={{ marginBottom: '10px', padding: '10px', backgroundColor: '#fff7f7', borderRadius: '4px' }}>
                                            <strong>{device.modelCategory}: </strong>{device.modelName}
                                            <br />
                                            <small>Serial: {device.serialNumber}</small>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }
                    mode="warning"
                />
            )}

            {isAbandonedHardware && (
                <Callout
                    label="Abandoned Hardware"
                    header="Abandoned Hardware"
                    time={new Date().toLocaleTimeString()}
                    title="Abandoned Hardware"
                    message="At least one of the implanted components was abandoned."
                    infoMessage={
                        <>
                            <p>Abandoned hardware refers to cardiac device components that have been left in place and are no longer connected to a functioning cardiac device system. This may affect MRI safety and future procedures.</p>

                            <h4 style={{ marginTop: '20px', marginBottom: '10px' }}>Abandoned Devices:</h4>
                            <div style={{ marginLeft: '10px' }}>
                                {implantInstances
                                    .filter(device => device.abandonedHardware)
                                    .map(device => (
                                        <div key={device.id} style={{ marginBottom: '10px', padding: '10px', backgroundColor: '#fff7f7', borderRadius: '4px' }}>
                                            <strong>{device.modelCategory}: </strong>{device.modelName}
                                            <br />
                                            <small>Serial: {device.serialNumber}</small>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }
                    mode="warning"
                />
            )}

            {loading ? (
                <AppSpinner />
            ) : error ? (
                <p>{error}</p>
            ) : (
                implantInstances && implantInstances.length > 0 ? (
                    implantInstances.map((implantInstance) => (
                        <Card clickable
                            key={implantInstance.barcode}
                            style={{
                                borderLeft: implantInstance.mrConditional ? '10px solid #c82333' : '4px solid #218838',
                                position: 'relative',
                                marginBottom: '1.5rem',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                                borderRadius: '8px',
                                padding: '1.5rem'
                            }}
                        >
                            <div style={{
                                position: 'absolute',
                                top: '1px',
                                right: '1px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '16px',
                                fontSize: '13px',
                                padding: '6px 12px',
                                backgroundColor: 'rgba(255,255,255,0.95)',
                                borderRadius: '20px',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                            }}>
                                {/* MRI Status */}
                                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                    <span
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            backgroundColor: implantInstance.mrConditional ? '#c82333' : '#218838',
                                            display: 'inline-block',
                                            border: '2px solid ' + (implantInstance.mrConditional ? '#c82333' : '#218838'),
                                            boxShadow: '0 0 4px rgba(0,0,0,0.2)'
                                        }}
                                        title={implantInstance.mrConditional ? "MRI Conditional" : "Not MRI Conditional"}
                                    />
                                    <span style={{
                                        fontWeight: '500',
                                        color: implantInstance.mrConditional ? '#c82333' : '#218838'
                                    }}>MRI</span>
                                </div>

                                {/* Hardware Status */}
                                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                    <span
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            backgroundColor: implantInstance.abandonedHardware ? '#dc3545' : '#28a745',
                                            display: 'inline-block',
                                            border: '2px solid ' + (implantInstance.abandonedHardware ? '#c82333' : '#218838'),
                                            boxShadow: '0 0 4px rgba(0,0,0,0.2)'
                                        }}
                                        title={implantInstance.abandonedHardware ? "Abandoned Hardware" : "Connected Hardware"}
                                    />
                                    <span style={{
                                        fontWeight: '500',
                                        color: implantInstance.abandonedHardware ? '#dc3545' : '#28a745'
                                    }}>{implantInstance.abandonedHardware ? "OFF" : "ON"}</span>
                                </div>
                            </div>

                            <Details>
                                <Details.Summary style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                                    <Tag style={{ marginRight: '1rem', padding: '4px 12px' }}>{implantInstance.modelCategory}</Tag>
                                    {implantInstance.modelName} - ({implantInstance.serialNumber})
                                </Details.Summary>
                                <Details.Text style={{ marginTop: '1rem' }}>
                                    {implantInstance.modelCategory === 'Generator' && (
                                        <InsetText style={{ marginBottom: '1.5rem', backgroundColor: '#f8f9fa' }}>
                                            <p style={{ marginBottom: '0.5rem' }}><strong>Implant Site: </strong>{implantInstance.generatorImplantSite}</p>
                                            <p style={{ margin: 0 }}><strong>Max System Capability: </strong>{implantInstance.maxSystemCapability}</p>
                                        </InsetText>
                                    )}
                                    {implantInstance.modelCategory === 'Lead' && (
                                        <InsetText style={{ marginBottom: '1.5rem', backgroundColor: '#f8f9fa' }}>
                                            <p style={{ marginBottom: '0.5rem' }}><strong>Lead Chamber: </strong>{implantInstance.leadChamber}</p>
                                            <p style={{ margin: 0 }}><strong>Pacing Site: </strong>{implantInstance.pacingSite}</p>
                                        </InsetText>
                                    )}
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', marginBottom: '1rem' }}>
                                        <p style={{ margin: 0 }}><strong>Manufacturer: </strong>{implantInstance.manufacturer}</p>
                                        <p style={{ margin: 0 }}><strong>Model Name: </strong>{implantInstance.modelName}</p>
                                        <p style={{ margin: 0 }}><strong>Model Number: </strong>{implantInstance.modelNumber}</p>
                                        <p style={{ margin: 0 }}><strong>Serial Number: </strong>{implantInstance.serialNumber}</p>
                                        <p style={{ margin: 0 }}><strong>Date Implanted: </strong>{formatISODate(implantInstance.eventDate, true)}</p>
                                        <p style={{ margin: 0 }}><strong>Status: </strong><Tag>{implantInstance.status}</Tag></p>
                                    </div>
                                    <div style={{ display: 'flex', gap: '2rem', marginTop: '1rem' }}>
                                        <p style={{ margin: 0 }}>
                                            <strong>Abandoned Hardware: </strong>
                                            <Tag color={implantInstance.abandonedHardware ? 'red' : 'green'}>
                                                {implantInstance.abandonedHardware ? 'Yes' : 'No'}
                                            </Tag>
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            <strong>MR Conditional: </strong>
                                            <Tag color={implantInstance.mrConditional ? 'red' : 'green'}>
                                                {implantInstance.mrConditional ? 'Yes' : 'No'}
                                            </Tag>
                                        </p>
                                    </div>
                                </Details.Text>
                            </Details>

                            
                        </Card>
                    ))
                ) : (
                    <p style={{ textAlign: 'center', padding: '2rem', color: '#666' }}>No Implants Found</p>
                )
            )}



        </React.Fragment>
    );
}

export default ImplantInstanceListReadOnly;