export const IMPLANT_LIST_REQUEST = 'IMPLANT_LIST_REQUEST'
export const IMPLANT_LIST_SUCCESS = 'IMPLANT_LIST_SUCCESS'
export const IMPLANT_LIST_FAIL = 'IMPLANT_LIST_FAIL'


export const IMPLANT_DETAILS_REQUEST = 'IMPLANT_DETAILS_REQUEST'
export const IMPLANT_DETAILS_SUCCESS = 'IMPLANT_DETAILS_SUCCESS'
export const IMPLANT_DETAILS_FAIL = 'IMPLANT_DETAILS_FAIL'


export const IMPLANT_DELETE_REQUEST = 'IMPLANT_DELETE_REQUEST'
export const IMPLANT_DELETE_SUCCESS = 'IMPLANT_DELETE_SUCCESS'
export const IMPLANT_DELETE_FAIL = 'IMPLANT_DELETE_FAIL'

export const IMPLANT_CREATE_REQUEST = 'IMPLANT_CREATE_REQUEST'
export const IMPLANT_CREATE_SUCCESS = 'IMPLANT_CREATE_SUCCESS'
export const IMPLANT_CREATE_FAIL = 'IMPLANT_CREATE_FAIL'
export const IMPLANT_CREATE_RESET = 'IMPLANT_CREATE_RESET'


export const IMPLANT_UPDATE_REQUEST = 'IMPLANT_UPDATE_REQUEST'
export const IMPLANT_UPDATE_SUCCESS = 'IMPLANT_UPDATE_SUCCESS'
export const IMPLANT_UPDATE_FAIL = 'IMPLANT_UPDATE_FAIL'
export const IMPLANT_UPDATE_RESET = 'IMPLANT_UPDATE_RESET'

export const IMPLANT_REQUEST = 'IMPLANT_REQUEST'
export const IMPLANT_SUCCESS = 'IMPLANT_SUCCESS'
export const IMPLANT_FAIL = 'IMPLANT_FAIL'
export const IMPLANT_RESET = 'IMPLANT_RESET'

export const IMPLANT_COUNT_REQUEST = 'IMPLANT_COUNT_REQUEST'
export const IMPLANT_COUNT_SUCCESS = 'IMPLANT_COUNT_SUCCESS'
export const IMPLANT_COUNT_FAIL = 'IMPLANT_COUNT_FAIL'

export const IMPLANT_DTO_REQUEST = 'IMPLANT_DTO_REQUEST'
export const IMPLANT_DTO_SUCCESS = 'IMPLANT_DTO_SUCCESS'
export const IMPLANT_DTO_FAIL = 'IMPLANT_DTO_FAIL'
export const IMPLANT_DTO_RESET = 'IMPLANT_DTO_RESET'

export const IMPLANT_LIST_BYIDS_REQUEST = 'IMPLANT_LIST_BYIDS_REQUEST'
export const IMPLANT_LIST_BYIDS_SUCCESS = 'IMPLANT_LIST_BYIDS_SUCCESS'
export const IMPLANT_LIST_BYIDS_FAIL = 'IMPLANT_LIST_BYIDS_FAIL'
export const IMPLANT_LIST_BYIDS_RESET = 'IMPLANT_LIST_BYIDS_RESET'

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'