import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { register } from "../actions/userActions";
import { listOrganisations } from "../actions/organisationActions";
import { Select } from "nhsuk-react-components";
import NHSPatientLogin from "../components/nhsCis2Login/NHSPatientLogin.component";
import NHSMailLogin from "../components/nhsCis2Login/NHSMailLogin.component";

function RegisterScreen({ history, location }) {
    useEffect(() => {
        document.title = "Register";
    }, []);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [organisationId, setOrganisationId] = useState("");
    
    const dispatch = useDispatch();

    const redirect = location.search ? location.search.split("=")[1] : "/";

    const userRegister = useSelector((state) => state.userRegister);
    const { error, loading, success } = userRegister;

    const organisationList = useSelector((state) => state.organisationList);
    const { organisations, loading: loadingOrganisations, error: errorOrganisations } = organisationList;

    useEffect(() => {
        dispatch(listOrganisations());
    }, [dispatch]);

    useEffect(() => {
        if (organisations && organisations.length > 0) {
            setOrganisationId(organisations[0].id);
        }
    }, [organisations]);

    useEffect(() => {
        if (success) {
            window.location.href = "/login";
        }
    }, [success]);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            if (password.length < 6) {
                setMessage("Password must be at least 6 characters long.");
                return;
            }
            if (organisationId === "") {
                setMessage("Please select an organisation.");
                return;
            }
            if (email === "") {
                setMessage("Please enter your email address.");
                return;
            }
            dispatch(register(email, password, organisationId));
        } catch (error) {
            setMessage(error.message);
        }
    };

    const handleLoginSuccess = (userInfo) => {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        window.location.href = "/login";
    };

    return (
        <React.Fragment>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <h1>User Registration</h1>
                            {error && (
                                <div className="nhsuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1">
                                    <h3 className="nhsuk-error-summary__title" id="error-summary-title">
                                        There is a problem
                                    </h3>
                                    <div className="nhsuk-error-summary__body">
                                        <ul className="nhsuk-list nhsuk-error-summary__list" role="list">
                                            <li>{error}</li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {message && (
                                <div className="nhsuk-panel nhsuk-panel--confirmation nhsuk-u-margin-top-0 nhsuk-u-margin-bottom-4">
                                    <h5 className="nhsuk-panel__title" style={{ color: "green" }}>
                                        {message}
                                    </h5>
                                </div>
                            )}
                            <h2>Enter your user details</h2>

                            <form onSubmit={submitHandler} className="nhsuk-form-group">
                                <Select
                                    label="Organisation"
                                    disabled={loadingOrganisations}
                                    items={organisations}
                                    onChange={(e) => setOrganisationId(e.target.value)}
                                    value={organisationId}
                                    required
                                >
                                    {loadingOrganisations ? (
                                        "Loading organisations..."
                                    ) : errorOrganisations ? (
                                        <h3>{errorOrganisations}</h3>
                                    ) : (
                                        organisations && organisations.map((organisation) => (
                                            <Select.Option key={organisation.id} value={organisation.id}>
                                                {organisation.name}
                                            </Select.Option>
                                        ))
                                    )}
                                </Select>

                                <label className="nhsuk-label" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    className="nhsuk-input"
                                    id="email"
                                    name="email"
                                    type="text"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />
                                <div className="nhsuk-u-padding-bottom-5"></div>

                                <label className="nhsuk-label" htmlFor="password">
                                    Password
                                </label>
                                <input
                                    className="nhsuk-input"
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                />

                                <div className="nhsuk-u-padding-bottom-5"></div>

                                <div className="nhsuk-grid-row">
                                    <div className="nhsuk-width-container">
                                        <button className="nhsuk-button" data-module="nhsuk-button" type="submit" style={{ width: '100%' }} disabled={loading}>
                                            {loading ? 'Sending Registration Request ...' : 'Register'}
                                        </button>
                                                                                
                                        <div className="nhsuk-u-padding-bottom-3"></div>
                                        
                                        <NHSMailLogin onLoginSuccess={handleLoginSuccess} />
                                    </div>
                                </div>
                            </form>

                            <p>Already have an account? <Link
                                to={redirect ? `/login?redirect=${redirect}` : "/login"}
                                className="text-primary fw-semibold"
                            >
                                Sign in now
                            </Link></p>
                        </div>
                    </div>
                </main>
            </div>
        </React.Fragment>
    );
}

export default RegisterScreen;