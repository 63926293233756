import React, { useEffect, useState, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Checkboxes, SummaryList, Button } from 'nhsuk-react-components';
import { Skeleton } from 'antd';
import { FaShieldAlt, FaExclamationTriangle, FaClock } from 'react-icons/fa';
import { updatePatientConsentIds } from '../../../actions/patientActions';
import { listConsents } from '../../../actions/consentActions';
import Modal from '../../../components/modal/Modal.component';
import Callout from '../../../components/callout/Callout.component';
import { convertNewlinesToBreaks, formatISODate } from '../../../utils/functions';

const ConsentModal = memo(({ show, content, handleClose, onConfirm, isRevoking = false }) => {
    if (!content) return null;

    const formatValue = (key, value) => {
        if (key.toLowerCase().includes('date') && value) {
            return formatISODate(value, true);
        }
        return typeof value === 'string' ? convertNewlinesToBreaks(value) : (value?.toString() || "-");
    };

    return (
        <Modal show={show} handleClose={handleClose} blur={true} theme="light">
            <div className="nhsuk-u-padding-bottom-4">
                {isRevoking ? (
                    <>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                            marginBottom: '24px',
                            color: '#d5281b'
                        }}>
                            <FaExclamationTriangle size={24} />
                            <h2 className="nhsuk-heading-l" style={{ margin: 0, color: '#d5281b' }}>
                                Revoke Consent
                            </h2>
                        </div>
                        <p className="nhsuk-body">
                            Are you sure you want to revoke your consent for "{content.consentType}"?
                            This action will restrict access to your medical information as specified in the consent scope.
                        </p>
                    </>
                ) : (
                    <>
                        <h2 className="nhsuk-heading-l">{content.consentType}</h2>
                        <SummaryList>
                            {Object.entries(content)
                                .filter(([key]) => !["id", "patientConsents"].includes(key))
                                .map(([key, value]) => (
                                    <SummaryList.Row key={key}>
                                        <SummaryList.Key>
                                            {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                        </SummaryList.Key>
                                        <SummaryList.Value>
                                            {formatValue(key, value)}
                                        </SummaryList.Value>
                                    </SummaryList.Row>
                                ))}
                        </SummaryList>
                    </>
                )}

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '12px',
                    marginTop: '24px'
                }}>
                    <Button secondary onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={onConfirm}
                        style={isRevoking ? { backgroundColor: '#d5281b', color: 'white' } : {}}
                    >
                        {isRevoking ? 'Revoke Consent' : 'Give Consent'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
});

const ConsentManagement = memo(({ patient, title = "Manage your consents" }) => {
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedConsent, setSelectedConsent] = useState(null);
    const [isRevoking, setIsRevoking] = useState(false);
    const [pendingConsent, setPendingConsent] = useState(null);
    const [showCallout, setShowCallout] = useState(false);
    const [calloutMessage, setCalloutMessage] = useState({ type: '', message: '' });
    const [showConsentList, setShowConsentList] = useState(false);
    const [showRevokeConfirmation, setShowRevokeConfirmation] = useState(false);

    const consentList = useSelector((state) => state.consentList);
    const { consents, loading } = consentList;

    const patientUpdateConsentIds = useSelector((state) => state.patientUpdateConsentIds);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = patientUpdateConsentIds;

    useEffect(() => {
        dispatch(listConsents());
    }, [dispatch]);

    useEffect(() => {
        if (successUpdate && pendingConsent) {
            setCalloutMessage({
                type: 'success',
                message: pendingConsent.checked ?
                    `Successfully granted consent for ${pendingConsent.consent.consentType}` :
                    `Successfully revoked consent for ${pendingConsent.consent.consentType}`
            });
            setShowCallout(true);
        } else if (errorUpdate) {
            setCalloutMessage({
                type: 'error',
                message: 'Failed to update consent. Please try again.'
            });
            setShowCallout(true);
        }
    }, [successUpdate, errorUpdate, pendingConsent]);

    useEffect(() => {
        if (showCallout) {
            const timer = setTimeout(() => {
                setShowCallout(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showCallout]);

    const handlePrimaryConsentChange = useCallback((checked) => {
        if (!checked) {
            setShowRevokeConfirmation(true);
        } else {
            setShowConsentList(true);
        }
    }, []);

    const handleRevokeConfirmation = useCallback(() => {
        dispatch(updatePatientConsentIds(patient.id, {
            ...patient,
            consentIds: []
        }));

        setCalloutMessage({
            type: 'success',
            message: 'All consents have been revoked'
        });
        setShowCallout(true);
        setShowRevokeConfirmation(false);
        setShowConsentList(false);
    }, [patient, dispatch]);

    useEffect(() => {
        if (patient.isConsentingPatient) {
            setShowConsentList(true);
        }
    }, [patient.isConsentingPatient]);

    const handleConsentChange = useCallback((consent, checked) => {
        if (checked) {
            setSelectedConsent(consent);
            setIsRevoking(false);
            setPendingConsent({ consent, checked });
            setModalVisible(true);
        } else {
            setSelectedConsent(consent);
            setIsRevoking(true);
            setPendingConsent({ consent, checked });
            setModalVisible(true);
        }
    }, []);

    const handleConfirmConsent = useCallback(() => {
        if (!pendingConsent) return;

        const { consent, checked } = pendingConsent;
        let updatedConsents;

        if (consent.consentType === "Full Access") {
            updatedConsents = checked ? consents.map(c => c.id) : [];
        } else {
            if (checked) {
                updatedConsents = [...(patient.consentIds || []), consent.id];
            } else {
                updatedConsents = (patient.consentIds || []).filter(id => id !== consent.id);
                const fullAccessId = consents.find(c => c.consentType === "Full Access")?.id;
                if (fullAccessId && patient.consentIds?.includes(fullAccessId)) {
                    updatedConsents = updatedConsents.filter(id => id !== fullAccessId);
                }
            }
        }

        dispatch(updatePatientConsentIds(patient.id, {
            ...patient,
            consentIds: updatedConsents
        }));

        setModalVisible(false);
        setPendingConsent(null);
    }, [patient, consents, pendingConsent, dispatch]);

    return (
        <>
            <Card className="nhsuk-u-margin-bottom-4" style={{
                background: "#f0f7ff",
                border: "1px solid #005eb8",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0,94,184,0.1)"
            }}>
                <Card.Content>
                    <div style={{
                        borderBottom: "1px solid #005eb8",
                        marginBottom: "24px",
                        paddingBottom: "16px"
                    }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px"
                        }}>
                            <div style={{
                                background: "#005eb8",
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                boxShadow: "0 2px 4px rgba(0,94,184,0.2)"
                            }}>
                                <FaShieldAlt size={20} style={{ color: "white" }} />
                            </div>
                            <div>
                                <h2 className="nhsuk-heading-m" style={{
                                    margin: 0,
                                    color: "#212b32",
                                    fontSize: "24px",
                                    fontWeight: 600,
                                    lineHeight: 1.2
                                }}>
                                    {title}
                                </h2>
                                <p style={{
                                    margin: "4px 0 0 0",
                                    color: "#4c6272",
                                    fontSize: "14px"
                                }}>
                                    Control access to patient medical information
                                </p>

                            </div>

                        </div>
                        {patient?.dateConsented && (
                            <div style={{
                                marginLeft: '50px',
                                marginTop: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                color: '#4c6272',
                                fontSize: '14px'
                            }}>
                                <FaClock size={14} />
                                <span>Consent updated on {formatISODate(patient.dateConsented, true)}</span>
                            </div>
                        )}
                    </div>

                    <Card.Description>
                        <div className="nhsuk-u-padding-bottom-3">
                            {showCallout && (
                                <div className="nhsuk-u-margin-bottom-4">
                                    <Callout
                                        label={calloutMessage.type === 'success' ? 'Success' : 'Error'}
                                        header={calloutMessage.type === 'success' ? 'Success!' : 'Error'}
                                        autoDismiss={true}
                                        dismissTime={5000}
                                        time={formatISODate(new Date(), true)}
                                        message={calloutMessage.message}
                                        mode={calloutMessage.type}
                                    />
                                </div>
                            )}

                            <div className="nhsuk-u-margin-bottom-4">
                                <Checkboxes.Box
                                    key={`primary-consent-${patient.id}`}
                                    checked={patient.isConsentingPatient || showConsentList}
                                    onChange={(e) => handlePrimaryConsentChange(e.target.checked)}
                                >
                                    I consent to Barts Health NHS Trust staff accessing my implanted device information.
                                </Checkboxes.Box>
                            </div>

                            {showConsentList && (
                                <div className="nhsuk-u-margin-left-4">
                                    {(loading || loadingUpdate) ? (
                                        [...Array(3)].map((_, i) => (
                                            <Skeleton
                                                key={i}
                                                active
                                                paragraph={{ rows: 1 }}
                                                style={{ marginBottom: "12px" }}
                                            />
                                        ))
                                    ) : !consents?.length ? (
                                        <div className="nhsuk-inset-text">
                                            <p>No additional consents available</p>
                                        </div>
                                    ) : (
                                        <Checkboxes hint="I consent to the following:">
                                            {consents
                                                .sort((a, b) => a.consentType === "Full Access" ? -1 : b.consentType === "Full Access" ? 1 : 0)
                                                .map(consent => (
                                                    <Checkboxes.Box
                                                        key={consent.id}
                                                        value={consent.id}
                                                        checked={patient.consentIds?.includes(consent.id)}
                                                        onChange={(e) => handleConsentChange(consent, e.target.checked)}
                                                    >
                                                        <div>
                                                            <div>{consent.consentType}</div>
                                                            <div style={{
                                                                fontSize: "14px",
                                                                color: "#4c6272",
                                                                marginTop: "4px"
                                                            }}>
                                                                Scope: {consent.consentScope}
                                                            </div>
                                                            {consent.validFromDate && (
                                                                <div style={{
                                                                    fontSize: "14px",
                                                                    color: "#4c6272",
                                                                    marginTop: "4px"
                                                                }}>
                                                                    Valid from: {formatISODate(consent.validFromDate, true)}
                                                                </div>
                                                            )}
                                                            {consent.validToDate && (
                                                                <div style={{
                                                                    fontSize: "14px",
                                                                    color: "#4c6272",
                                                                    marginTop: "4px"
                                                                }}>
                                                                    Valid until: {formatISODate(consent.validToDate, true)}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Checkboxes.Box>
                                                ))
                                            }
                                        </Checkboxes>
                                    )}
                                </div>
                            )}
                        </div>
                    </Card.Description>
                </Card.Content>
            </Card>
            <ConsentModal
                show={modalVisible}
                content={selectedConsent}
                handleClose={() => {
                    setModalVisible(false);
                    setPendingConsent(null);
                    if (pendingConsent) {
                        const checkbox = document.querySelector(`input[value="${pendingConsent.consent.id}"]`);
                        if (checkbox) {
                            checkbox.checked = !pendingConsent.checked;
                        }
                    }
                }}
                onConfirm={handleConfirmConsent}
                isRevoking={isRevoking}
            />

            <Modal
                show={showRevokeConfirmation}
                handleClose={() => setShowRevokeConfirmation(false)}
                blur={true}
                theme="light"
            >
                <div className="nhsuk-u-padding-bottom-4">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        marginBottom: '24px',
                        color: '#d5281b'
                    }}>
                        <FaExclamationTriangle size={24} />
                        <h2 className="nhsuk-heading-l" style={{ margin: 0, color: '#d5281b' }}>
                            Revoke Consent
                        </h2>
                    </div>
                    <p className="nhsuk-body">
                        Are you sure you want to revoke your consent for Barts Health NHS Trust staff to access your implanted device information?
                        This action will restrict access to your medical information.
                    </p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '12px',
                        marginTop: '24px'
                    }}>
                        <Button secondary onClick={() => setShowRevokeConfirmation(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleRevokeConfirmation}
                            style={{ backgroundColor: '#d5281b', color: 'white' }}
                        >
                            Revoke Consent
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
   );
});

export default ConsentManagement;