import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, InsetText, Select } from "nhsuk-react-components";
import { listUsers, deleteUser } from "../../actions/userActions";
import Pagination from "../../components/pagination/Pagination.component";
import AppSpinner from "../../components/spinner/AppSpinner.component";

export default function UserManagementScreen({ history }) {

  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users, currentPage, totalPages } = userList;

  // set initial filter to empty string, set default value 
  // {{ _.docker_api_url }}/api/v1/user/all?page=1&limit=2&filter=Email&query=gmail
  const [userListFilter, setuserListFilter] = useState({ query: "", page: 1, filter: "UserName" });

  // listen to filter changes
  useEffect(() => {
    dispatch(listUsers(userListFilter));
  }, [dispatch, userListFilter]);

  // handle page change
  const handlePageChange = (newPage) => {
    setuserListFilter({ ...userListFilter, page: newPage });
  };

  // handle search text field change
  const handleSearchChange = (e) => {
    setuserListFilter({ ...userListFilter, query: e.target.value });
  };

  // handle filter value change
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setuserListFilter({ ...userListFilter, filter: selectedFilter });
  };

  // Handle Delete
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteUser(id));
      setuserListFilter({ query: "", page: 1, filter: "UserName" });
    }
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <div className="welcome-box">

                <div className="welcome-title">
                  <h2>User Management</h2>
                  <BackLink
                    asElement="a"
                    href="#"
                    onClick={() => history.goBack()}
                  >
                    Return back
                  </BackLink>
                  <Button
                    onClick={() => history.push(`/users/create`)}
                  >
                    + Create User

                  </Button>


                  <Card>
                    <Card.Content>
                      <Card.Heading>

                        <Input
                          id="input-example"
                          label="Search"
                          name="search"
                          width={20}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearchChange(e);
                            }
                          }
                          }
                        />
                        <Select
                          name="user-filter"
                          hint="Filter by:"
                          value={userListFilter.filter}
                          onChange={handleFilterChange}
                        >
                          <option value="UserName">User Name</option>
                          <option value="Email">Email</option>
                        </Select>
                      </Card.Heading>


                      <Table responsive={true}>
                        <Table.Head>
                          <Table.Row>
                            <Table.Cell>Username</Table.Cell>
                            <Table.Cell>E-mail</Table.Cell>
                            <Table.Cell>Confirmed</Table.Cell>
                            <Table.Cell>Role(s)</Table.Cell>
                            <Table.Cell> &nbsp;</Table.Cell>
                            <Table.Cell> &nbsp;</Table.Cell>

                          </Table.Row>
                        </Table.Head>
                        <Table.Body>
                          {loading ? (
                            <AppSpinner />
                          ) : error ? (
                            <div>{error}</div>
                          ) : (
                            Array.isArray(users) && users.length > 0 ? (
                              users?.map((user) => (
                                <Table.Row key={user.id}>
                                  <Table.Cell>{user.userName}</Table.Cell>
                                  <Table.Cell>{user.email}</Table.Cell>
                                  <Table.Cell>
                                    {user.emailConfirmed === true ? (
                                      <Tag key="yes" color="green">Yes</Tag>
                                    ) : (
                                      <Tag key="no" color="red">No</Tag>
                                    )}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {user.roles.map((role) => (
                                      <Tag key={role}>{role}</Tag>
                                    ))}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Button onClick={() => history.push(`/profile/${user.id}`)}>
                                      View/Amend
                                    </Button>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Button secondary onClick={() => handleDelete(user.id)}>
                                      Delete
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              ))
                            ) : (
                              <div>No users found</div>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </Card.Content>
                  </Card>
                  {loading ? (
                    <div></div>
                  ) : error ? (
                    <div>{error}</div>
                  ) : (

                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                    />
                  )
                  }



                </div>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
}

