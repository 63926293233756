import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ErrorMessage, Button, ChevronLeftIcon } from "nhsuk-react-components";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function NotFoundScreen(props, navigation) {
    useEffect(() => {
        document.title = "Page Not Found"; 
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="nhsuk-width-container">

                    <main className="nhsuk-main-wrapper" id="maincontent" role="main">

                        <div className="nhsuk-grid-row">
                            <div className="nhsuk-grid-column-two-thirds">
                                <h1 className="nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-5">Page Not Found</h1>
                                <ErrorMessage>
                                    Sorry the page you are looking for cannot be found
                                </ErrorMessage>
                                <Link to="/">
                                    <Button>
                                        Return to Dashboard
                                    </Button>
                                </Link>

                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </React.Fragment>
    );
}