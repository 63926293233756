import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary } from 'nhsuk-react-components';
import { listImplantCentres, createImplantCentre, updateImplantCentre, deleteImplantCentre, getImplantCentreDetails } from '../../actions/implantCentreActions';
// constants 
import { IMPLANT_CENTRE_CREATE_RESET, IMPLANT_CENTRE_UPDATE_RESET } from '../../constants/implantCentreConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';

// debouncer 
import { debounce } from 'lodash';

export default function ImplantCentreCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    // reset the state of the implantCentreCreate and implantCentreUpdate
    useEffect(() => {
        dispatch({ type: IMPLANT_CENTRE_CREATE_RESET });
        dispatch({ type: IMPLANT_CENTRE_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/implantCentre/all?page=1&limit=2&filter=Email&query=gmail
    const [implantCentreListFilter, setimplantCentreListFilter] = useState({ query: '', page: 1, filter: 'Name' });

    // get implantCentre list from redux store
    const implantCentreList = useSelector((state) => state.implantCentreList);
    const { loading, error, implantCentres, currentPage, totalPages } = implantCentreList;

    // get implantCentre create from redux store
    const implantCentreCreate = useSelector((state) => state.implantCentreCreate);
    const { success: successCreate, error: implantCentreCreateErrors } = implantCentreCreate;

    // get implantCentre update from redux store
    const implantCentreUpdate = useSelector((state) => state.implantCentreUpdate);
    const { success: successUpdate, loading: implantCentreUpdateProcessing } = implantCentreUpdate;

    // get implantCentre delete from redux store
    const implantCentreDelete = useSelector((state) => state.implantCentreDelete);
    const { success: successDelete } = implantCentreDelete;

    // get implantCentre details from redux store
    const implantCentreDetails = useSelector((state) => state.implantCentreDetails);
    const { implantCentre: implantCentreDet, loading: implantCentreDetailsLoading } = implantCentreDetails;

    // newImplantCentre state
    const [newImplantCentre, setNewImplantCentre] = useState({});
    const [updatedImplantCentre, setUpdatedImplantCentre] = useState({});
    const [editMode, setEditMode] = useState(false);


    // listen to filter changes
    useEffect(() => {
        dispatch(listImplantCentres(implantCentreListFilter));
    }, [dispatch, implantCentreListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setimplantCentreListFilter({ ...implantCentreListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setimplantCentreListFilter({ ...implantCentreListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setimplantCentreListFilter({ ...implantCentreListFilter, filter: selectedFilter });
    };

    // get all implantCentres
    useEffect(() => {
        dispatch(listImplantCentres({}));
    }, [dispatch]);

    // listen to implantCentreDet 
    useEffect(() => {
        if (implantCentreDet) {
            // use spread operator to copy the implantCentreDet object
            setUpdatedImplantCentre({ ...implantCentreDet });
        }
    }, [implantCentreDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newImplantCentre state
            setNewImplantCentre({
                name: '',
                streetAddress: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                email: '',
                phoneNumber: '',
            });
        }
    }, [editMode]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this implantCentre?')) {
            dispatch(deleteImplantCentre(id)).then(() => {
                dispatch(listImplantCentres({}));
            }
            );
        }
    };

    // handle edit
    const handleEditImplantCentre = (id) => {
        
        dispatch(getImplantCentreDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateImplantCentre = () => {
        dispatch(createImplantCentre(newImplantCentre)).then(() => {
            setUpdatedImplantCentre(newImplantCentre);
            setEditMode(false);
            dispatch(listImplantCentres({}));
        }
        );
    };

    // handle update
    const handleUpdateImplantCentre = () => {
        dispatch(updateImplantCentre(updatedImplantCentre.id, updatedImplantCentre)).then(() => {

            setUpdatedImplantCentre(updatedImplantCentre);
            setEditMode(true);
            dispatch(getImplantCentreDetails(updatedImplantCentre.id));
        }
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>ImplantCentre Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the implantCentres. You can create, update and delete implantCentres in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">

                            {implantCentreCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {implantCentreCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {implantCentreCreateErrors.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null

                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-implantCentres"
                                        label="Search implantCentres"
                                        name="search-implantCentres"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {implantCentres && implantCentres.length > 0 ? (
                                            implantCentres.map((implantCentre) => (
                                                <Details key={implantCentre._id}>
                                                    <Details.Summary>
                                                        {implantCentre.name}
                                                    </Details.Summary>
                                                    <Details.Text>
                                                        <p>
                                                            <strong>Address:</strong> {implantCentre.streetAddress}
                                                        </p>
                                                        <p>
                                                            <strong>City:</strong> {implantCentre.city}
                                                        </p>
                                                        <p>
                                                            <strong>State:</strong> {implantCentre.state}
                                                        </p>
                                                        <p>
                                                            <strong>Postal Code:</strong> {implantCentre.postalCode}
                                                        </p>
                                                        <p>
                                                            <strong>Country:</strong> {implantCentre.country}
                                                        </p>
                                                        <p>
                                                            <strong>Email:</strong> {implantCentre.email}
                                                        </p>
                                                        <p>
                                                            <strong>Phone Number:</strong> {implantCentre.phoneNumber}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={implantCentreDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditImplantCentre(implantCentre.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(implantCentre.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit ImplantCentre
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an implantCentre. If you want to create a new implantCentre, please click the "Create ImplantCentre" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create ImplantCentre
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name"
                                            label="Name"
                                            name="name"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantCentre({ ...updatedImplantCentre, name: e.target.value })}
                                            value={updatedImplantCentre.name ? updatedImplantCentre.name : ''}
                                            required
                                        />
                                        <Input
                                            id="streetAddress"
                                            label="Street Address"
                                            name="streetAddress"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantCentre({ ...updatedImplantCentre, streetAddress: e.target.value })}
                                            value={updatedImplantCentre.streetAddress ? updatedImplantCentre.streetAddress : ''}
                                            required
                                        />
                                        <Input
                                            id="city"
                                            label="City"
                                            name="city"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantCentre({ ...updatedImplantCentre, city: e.target.value })}
                                            value={updatedImplantCentre.city ? updatedImplantCentre.city : ''}
                                            required
                                        />
                                        <Input
                                            id="state"
                                            label="State"
                                            name="state"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantCentre({ ...updatedImplantCentre, state: e.target.value })}
                                            value={updatedImplantCentre.state ? updatedImplantCentre.state : ''}
                                            required
                                        />
                                        <Input
                                            id="postalCode"
                                            label="Postal Code"
                                            name="postalCode"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantCentre({ ...updatedImplantCentre, postalCode: e.target.value })}
                                            value={updatedImplantCentre.postalCode ? updatedImplantCentre.postalCode : ''}
                                            required
                                        />
                                        <Input
                                            id="country"
                                            label="Country"
                                            name="country"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantCentre({ ...updatedImplantCentre, country: e.target.value })}
                                            value={updatedImplantCentre.country ? updatedImplantCentre.country : ''}
                                            required
                                        />
                                        <Input
                                            id="email"
                                            label="Email"
                                            name="email"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantCentre({ ...updatedImplantCentre, email: e.target.value })}
                                            value={updatedImplantCentre.email ? updatedImplantCentre.email : ''}
                                            required
                                        />
                                        <Input
                                            id="phoneNumber"
                                            label="Phone Number"
                                            name="phoneNumber"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantCentre({ ...updatedImplantCentre, phoneNumber: e.target.value })}
                                            value={updatedImplantCentre.phoneNumber ? updatedImplantCentre.phoneNumber : ''}
                                            required
                                        />

                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The implantCentre was created successfully." : "The implantCentre was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={implantCentreUpdateProcessing}
                                        onClick={handleUpdateImplantCentre}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new ImplantCentre
                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name-create"
                                            label="Name"
                                            name="name-create"
                                            type="text"
                                            onChange={(e) => setNewImplantCentre({ ...newImplantCentre, name: e.target.value })}
                                            value={newImplantCentre?.name}
                                            required
                                        />
                                        <Input
                                            id="streetAddress-create"
                                            label="Street Address"
                                            name="streetAddress-create"
                                            type="text"
                                            onChange={(e) => setNewImplantCentre({ ...newImplantCentre, streetAddress: e.target.value })}
                                            value={newImplantCentre?.streetAddress}
                                            required
                                        />
                                        <Input
                                            id="city-create"
                                            label="City"
                                            name="city-create"
                                            type="text"
                                            onChange={(e) => setNewImplantCentre({ ...newImplantCentre, city: e.target.value })}
                                            value={newImplantCentre?.city}
                                            required
                                        />
                                        <Input
                                            id="state-create"
                                            label="State"
                                            name="state-create"
                                            type="text"
                                            onChange={(e) => setNewImplantCentre({ ...newImplantCentre, state: e.target.value })}
                                            value={newImplantCentre?.state}
                                            required
                                        />
                                        <Input
                                            id="postalCode-create"
                                            label="Postal Code"
                                            name="postalCode-create"
                                            type="text"
                                            onChange={(e) => setNewImplantCentre({ ...newImplantCentre, postalCode: e.target.value })}
                                            value={newImplantCentre?.postalCode}
                                            required
                                        />
                                        <Input
                                            id="country-create"
                                            label="Country"
                                            name="country-create"
                                            type="text"
                                            onChange={(e) => setNewImplantCentre({ ...newImplantCentre, country: e.target.value })}
                                            value={newImplantCentre?.country}
                                            required
                                        />
                                        <Input
                                            id="email-create"
                                            label="Email"
                                            name="email-create"
                                            type="text"
                                            onChange={(e) => setNewImplantCentre({ ...newImplantCentre, email: e.target.value })}
                                            value={newImplantCentre?.email}
                                            required
                                        />
                                        <Input
                                            id="phoneNumber-create"
                                            label="Phone Number"
                                            name="phoneNumber-create"
                                            type="text"
                                            onChange={(e) => setNewImplantCentre({ ...newImplantCentre, phoneNumber: e.target.value })}
                                            value={newImplantCentre?.phoneNumber}
                                            required
                                        />

                                    </Card.Description>

                                    <Button
                                        disabled={implantCentreUpdateProcessing}
                                        onClick={handleCreateImplantCentre}
                                    >
                                        Create ImplantCentre
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






