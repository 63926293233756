import axios from 'axios';

import {
    SYMPTOM_LIST_REQUEST,
    SYMPTOM_LIST_SUCCESS,
    SYMPTOM_LIST_FAIL,

    SYMPTOM_DETAILS_REQUEST,
    SYMPTOM_DETAILS_SUCCESS,
    SYMPTOM_DETAILS_FAIL,

    SYMPTOM_DELETE_REQUEST,
    SYMPTOM_DELETE_SUCCESS,
    SYMPTOM_DELETE_FAIL,

    SYMPTOM_CREATE_REQUEST,
    SYMPTOM_CREATE_SUCCESS,
    SYMPTOM_CREATE_FAIL,

    SYMPTOM_UPDATE_REQUEST,
    SYMPTOM_UPDATE_SUCCESS,
    SYMPTOM_UPDATE_FAIL,

    SYMPTOM_REQUEST,
    SYMPTOM_FAIL,
    SYMPTOM_RESET,
    SYMPTOM_SUCCESS,

    SYMPTOM_COUNT_SUCCESS,
    SYMPTOM_COUNT_REQUEST,
    SYMPTOM_COUNT_FAIL,

    SYMPTOM_LIST_BYIDS_REQUEST,
    SYMPTOM_LIST_BYIDS_SUCCESS,
    SYMPTOM_LIST_BYIDS_FAIL,
    SYMPTOM_LIST_BYIDS_RESET,


    SET_CURRENT_PAGE,
    

} from "../constants/symptomConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listSymptoms = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: SYMPTOM_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
            params
        }
        const { data } = await axios.get('/api/v1/symptom/all', config);

        dispatch({
            type: SYMPTOM_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: SYMPTOM_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later symptoms",
        });
    }
}

// list symptoms by ids
export const listSymptomsByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: SYMPTOM_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }
        const { data } = await axios.post('/api/v1/symptom/ids/all', ids, config);

        dispatch({
            type: SYMPTOM_LIST_BYIDS_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: SYMPTOM_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later symptoms",
        });
    }
}



// count symptoms
export const countSymptoms = () => async (dispatch, getState) => {
    try {
      dispatch({ type: SYMPTOM_COUNT_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        },
      }
      const { data } = await axios.get('/api/v1/symptom/count', config);
  
      dispatch({
        type: SYMPTOM_COUNT_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: SYMPTOM_COUNT_FAIL,
        payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : "Failed to fetch symptom counts",
      });
    }
  }
  


export const getSymptomDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SYMPTOM_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }
        const { data } = await axios.get(`/api/v1/symptom/${_id}`, config);

        dispatch({
            type: SYMPTOM_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: SYMPTOM_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteSymptom = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SYMPTOM_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/v1/symptom/${_id}/`,
            config
        )

        dispatch({
            type: SYMPTOM_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: SYMPTOM_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createSymptom = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SYMPTOM_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const data = await axios.post(
            `/api/v1/symptom/`,
            changes,
            config
        )

        dispatch({
            type: SYMPTOM_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: SYMPTOM_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
const loadSymptomBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make symptom using a local symptom
export const makeSymptom = (file, symptomId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const symptom = await loadSymptomBase64(file);
    const symptom_input = JSON.stringify({ "symptom": symptom });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const data = await axios.post(
        apiEndPoint,
        symptom_input,
        config
    )
    try {
        dispatch({
            type: SYMPTOM_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": symptom }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: SYMPTOM_SUCCESS
                });
                if (symptomId && response.data) {
                    dispatch(updateSymptom(symptomId, { symptom: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: SYMPTOM_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: SYMPTOM_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateSymptom = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SYMPTOM_UPDATE_REQUEST
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const response = await axios.patch(
            `/api/v1/symptom/${_id}/`,
            changes,
            config
        );

        dispatch({
            type: SYMPTOM_UPDATE_SUCCESS,
            payload: response.data, // Assuming the updated symptom data is returned
        });

        dispatch({
            type: SYMPTOM_DETAILS_SUCCESS,
            payload: response.data, // Assuming the updated symptom data is returned
        });
    } catch (error) {
        let errorPayload = [];

        if (error.response && error.response.status === 400 && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            // Concatenate detailed validation errors to the error payload array
            errorPayload = Object.values(validationErrors).flat();
        }

        // Include generic error message or additional error data in the payload array
        if (error.response && error.response.data.detail) {
            errorPayload.push(error.response.data.detail);
        } else {
            errorPayload.push(error.message);
        }

        dispatch({
            type: SYMPTOM_UPDATE_FAIL,
            payload: errorPayload,
        });
    }
};
