import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ErrorMessage, Button, ChevronLeftIcon } from "nhsuk-react-components";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Result } from "antd";

export default function UnauthorizedScreen(props, navigation) {
    useEffect(() => {
        document.title = "Unauthorized Access"; 
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="nhsuk-width-container">

                    <main className="nhsuk-main-wrapper" id="maincontent" role="main">

                        <div className="nhsuk-grid-row">
                            <div className="nhsuk-grid-column-two-thirds">

                                <ErrorMessage>
                                    <h2>Unauthorized Access</h2>
                                    <p>Sorry, you do not have permission to access this page. Please contact the system administrator for further assistance.</p>
                                </ErrorMessage>
                                <Link to="/">
                                    <Button>
                                        Return to Dashboard
                                    </Button>
                                </Link>

                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </React.Fragment>
    );
}