import React, { useEffect } from 'react';

const Modal = ({ 
    show, 
    handleClose, 
    children,
    blur = true,
    theme = 'light'
}) => {
    useEffect(() => {
        const handleEsc = (e) => {
            if (e.key === 'Escape') handleClose();
        };
        
        if (show) {
            document.addEventListener('keydown', handleEsc);
            document.body.style.overflow = 'hidden';
        }
        
        return () => {
            document.removeEventListener('keydown', handleEsc);
            document.body.style.overflow = 'unset';
        };
    }, [show, handleClose]);

    const themes = {
        light: {
            background: '#ffffff',
            text: '#1a1a1a',
            shadow: '0 0 20px rgba(0,0,0,0.2)',
            overlay: 'rgba(0, 0, 0, 0.75)'
        },
        dark: {
            background: '#1a1a1a',
            text: '#ffffff',
            shadow: '0 0 20px rgba(0,0,0,0.4)',
            overlay: 'rgba(0, 0, 0, 0.85)'
        }
    };

    const currentTheme = themes[theme];

    const modalStyle = {
        display: show ? 'flex' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: currentTheme.overlay,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1050,
        opacity: show ? 1 : 0,
        transition: 'opacity 0.2s ease-in-out',
        backdropFilter: blur ? 'blur(5px)' : 'none',
        WebkitBackdropFilter: blur ? 'blur(5px)' : 'none',
        padding: '20px'
    };

    const contentStyle = {
        position: 'relative',
        backgroundColor: currentTheme.background,
        color: currentTheme.text,
        borderRadius: '8px',
        maxWidth: '90%',
        maxHeight: '85vh',
        width: 'auto',
        overflow: 'auto',
        boxShadow: currentTheme.shadow,
        animation: show ? 'modalPop 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)' : 'none',
        margin: '0 auto'
    };

    const closeButtonWrapperStyle = {
        position: 'relative',
        height: '40px', // Fixed height for the close button area
        marginBottom: '10px' // Space between button and content
    };

    const closeStyle = {
        position: 'absolute',
        right: '15px',
        top: '15px',
        cursor: 'pointer',
        fontSize: '24px',
        color: currentTheme.text,
        background: 'none',
        border: 'none',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        opacity: 0.7,
        transition: 'all 0.2s ease',
        padding: 0,
        transform: 'translateZ(0)',
        outline: 'none'
    };

    const closeHoverStyle = {
        ...closeStyle,
        opacity: 1,
        transform: 'rotate(90deg) translateZ(0)',
        backgroundColor: theme === 'light' ? 'rgba(0,0,0,0.05)' : 'rgba(255,255,255,0.1)'
    };

    const contentWrapperStyle = {
        padding: '0 25px 25px 25px' // Removed top padding since we have the closeButtonWrapper
    };

    return (
        <div style={modalStyle} onClick={handleClose}>
            <div 
                style={contentStyle} 
                onClick={e => e.stopPropagation()}
                role="dialog"
                aria-modal="true"
            >
                <div style={closeButtonWrapperStyle}>
                    <button 
                        style={closeStyle}
                        onMouseEnter={e => Object.assign(e.target.style, closeHoverStyle)}
                        onMouseLeave={e => Object.assign(e.target.style, closeStyle)}
                        onClick={handleClose}
                        aria-label="Close modal"
                    >
                        ×
                    </button>
                </div>
                <div style={contentWrapperStyle}>
                    {children}
                </div>
            </div>
            <style jsx>{`
                @keyframes modalPop {
                    0% {
                        transform: scale(0.95) translateY(10px);
                        opacity: 0;
                    }
                    100% {
                        transform: scale(1) translateY(0);
                        opacity: 1;
                    }
                }

                div::-webkit-scrollbar {
                    width: 8px;
                }

                div::-webkit-scrollbar-track {
                    background: ${theme === 'light' ? '#f1f1f1' : '#2d2d2d'};
                    border-radius: 4px;
                }

                div::-webkit-scrollbar-thumb {
                    background: ${theme === 'light' ? '#c1c1c1' : '#505050'};
                    border-radius: 4px;
                }

                div::-webkit-scrollbar-thumb:hover {
                    background: ${theme === 'light' ? '#a8a8a8' : '#606060'};
                }
            `}</style>
        </div>
    );
};

export default Modal;