import React, { useEffect } from 'react';
import { Input, Select as SimpleSelect, Card, ErrorSummary, InsetText, Button } from "nhsuk-react-components";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { getIdentityUserDetails } from '../../../actions/userActions';
import IdentityUserSelect from '../../../components/identityUserSelect/IdentityUserSelect.component';
import ISODateInput from '../../../components/date/ISODateInput.component';


const PatientDemographics = ({
    updatedPatient,
    onPatientDemographicChange,
    error,
}) => {
    const dispatch = useDispatch();
    const identityUserDetails = useSelector((state) => state.identityUserDetails);
    const { user: identityUser } = identityUserDetails;

    useEffect(() => {
        if (updatedPatient?.identityUserId) {
            dispatch(getIdentityUserDetails(updatedPatient.identityUserId));
        }
    }, [dispatch, updatedPatient.identityUserId]);

    const handleIdentityUserSelect = ({ identityUserId, email }) => {
        onPatientDemographicChange({
          ...updatedPatient,
          identityUserId: identityUserId,
          email: email
        });
    };

    return (
        <Card>
            <Card.Content>
                <Card.Heading className="nhsuk-heading-m">
                    <div className="icon-container">
                        <FaUser size={30} />
                    </div>
                    Patient: {updatedPatient?.forename} {updatedPatient?.surname}
                </Card.Heading>
                {error && (
                    <ErrorSummary>
                        <ErrorSummary.Title id="error-summary-title">
                            There is a problem
                        </ErrorSummary.Title>
                        <ErrorSummary.Body>
                            <ul className="nhsuk-list nhsuk-list--bullet">
                                {Array.isArray(error) ? error.map((err, index) => (
                                    <li key={index}>{err}</li>
                                )) : <li>{error}</li>}
                            </ul>
                        </ErrorSummary.Body>
                    </ErrorSummary>
                )}
                <InsetText>
                    <SimpleSelect
                        label="Implant type"
                        name="implantType"
                        value={updatedPatient?.implantType}
                        onChange={(e) => onPatientDemographicChange({ implantType: e.target.value })}
                        required
                    >
                        <SimpleSelect.Option value="">Select an option</SimpleSelect.Option>
                        <SimpleSelect.Option value="Cardiac implantable electronic device">Cardiac implantable electronic device</SimpleSelect.Option>
                        <SimpleSelect.Option value="Heart Valve">Heart Valve</SimpleSelect.Option>
                        <SimpleSelect.Option value="Coronary intervention">Coronary intervention</SimpleSelect.Option>
                        <SimpleSelect.Option value="Electrophysiology">Electrophysiology</SimpleSelect.Option>
                    </SimpleSelect>
                </InsetText>
                <Input
                    id="input-forname"
                    label="Forename"
                    name="forename"
                    value={updatedPatient?.forename}
                    onChange={(e) => onPatientDemographicChange({ forename: e.target.value })}
                />
                <Input
                    id="input-surname"
                    label="Surname"
                    name="surname"
                    value={updatedPatient?.surname}
                    onChange={(e) => onPatientDemographicChange({ surname: e.target.value })}
                />
                <ISODateInput
                    id="input-dob"
                    label="Date of Birth"
                    name="dob"
                    value={updatedPatient?.dateOfBirth}
                    onChange={(dob) => onPatientDemographicChange({ dateOfBirth: dob })}
                    required
                />
                <Input
                    id="input-nhsnumber"
                    label="NHS Number"
                    name="nhsNumber"
                    value={updatedPatient?.nhsNumber}
                    onChange={(e) => onPatientDemographicChange({ nhsNumber: e.target.value })}
                />
                  <Input
                    id="input-referencenumber"
                    label="Identification Number"
                    name="identificationNumber"
                    value={updatedPatient?.identificationNumber}
                    onChange={(e) => onPatientDemographicChange({ identificationNumber: e.target.value })}
                />
                
                <Input
                    id="input-phone"
                    label="Phone Number"
                    name="phoneNumber"
                    value={updatedPatient?.phoneNumber}
                    onChange={(e) => onPatientDemographicChange({ phoneNumber: e.target.value })}
                />
                <IdentityUserSelect
                    value={updatedPatient?.identityUserId}
                    onChange={handleIdentityUserSelect}
                    primarySearchProp="userName"
                    secondarySearchProp="email"
                />

                <SimpleSelect
                    label="Gender"
                    name="gender"
                    value={updatedPatient?.gender}
                    onChange={(e) => onPatientDemographicChange({ gender: e.target.value })}
                >
                    <SimpleSelect.Option value="Male">Male</SimpleSelect.Option>
                    <SimpleSelect.Option value="Female">Female</SimpleSelect.Option>
                    <SimpleSelect.Option value="Other">Other</SimpleSelect.Option>
                </SimpleSelect>

            </Card.Content>
        </Card>
    );
};

export default PatientDemographics;