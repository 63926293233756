import React from 'react';
import PropTypes from 'prop-types'
import { useLocation } from "react-router-dom"
import Footer from '../footer/Footer.component';
import NonAuthHeader from '../header/NonAuthHeader';

const NonAuthLayout = (props) => {
  const location = useLocation(); // Use the useLocation hook here

  return (
    <div>
      <NonAuthHeader />
      <div className="nhsuk-width-container-fluid">
        <main className="nhsuk-main-wrapper" id="maincontent" role="main">
          <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
              {props.children}
            </div>
          </div>
        </main>
      </div>

      <Footer />
    </div>
  );
}


NonAuthLayout.propTypes = {
  children: PropTypes.any,
}

export default NonAuthLayout; // Remove withRouter here
