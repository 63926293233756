import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { Input, Button, InsetText, Select as SimpleSelect, BackLink, Card, ErrorSummary } from "nhsuk-react-components";
import { createPatient } from "../../actions/patientActions";
import Callout from "../../components/callout/Callout.component";
import { PATIENT_CREATE_RESET } from "../../constants/patientConstants";
import ISODateInput from "../../components/date/ISODateInput.component";

export default function PatientCreateScreen({ history }) {
    const dispatch = useDispatch();
    const [newPatient, setNewPatient] = useState({
        identityUserId: "",
        dateOfBirth: "",
        gender: "Male",
        mrConditional: false,
        abondonedHardware: false,
        implantType: "",
        ecgIds: [],
        consentIds: [],
        implantIds: [],
        symptomIds: [],
        aetiologyIds: [],
        implantCentreIds: [],
        followUpCentreIds: []
    });

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const patientCreate = useSelector((state) => state.patientCreate);
    const { loading: loadingCreate, error: errorCreate, success: successCreate, patient } = patientCreate;

    useEffect(() => {
        document.title = "Create Patient";
        dispatch({ type: PATIENT_CREATE_RESET });
    }, [dispatch]);

    useEffect(() => {
        if (successCreate && patient?.id) {
            console.log("Patient created", patient);
            history.push(`/patient/${patient.id}`);
        }
    }, [history, successCreate, patient]);

    useEffect(() => {
        if (userInfo.userId && userInfo.email) {
            setNewPatient(prev => ({
                ...prev,
                identityUserId: userInfo.userId,
                email: userInfo.email
            }));
        }
    }, [userInfo]);

    const handleCreatePatient = (e) => {
        e.preventDefault();
        dispatch(createPatient(newPatient));
    };

    const implantTypeSelected = newPatient.implantType !== "" && newPatient.implantType !== "Select an option";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="nhsuk-grid-row">
                        <div className="welcome-title d-flex justify-content-between align-items-center">
                            <h3>Create a new Patient</h3>
                            <BackLink asElement="a" href="#" onClick={() => history.goBack()}>
                                Return back
                            </BackLink>
                        </div>
                        <div className="nhsuk-grid-column-one-half">
                            <div className="welcome-box">
                                <div className="welcome-content">
                                    <Card>
                                        <Card.Content>
                                            {errorCreate && (
                                                <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
                                                    <ErrorSummary.Title id="error-summary-title">
                                                        There is a problem
                                                    </ErrorSummary.Title>
                                                    <ErrorSummary.Body>
                                                        <ul className="nhsuk-list nhsuk-list--bullet">
                                                            {errorCreate.map((err) => (
                                                                <li key={err}>{err}</li>
                                                            ))}
                                                        </ul>
                                                    </ErrorSummary.Body>
                                                </ErrorSummary>
                                            )}

                                            <Card.Heading className="nhsuk-heading-m">
                                                <div className="icon-container ">
                                                    <FaUser size={30} />
                                                </div>
                                                New Patient: {newPatient?.forename} {newPatient?.surname}
                                            </Card.Heading>
                                            <p>This patient will be automatically linked to your account.</p>
                                            <InsetText>
                                                <SimpleSelect
                                                    label="Implant type"
                                                    selected={newPatient?.implantType}
                                                    name="implantType"
                                                    required
                                                    onChange={(e) => setNewPatient({ ...newPatient, implantType: e.target.value })}
                                                    value={newPatient?.implantType}
                                                >
                                                    <SimpleSelect.Option value="">Select an option</SimpleSelect.Option>
                                                    <SimpleSelect.Option value="Cardiac implantable electronic device">Cardiac implantable electronic device</SimpleSelect.Option>
                                                    <SimpleSelect.Option value="Heart Valve">Heart Valve</SimpleSelect.Option>
                                                    <SimpleSelect.Option value="Coronary intervention">Coronary intervention</SimpleSelect.Option>
                                                    <SimpleSelect.Option value="Electrophysiology">Electrophysiology</SimpleSelect.Option>
                                                </SimpleSelect>
                                            </InsetText>

                                            {implantTypeSelected && (
                                                <>
                                                    <Input
                                                        id="input-nhsnumber"
                                                        label="NHS Number"
                                                        name="nhsNumber"
                                                        value={newPatient?.nhsNumber}
                                                        onChange={(e) => setNewPatient({ ...newPatient, nhsNumber: e.target.value })}
                                                        required
                                                    />
                                                    <Input
                                                        id="input-identificationNumber"
                                                        label="Identification Number"
                                                        name="identificationNumber"
                                                        value={newPatient?.identificationNumber}
                                                        onChange={(e) => setNewPatient({ ...newPatient, identificationNumber: e.target.value })}

                                                    />

                                                    <Input
                                                        id="input-forname"
                                                        label="Forename"
                                                        name="forename"
                                                        value={newPatient?.forename}
                                                        onChange={(e) => setNewPatient({ ...newPatient, forename: e.target.value })}
                                                        required={true}
                                                    />
                                                    <Input
                                                        id="input-surname"
                                                        label="Surname"
                                                        name="surname"
                                                        value={newPatient?.surname}
                                                        onChange={(e) => setNewPatient({ ...newPatient, surname: e.target.value })}
                                                        required={true}
                                                    />
                                                    <ISODateInput
                                                        id="input-dateofbirth"
                                                        label="Date of Birth"
                                                        name="dateOfBirth"
                                                        value={newPatient?.dateOfBirth}
                                                        onChange={(isoDate) => setNewPatient({ ...newPatient, dateOfBirth: isoDate })}
                                                        required={true}
                                                    />

                                                    <Input
                                                        id="input-phone"
                                                        label="Phone Number"
                                                        name="phoneNumber"
                                                        value={newPatient?.phoneNumber}
                                                        onChange={(e) => setNewPatient({ ...newPatient, phoneNumber: e.target.value })}
                                                    />

                                                    <SimpleSelect
                                                        label="Gender"
                                                        selected={newPatient?.gender}
                                                        name="gender"
                                                        onChange={(e) => setNewPatient({ ...newPatient, gender: e.target.value })}
                                                        required
                                                    >
                                                        <SimpleSelect.Option value="Male">Male</SimpleSelect.Option>
                                                        <SimpleSelect.Option value="Female">Female</SimpleSelect.Option>
                                                        <SimpleSelect.Option value="Other">Other</SimpleSelect.Option>
                                                    </SimpleSelect>

                                                    {successCreate && (
                                                        <Callout
                                                            label="Success"
                                                            header="Success!"
                                                            autoDismiss={true}
                                                            dismissTime={5000}
                                                            time={new Date().toLocaleTimeString()}
                                                            message={"Patient created successfully!"}
                                                            mode="success"
                                                        />
                                                    )}

                                                    <Button
                                                        type="submit"
                                                        disabled={loadingCreate}
                                                        onClick={handleCreatePatient}
                                                    >
                                                        Create Patient
                                                    </Button>
                                                </>
                                            )}
                                        </Card.Content>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}