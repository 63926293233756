import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, ErrorSummary } from 'nhsuk-react-components';
import { createIdentityUser } from '../../actions/userActions';
import { v4 as uuidv4 } from 'uuid';
import { IDENTITY_USER_CREATE_RESET } from '../../constants/userConstants';

const CompactUserCreation = ({ onClose }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());
    const [error, setError] = useState(null);

    const currentUser = useSelector(state => state.userLogin.userInfo);
    const identityUserCreate = useSelector(state => state.identityUserCreate);
    const { loading: isSubmitting, error: submitError, success: createSuccess } = identityUserCreate;

    useEffect(() => {
        dispatch({ type: IDENTITY_USER_CREATE_RESET });
    }, [dispatch]);

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const generatePassword = useCallback(() => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
        return Array(12).fill().map(() => chars[Math.floor(Math.random() * chars.length)]).join('');
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (!validateEmail(email)) {
            alert('Please enter a valid email address.');
            return;
        }

        if (email !== confirmEmail) {
            alert('Emails do not match.');
            return;
        }

        const newUser = {
            email: email,
            userName: email,
            password: generatePassword(),
            organisationId: currentUser.organisationId
        };

        dispatch(createIdentityUser(newUser, idempotencyKey));
    };

    const handleReset = () => {
        setEmail('');
        setConfirmEmail('');
        setIdempotencyKey(uuidv4());
        setError(null);
        dispatch({ type: IDENTITY_USER_CREATE_RESET });
    };

    return (
        <div style={{ width: '100%', maxWidth: '500px', margin: '0 auto', padding: '20px', minWidth: '400px' }}>
            <h3>Add New Patient Email</h3>

            {!createSuccess ? (
                <form onSubmit={handleSubmit}>
                    {error && (
                        <ErrorSummary
                            title="There is a problem"
                            description={error}
                            style={{ marginBottom: '20px' }}
                        />
                    )}
                    <div style={{ marginBottom: '20px' }}>
                        <Input
                            id="email"
                            name="email"
                            label="Patient's Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <Input
                            id="confirmEmail"
                            name="confirmEmail"
                            label="Confirm Patient's Email"
                            type="email"
                            value={confirmEmail}
                            onChange={(e) => setConfirmEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Creating...' : 'Create Patient Account'}
                        </Button>
                    </div>
                </form>
            ) : (
                <div>
                    <p style={{ color: 'green', marginTop: '10px', marginBottom: '20px' }}>
                        <strong>Patient account created successfully! </strong>The patient will receive an automatic email to set up their password and access their account.
                    </p>
                    <p>
                        <strong>Patient portal access note: A SuperUser approval is required before the patient can view their passes.</strong>
                    </p>
                    <p>
                        You can now close this window and select the patient email from the list.
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button onClick={handleReset}>
                            Add Another Patient
                        </Button>
                        <Button onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </div>
            )}
            {submitError && <p style={{ color: 'red', marginTop: '10px' }}>An error occurred: <br />{submitError}</p>}
        </div>
    );
};

export default CompactUserCreation;