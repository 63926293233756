
export const layoutTypes = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
  }
  
  export const layoutTheme = {
    LIGHTMODE: 'light',
    DARKMODE: 'dark'
  }
  
  export const layoutWidthTypes = {
    FLUID: 'fluid',
    BOXED: 'boxed',
  }
  
  export const layoutPositions = {
    SCROLLABLE_TRUE: 'true',
    SCROLLABLE_FALSE: 'false'
  }
  
  export const topBarThemeTypes = {
    LIGHT: 'light',
    DARK: 'dark'
  }
  
  export const leftSidebarTypes = {
    DEFAULT: 'lg',
    COMPACT: 'md',
    ICON: 'sm',
  }
  
  export const leftSideBarThemeTypes = {
    LIGHT: 'light',
    COLORED: 'brand',
    DARK: 'dark',
  }
  
  /* LAYOUT */
  export const CHANGE_LAYOUT = 'CHANGE_LAYOUT'
  export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH'
  export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME'
  export const CHANGE_SIDEBAR_TYPE = 'CHANGE_SIDEBAR_TYPE'
  
  // topbar
  export const CHANGE_TOPBAR_THEME = 'CHANGE_TOPBAR_THEME'
  
  //layout mode
  export const CHANGE_LAYOUT_THEME = 'CHANGE_LAYOUT_THEME'
  
  //layout postion
  export const CHANGE_LAYOUT_POSITION = 'CHANGE_LAYOUT_POSITION'
  
  // show sidebar
  export const SHOW_SIDEBAR = 'SHOW_SIDEBAR'
  export const TOGGLE_LEFTMENU = 'TOGGLE_LEFTMENU'
  
  /* RIGHT SIDEBAR */
  export const SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR'
  
  // Preloader
  export const CHANGE_PRELOADER = 'CHANGE_PRELOADER'

  export const Icon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 16" height="27" width="67">
        <path className="nhsuk-logo__background" fill="#fff" d="M0 0h40v16H0z"></path>
        <path className="nhsuk-logo__text" fill="#fff" d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6"></path>
    </svg>
);

    