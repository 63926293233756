import React from 'react';
import { Card } from 'nhsuk-react-components';
import { Link } from 'react-router-dom'; // Replace 'react-router-dom' with the actual library you're using

const FeatureCard = ({ roles, currentUserRole, Icon, title, link }) => {
    // Destructuring the props directly in the function signature for clarity
    if (!roles?.includes(currentUserRole)) {
        // Use optional chaining to avoid errors if roles is undefined
        return null;
    }

    return (
        <Card clickable style={{ width: '280px', height: '280px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* Set a fixed width and height for the Card and center its content */}
            <Card.Content>
                <Card.Heading className="nhsuk-heading-m">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        {Icon && <Icon size={60} />}
                    </div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        {/* Removed unnecessary Card.Link */}
                        <Link to={link}>{title}</Link>
                    </div>
                </Card.Heading>
            </Card.Content>
        </Card>
    );
};

export default FeatureCard;
