import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary } from 'nhsuk-react-components';
import { listTemplates, createTemplate, updateTemplate, deleteTemplate, getTemplateDetails } from '../../actions/templateActions';
// constants 
import { TEMPLATE_CREATE_RESET, TEMPLATE_UPDATE_RESET } from '../../constants/templateConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';

// debouncer 
import { debounce } from 'lodash';

export default function TemplateCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Template Management"; 
    }, []);


    // reset the state of the templateCreate and templateUpdate
    useEffect(() => {
        dispatch({ type: TEMPLATE_CREATE_RESET });
        dispatch({ type: TEMPLATE_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/template/all?page=1&limit=2&filter=Email&query=gmail
    const [templateListFilter, settemplateListFilter] = useState({ query: '', page: 1, filter: 'Name' });

    // get template list from redux store
    const templateList = useSelector((state) => state.templateList);
    const { loading, error, templates, currentPage, totalPages } = templateList;

    // get template create from redux store
    const templateCreate = useSelector((state) => state.templateCreate);
    const { success: successCreate, error: templateCreateErrors } = templateCreate;

    // get template update from redux store
    const templateUpdate = useSelector((state) => state.templateUpdate);
    const { success: successUpdate, loading: templateUpdateProcessing } = templateUpdate;

    // get template delete from redux store
    const templateDelete = useSelector((state) => state.templateDelete);
    const { success: successDelete } = templateDelete;

    // get template details from redux store
    const templateDetails = useSelector((state) => state.templateDetails);
    const { template: templateDet, loading: templateDetailsLoading } = templateDetails;

    // newTemplate state
    const [newTemplate, setNewTemplate] = useState({});
    const [updatedTemplate, setUpdatedTemplate] = useState({});
    const [editMode, setEditMode] = useState(false);


    // listen to filter changes
    useEffect(() => {
        dispatch(listTemplates(templateListFilter));
    }, [dispatch, templateListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        settemplateListFilter({ ...templateListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        settemplateListFilter({ ...templateListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        settemplateListFilter({ ...templateListFilter, filter: selectedFilter });
    };

    // get all templates
    useEffect(() => {
        dispatch(listTemplates({}));
    }, [dispatch]);

    // listen to templateDet 
    useEffect(() => {
        if (templateDet) {
            // use spread operator to copy the templateDet object
            setUpdatedTemplate({ ...templateDet });
        }
    }, [templateDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newTemplate state
            setNewTemplate({
                name: '',
                htmlContent: '',
            });
        }
    }, [editMode]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this template?')) {
            dispatch(deleteTemplate(id)).then(() => {
                dispatch(listTemplates({}));
            }
            );
        }
    };

    // handle edit
    const handleEditTemplate = (id) => {
        
        dispatch(getTemplateDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateTemplate = () => {
        dispatch(createTemplate(newTemplate)).then(() => {
            setUpdatedTemplate(newTemplate);
            setEditMode(false);
            dispatch(listTemplates({}));
        }
        );
    };

    // handle update
    const handleUpdateTemplate = () => {
        dispatch(updateTemplate(updatedTemplate.id, updatedTemplate)).then(() => {

            setUpdatedTemplate(updatedTemplate);
            setEditMode(true);
            dispatch(getTemplateDetails(updatedTemplate.id));
        }
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Template Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <InsetText>
                                    <p>
                                        This section allows you to manage and configure the templates. You can create, update and delete templates in one place!
                                    </p>
                                </InsetText>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">

                            {templateCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {templateCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {templateCreateErrors.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null

                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                <Callout
                                        label="Note"
                                        header="Note!"
                                        autoDismiss={false}
                                        time={new Date().toLocaleTimeString()}
                                        title="Note!"
                                        message="Please dont change pre-configured template names"
                                        mode="warning"
                                    /> 

                                    <Input
                                        id="search-templates"
                                        label="Search templates"
                                        name="search-templates"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                </Card.Heading>
                                <Card.Description>


                                    <Fieldset>
                                        {templates && templates.length > 0 ? (
                                            templates.map((template) => (
                                                <Details key={template._id}>
                                                    <Details.Summary>
                                                        {template.name}
                                                    </Details.Summary>
                                                    <Details.Text>
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={templateDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditTemplate(template.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(template.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit Template
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an template. If you want to create a new template, please click the "Create Template" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create Template
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name"
                                            label="Name"
                                            name="name"
                                            type="text"
                                            onChange={(e) => setUpdatedTemplate({ ...updatedTemplate, name: e.target.value })}
                                            value={updatedTemplate.name ? updatedTemplate.name : ''}
                                            required
                                        />
                                        <Textarea
                                            id="htmlContent"
                                            label="HTML Content"
                                            name="htmlContent"
                                            onChange={(e) => setUpdatedTemplate({ ...updatedTemplate, htmlContent: e.target.value })}
                                            value={updatedTemplate.htmlContent ? updatedTemplate.htmlContent : ''}
                                            style={{ height: '500px' }}
                                        />


                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The template was created successfully." : "The template was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={templateUpdateProcessing}
                                        onClick={handleUpdateTemplate}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new Template
                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="name-create"
                                            label="Name"
                                            name="name-create"
                                            type="text"
                                            onChange={(e) => setNewTemplate({ ...newTemplate, name: e.target.value })}
                                            value={newTemplate?.name}
                                            required
                                        />
                                        <Textarea
                                            id="htmlContent-create"
                                            label="HTML Content"
                                            name="htmlContent-create"
                                            onChange={(e) => setNewTemplate({ ...newTemplate, htmlContent: e.target.value })}
                                            value={newTemplate?.htmlContent}
                                            style={{ height: '500px' }}
                                        />
                                    </Card.Description>

                                    <Button
                                        disabled={templateUpdateProcessing}
                                        onClick={handleCreateTemplate}
                                    >
                                        Create Template
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






