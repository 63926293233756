import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineDone } from "react-icons/md";
import { BackLink } from "nhsuk-react-components";


function ResetPasswordSentScreen({ history, location }) {

    useEffect(() => {
        document.title = "Reset Password Sent";
    }, []);

    return (
        <React.Fragment>

            <div className="nhsuk-width-container">

                <main className="nhsuk-main-wrapper" id="maincontent" role="main">

                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">

                            <h1>Reset Password</h1>
                            <MdOutlineDone size={50} color="green" />
                            <h3>
                                Link sent successfully.
                            </h3>
                            <p>
                                if an account exists with the email address you entered, you will receive an email with a link to reset your password.
                            </p>
                            <p>
                                The link will expire after 24 hours.
                            </p>
                            <BackLink
                                asElement="a"
                                href="#"
                                onClick={() => history.goBack()}
                            >
                                Go back

                            </BackLink>

                        </div>

                    </div>

                </main>
            </div >
        </React.Fragment >
    )
}

export default ResetPasswordSentScreen;