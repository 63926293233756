import {

    IMPLANT_HISTORY_LIST_REQUEST,
    IMPLANT_HISTORY_LIST_SUCCESS,
    IMPLANT_HISTORY_LIST_FAIL,

    IMPLANT_HISTORY_DETAILS_REQUEST,
    IMPLANT_HISTORY_DETAILS_SUCCESS,
    IMPLANT_HISTORY_DETAILS_FAIL,

    IMPLANT_HISTORY_DELETE_REQUEST,
    IMPLANT_HISTORY_DELETE_SUCCESS,
    IMPLANT_HISTORY_DELETE_FAIL,

    IMPLANT_HISTORY_CREATE_REQUEST,
    IMPLANT_HISTORY_CREATE_SUCCESS,
    IMPLANT_HISTORY_CREATE_FAIL,
    IMPLANT_HISTORY_CREATE_RESET,

    IMPLANT_HISTORY_UPDATE_REQUEST,
    IMPLANT_HISTORY_UPDATE_SUCCESS,
    IMPLANT_HISTORY_UPDATE_FAIL,
    IMPLANT_HISTORY_UPDATE_RESET,

    IMPLANT_HISTORY_COUNT_SUCCESS,
    IMPLANT_HISTORY_COUNT_REQUEST,
    IMPLANT_HISTORY_COUNT_FAIL,

    SET_CURRENT_PAGE,

} from "../constants/implantHistoryConstants";

const initialState = {
    ImplantHistories: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const implantHistoryListReducer = (
    state = {
        ImplantHistories: []
    }, action) => {
    switch (action.type) {
        case IMPLANT_HISTORY_LIST_REQUEST:
            return { loading: true, implantHistories: [] };
        case IMPLANT_HISTORY_LIST_SUCCESS:
            // our state will have a list of ImplantHistories after the request
            return {
                loading: false,
                implantHistories: action.payload.implantHistories,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case IMPLANT_HISTORY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantHistoryListByNhsNumberReducer = (
    state = {
        ImplantHistories: []
    }, action) => {
    switch (action.type) {
        case IMPLANT_HISTORY_LIST_REQUEST:
            return { loading: true, implantHistories: [] };
        case IMPLANT_HISTORY_LIST_SUCCESS:
            // our state will have a list of ImplantHistories after the request
            return {
                loading: false,
                implantHistories: action.payload.implantHistories,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case IMPLANT_HISTORY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantHistoryCountReducer = (state = { implantHistoryCounts: [] }, action) => {
  switch (action.type) {
    case IMPLANT_HISTORY_COUNT_REQUEST:
      return { loading: true, implantHistoryCounts: [] };
    case IMPLANT_HISTORY_COUNT_SUCCESS:
      return { loading: false, implantHistoryCounts: action.payload };
    case IMPLANT_HISTORY_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const implantHistoryDetailsReducer = (
    state = {
        implantHistory: { comments: [] }
    }, action) => {
    switch (action.type) {
        case IMPLANT_HISTORY_DETAILS_REQUEST:
            return { loading: true, ...state };
        case IMPLANT_HISTORY_DETAILS_SUCCESS:
            // our state will have a list of ImplantHistories after the request
            return { loading: false, implantHistory: action.payload };
        case IMPLANT_HISTORY_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantHistoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case IMPLANT_HISTORY_DELETE_REQUEST:
            return { loading: true }

        case IMPLANT_HISTORY_DELETE_SUCCESS:
            return { loading: false, success: true }

        case IMPLANT_HISTORY_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const implantHistoryCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case IMPLANT_HISTORY_CREATE_REQUEST:
            return { loading: true }

        case IMPLANT_HISTORY_CREATE_SUCCESS:
            return { loading: false, success: true, implantHistory: action.payload }

        case IMPLANT_HISTORY_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case IMPLANT_HISTORY_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const implantHistoryUpdateReducer = (state = { implantHistory: {} }, action) => {
    switch (action.type) {
        case IMPLANT_HISTORY_UPDATE_REQUEST:
            return { loading: true }

        case IMPLANT_HISTORY_UPDATE_SUCCESS:
            return { loading: false, success: true, implantHistory: action.payload }

        case IMPLANT_HISTORY_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case IMPLANT_HISTORY_UPDATE_RESET:
            return { implantHistory: {} }

        default:
            return state
    }
}