import axios from 'axios';

import {
    SHORT_URL_LIST_REQUEST,
    SHORT_URL_LIST_SUCCESS,
    SHORT_URL_LIST_FAIL,

    SHORT_URL_DETAILS_REQUEST,
    SHORT_URL_DETAILS_SUCCESS,
    SHORT_URL_DETAILS_FAIL,

    SHORT_URL_DELETE_REQUEST,
    SHORT_URL_DELETE_SUCCESS,
    SHORT_URL_DELETE_FAIL,

    SHORT_URL_CREATE_REQUEST,
    SHORT_URL_CREATE_SUCCESS,
    SHORT_URL_CREATE_FAIL,

    SHORT_URL_UPDATE_REQUEST,
    SHORT_URL_UPDATE_SUCCESS,
    SHORT_URL_UPDATE_FAIL,

    SHORT_URL_REQUEST,
    SHORT_URL_FAIL,
    SHORT_URL_RESET,
    SHORT_URL_SUCCESS,

    SHORT_URL_COUNT_SUCCESS,
    SHORT_URL_COUNT_REQUEST,
    SHORT_URL_COUNT_FAIL,

    SET_CURRENT_PAGE,
    

} from "../constants/shortUrlConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listShortUrls = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: SHORT_URL_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/api/v1/shortUrl/all', config);

        dispatch({
            type: SHORT_URL_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: SHORT_URL_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later shortUrls",
        });
    }
}

// count shortUrls
export const countShortUrls = () => async (dispatch, getState) => {
    try {
      dispatch({ type: SHORT_URL_COUNT_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        },
      }
      const { data } = await axios.get('/api/v1/shortUrl/count', config);
  
      dispatch({
        type: SHORT_URL_COUNT_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: SHORT_URL_COUNT_FAIL,
        payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : "Failed to fetch shortUrl counts",
      });
    }
  }
  


export const getShortUrlDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SHORT_URL_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/api/v1/shortUrl/${_id}`, config);

        dispatch({
            type: SHORT_URL_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: SHORT_URL_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

// get shortUrl by userId
export const getShortUrlDetailsbyUserId = (userId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SHORT_URL_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/v1/shortUrl/user/${userId}`, config);

        dispatch({
            type: SHORT_URL_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: SHORT_URL_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getShortUrlDetailsbyCode = (shortcode) => async (dispatch, getState) => {
    try {
        dispatch({ type: SHORT_URL_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/api/v1/shortUrl/code/${shortcode}`, config);

        dispatch({
            type: SHORT_URL_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: SHORT_URL_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteShortUrl = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHORT_URL_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/v1/shortUrl/${_id}/`,
            config
        )

        dispatch({
            type: SHORT_URL_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: SHORT_URL_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createShortUrl = (shortUrlData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHORT_URL_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json', // Change content type to 'application/json'
                Authorization: `Bearer ${userInfo.token}`
            }
        }


        const {data} = await axios.post(
            `/api/v1/shortUrl`,
            shortUrlData,
            config
        )

        dispatch({
            type: SHORT_URL_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: SHORT_URL_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}


const loadShortUrlBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};


export const updateShortUrl = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHORT_URL_UPDATE_REQUEST
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const response = await axios.patch(
            `/api/v1/shortUrl/${_id}/`,
            changes,
            config
        );

        dispatch({
            type: SHORT_URL_UPDATE_SUCCESS,
            payload: response.data, // Assuming the updated shortUrl data is returned
        });

        dispatch({
            type: SHORT_URL_DETAILS_SUCCESS,
            payload: response.data, // Assuming the updated shortUrl data is returned
        });
    } catch (error) {
        let errorPayload = [];

        if (error.response && error.response.status === 400 && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            // Concatenate detailed validation errors to the error payload array
            errorPayload = Object.values(validationErrors).flat();
        }

        // Include generic error message or additional error data in the payload array
        if (error.response && error.response.data.detail) {
            errorPayload.push(error.response.data.detail);
        } else {
            errorPayload.push(error.message);
        }

        dispatch({
            type: SHORT_URL_UPDATE_FAIL,
            payload: errorPayload,
        });
    }
};
