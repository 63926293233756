import {

    SMTP_CONFIG_LIST_REQUEST,
    SMTP_CONFIG_LIST_SUCCESS,
    SMTP_CONFIG_LIST_FAIL,

    SMTP_CONFIG_DETAILS_REQUEST,
    SMTP_CONFIG_DETAILS_SUCCESS,
    SMTP_CONFIG_DETAILS_FAIL,

    SMTP_CONFIG_DELETE_REQUEST,
    SMTP_CONFIG_DELETE_SUCCESS,
    SMTP_CONFIG_DELETE_FAIL,

    SMTP_CONFIG_CREATE_REQUEST,
    SMTP_CONFIG_CREATE_SUCCESS,
    SMTP_CONFIG_CREATE_FAIL,
    SMTP_CONFIG_CREATE_RESET,

    SMTP_CONFIG_UPDATE_REQUEST,
    SMTP_CONFIG_UPDATE_SUCCESS,
    SMTP_CONFIG_UPDATE_FAIL,
    SMTP_CONFIG_UPDATE_RESET,

    SMTP_CONFIG_COUNT_SUCCESS,
    SMTP_CONFIG_COUNT_REQUEST,
    SMTP_CONFIG_COUNT_FAIL,

    SET_CURRENT_PAGE,

} from "../constants/smtpConfigConstants";

const initialState = {
    smtpConfigs: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const smtpConfigListReducer = (
    state = {
        smtpConfigs: []
    }, action) => {
    switch (action.type) {
        case SMTP_CONFIG_LIST_REQUEST:
            return { loading: true, smtpConfigs: [] };
        case SMTP_CONFIG_LIST_SUCCESS:
            // our state will have a list of smtpConfigs after the request
            return {
                loading: false,
                smtpConfigs: action.payload.smtpConfigs,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case SMTP_CONFIG_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const smtpConfigCountReducer = (state = { smtpConfigCounts: [] }, action) => {
  switch (action.type) {
    case SMTP_CONFIG_COUNT_REQUEST:
      return { loading: true, smtpConfigCounts: [] };
    case SMTP_CONFIG_COUNT_SUCCESS:
      return { loading: false, smtpConfigCounts: action.payload };
    case SMTP_CONFIG_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const smtpConfigDetailsReducer = (
    state = {
        smtpConfig: { comments: [] }
    }, action) => {
    switch (action.type) {
        case SMTP_CONFIG_DETAILS_REQUEST:
            return { loading: true, ...state };
        case SMTP_CONFIG_DETAILS_SUCCESS:
            // our state will have a list of smtpConfigs after the request
            return { loading: false, smtpConfig: action.payload };
        case SMTP_CONFIG_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const smtpConfigDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SMTP_CONFIG_DELETE_REQUEST:
            return { loading: true }

        case SMTP_CONFIG_DELETE_SUCCESS:
            return { loading: false, success: true }

        case SMTP_CONFIG_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const smtpConfigCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SMTP_CONFIG_CREATE_REQUEST:
            return { loading: true }

        case SMTP_CONFIG_CREATE_SUCCESS:
            return { loading: false, success: true, smtpConfig: action.payload }

        case SMTP_CONFIG_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case SMTP_CONFIG_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const smtpConfigUpdateReducer = (state = {} , action) => {
    switch (action.type) {
        case SMTP_CONFIG_UPDATE_REQUEST:
            return { loading: true }

        case SMTP_CONFIG_UPDATE_SUCCESS:
            return { loading: false, success: true, smtpConfig: action.payload }

        case SMTP_CONFIG_UPDATE_FAIL:
            return { loading: false, error: action.payload.data }

        case SMTP_CONFIG_UPDATE_RESET:
            return { smtpConfig: {} }

        default:
            return state
    }
}