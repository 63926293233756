// given LoginScreen.js 
// generate similarely RegisterScreen

import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIdentityUserDetails, createIdentityUser, resetPassword } from "../../actions/userActions";
import { listOrganisations } from "../../actions/organisationActions";
import { Input, Button, Select, WarningCallout, BackLink, Card, DoDontList, ErrorSummary } from "nhsuk-react-components";
import { List } from 'react-content-loader'
import Callout from "../../components/callout/Callout.component";
import Checklist from "../../components/checklist/Checklist.component";
import { IDENTITY_USER_CREATE_RESET } from "../../constants/userConstants";
import axios from "axios";


function CreateUserScreen({ match, history }) {

    const dispatch = useDispatch();

    // reset state after success
    useEffect(() => {
        dispatch({ type: IDENTITY_USER_CREATE_RESET });
    }, [dispatch]);

    // define new state for identityUser
    const [newIdentityUser, setNewIdentityUser] = useState({});

    // createIdentityUser 
    const identityUserCreate = useSelector(state => state.identityUserCreate);
    const { loading, error, success } = identityUserCreate;
    // List organisations
    const organisationList = useSelector(state => state.organisationList);
    const { loading: loadingOrganisations, error: errorOrganisations, organisations } = organisationList;

    // Fetch organisations
    useEffect(() => {
        dispatch(listOrganisations());
        setNewIdentityUser({
            email: "",
            userName: "",
            password: "",
            organisationId: ""
        });

    }, [dispatch]);

    // handle create identityUser
    const handleCreateIdentityUser = async () => {
        dispatch(createIdentityUser(newIdentityUser)).then(() => {
            if (success) {
                // go back to 
                alert("User created successfully");
            }
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-one-half">
                            <div className="welcome-box">

                                <div className="welcome-title">
                                    <h3>Create a new Identity User Account</h3>
                                    <BackLink
                                        asElement="a"
                                        href="#"
                                        onClick={() => history.goBack()}
                                    >
                                        Return back
                                    </BackLink>
                                    <Card style={{ padding: "20px" }}>
                                        <Card.Heading>
                                            <h3></h3>
                                        </Card.Heading>
                                        <Card.Description>
                                            {error &&
                                                <ErrorSummary
                                                    aria-labelledby="error-summary-title"
                                                    role="alert"
                                                    tabIndex={-1}
                                                >
                                                    <ErrorSummary.Title id="error-summary-title">
                                                        There is a problem
                                                    </ErrorSummary.Title>
                                                    <ErrorSummary.Body>
                                                        <p> {error} </p>
                                                    </ErrorSummary.Body>
                                                </ErrorSummary>
                                            }

                                            <WarningCallout>
                                                <WarningCallout.Label>Important</WarningCallout.Label>
                                                <p>
                                                    This is a new user account. The user will be sent an email to confirm their email account. They will have then to reset their password.
                                                </p>
                                            </WarningCallout>


                                            <div className="cards-grid">
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    label="Email"
                                                    type="text"
                                                    onChange={(e) => setNewIdentityUser({ ...newIdentityUser, email: e.target.value, userName: e.target.value })}
                                                />
                                                <Input
                                                    id="password"
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    onChange={(e) => setNewIdentityUser({ ...newIdentityUser, password: e.target.value })}
                                                />
                                                <Select
                                                    required
                                                    id="organisation"
                                                    name="organisation"
                                                    label="Organisation"
                                                    onChange={(e) => setNewIdentityUser({ ...newIdentityUser, organisationId: e.target.value })}
                                                >
                                                    <Select.Option value="">Select an organisation</Select.Option>
                                                    {loadingOrganisations ? (
                                                        "Loading organisations..."
                                                    ) : errorOrganisations ? (
                                                        <h3>{errorOrganisations}</h3>
                                                    ) : (
                                                        (organisations) ? (
                                                            organisations.map((organisation) => (
                                                                <Select.Option key={organisation.id} value={organisation.id}>
                                                                    {organisation.name}
                                                                </Select.Option>
                                                            ))
                                                        ) : (
                                                            <Select.Option value="">No organisations found</Select.Option>
                                                        )
                                                    )
                                                    }

                                                </Select>
                                            </div>
                                        </Card.Description>
                                        <Button
                                            disabled={loading}
                                            onClick={handleCreateIdentityUser}
                                        >
                                            {loading ? (
                                                "Creating..."
                                            ) : (
                                                "Create user"
                                            )}
                                        </Button>

                                        {success && (
                                            <Callout
                                                mode="success"
                                                header="User created!"
                                                message="The user has been created successfully. An email has been sent to the user to confirm their email address."
                                            />
                                        )}



                                    </Card>


                                </div>
                            </div>
                        </div>
                    </div>

                </Container>
            </div>
        </React.Fragment>
    );

}
export default CreateUserScreen;