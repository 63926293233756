import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, Select } from 'nhsuk-react-components';
import { listPatients, deletePatient } from '../../actions/patientActions';

import Pagination from '../../components/pagination/Pagination.component';

import AppSpinner from '../../components/spinner/AppSpinner.component';
import { formatISODate } from '../../utils/functions';

export default function PatientListScreen({ history }) {

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Patients";
    }, []);


    const patientList = useSelector((state) => state.patientList);
    const { loading, error, patients, currentPage, totalPages } = patientList;

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/patient/all?page=1&limit=2&filter=Email&query=gmail
    const [patientListFilter, setpatientListFilter] = useState({ query: '', page: 1, filter: 'Surname' });

    // listen to filter changes
    useEffect(() => {
        dispatch(listPatients(patientListFilter));
    }, [dispatch, patientListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setpatientListFilter({ ...patientListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setpatientListFilter({ ...patientListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setpatientListFilter({ ...patientListFilter, filter: selectedFilter });
    };

    // Handle Delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            dispatch(deletePatient(id));
            setpatientListFilter({ query: '', page: 1, filter: 'Surname' });
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Patient Management</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <Button
                                    variant="primary"
                                    asElement={Link}
                                    onClick={() => history.push(`/patients/create`)}
                                >
                                    + Add Patient
                                </Button>


                                <Card>
                                    <Card.Content>
                                    <Card.Heading>
                                        <Input
                                            id="input-example"
                                            label="Search"
                                            name="test-name"
                                            width={20}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handleSearchChange(e);
                                                }
                                            }
                                            }
                                        />
                                        <Select
                                            value={patientListFilter.filter}
                                            onChange={handleFilterChange}
                                            label="Filter by:"
                                        >
                                            <option value="Surname">Surname</option>
                                            <option value="Forename">First Name</option>
                                            <option value="NHSNumber">NHS Number</option>
                                            <option value="DateOfBirth">Date of Birth (dd/mm/yyyy)</option>
                                        </Select>
                                    </Card.Heading>

                                    <Card.Description>
                                        <Table responsive={true}>
                                            <Table.Head>
                                                <Table.Row>
                                                    <Table.Cell>Full name</Table.Cell>
                                                    <Table.Cell>Birthdate</Table.Cell>
                                                    <Table.Cell>NHS Number</Table.Cell>
                                                    <Table.Cell> &nbsp;</Table.Cell>
                                                    <Table.Cell> &nbsp;</Table.Cell>
                                                </Table.Row>
                                            </Table.Head>
                                            <Table.Body>
                                                {loading ? (
                                                    <AppSpinner />

                                                ) : error ? (
                                                    <div>{error}</div>
                                                ) : (
                                                    (patients) ?
                                                        (patients.map((patient) => (
                                                            <Table.Row key={patient.id}>
                                                                <Table.Cell>
                                                                    <Details>
                                                                        <Details.Summary>{patient.forename} {patient.surname}</Details.Summary>
                                                                        <Details.Text>
                                                                            <strong>Gendre:</strong> <br />
                                                                            {patient.gender ? patient.gender : "Not Specified"}<br />
                                                                            <strong>Phone:</strong> <br />
                                                                            {patient.phoneNumber ? patient.phoneNumber : "Not Specified"}
                                                                        </Details.Text>
                                                                    </Details>

                                                                </Table.Cell>
                                                                <Table.Cell>{formatISODate(patient.dateOfBirth)}</Table.Cell>
                                                                <Table.Cell>{patient.nhsNumber}</Table.Cell>
                                                                <Table.Cell>
                                                                    <Button
                                                                        onClick={() => history.push(`/patient/${patient.id}`)}
                                                                    >
                                                                        View/Amend
                                                                    </Button>

                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Button
                                                                        onClick={() => handleDelete(patient.id)}
                                                                        secondary
                                                                    >
                                                                        Delete




                                                                    </Button>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        ))) : (
                                                            <div>No Patients found</div>
                                                        )

                                                )}
                                            </Table.Body>
                                        </Table>
                                    </Card.Description>
                                    </Card.Content>
                                </Card>

                                {loading ? (
                                    <div></div>
                                ) : error ? (
                                    <div>{error}</div>
                                ) : (

                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        handlePageChange={handlePageChange}
                                    />
                                )
                                }



                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>

    );
}






