const generateEmailTemplate = ({ patient, androidUrl, iosUrl }) => {
  return `
    <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
      <p>Dear ${patient.forename} ${patient.surname},</p>
      
      <p>Thank you for using My Heart Passport. Your digital ID card is ready to be added to your mobile wallet.</p>
      
      <div>
        <p><strong>Please follow these steps:</strong></p>
        <ol style="margin-left: 20px;">
          <li>Click the appropriate button below based on your device:</li>
          <div style="margin: 15px 0; display: flex; gap: 20px; flex-wrap: wrap;">
            <a href="${androidUrl}" style="text-decoration: none;">
              <img src="https://upload.wikimedia.org/wikipedia/commons/b/bb/Add_to_Google_Wallet_badge.svg" 
                   alt="Add to Google Wallet" 
                   style="height: 60px; width: auto;" />
            </a>
            <a href="${iosUrl}" style="text-decoration: none;">
              <img src="https://upload.wikimedia.org/wikipedia/commons/3/30/Add_to_Apple_Wallet_badge.svg" 
                   alt="Add to Apple Wallet" 
                   style="height: 60px; width: auto;" />
            </a>
          </div>
          <li>Download and save your My Heart Passport ID Card into your Apple or Google wallet.</li>
          <li>You can show this ID card to healthcare practitioners at other hospitals if needed so that they can assess your device details.</li>
        </ol>
      </div>

      <p>To report any device-related issues, please log in to your profile at <a href="https://myheartpassport.com" style="color: #4CAF50; text-decoration: none;">myheartpassport.com</a> and submit a report through your dashboard.</p>
      
      <p style="margin-top: 30px;">
        Best regards,<br/>
        The My Heart Passport Team
      </p>
    </div>
  `;
};

export default generateEmailTemplate;