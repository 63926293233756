import React, { useEffect, useState } from "react";
import { Button } from "nhsuk-react-components";

export default function EmailConfirmedScreen(props, history) {
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        document.title = "Email Confirmed";
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (countdown > 1) {
                setCountdown(countdown - 1);
            } else {
                // remove user info from local storage
                localStorage.removeItem("userInfo");
                // remove two factor authentication from local storage
                localStorage.removeItem("twoFactorAuthentication");
                // reload page /
                window.location.href = "/login";
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [countdown]);

    // handle logout
    const handleLogout = () => {
        // remove user info from local storage
        localStorage.removeItem("userInfo");
        // remove two factor authentication from local storage
        localStorage.removeItem("twoFactorAuthentication");
        window.location.href = "/login";
    };

    // handle password reset
    const handlePasswordReset = () => {
        // remove user info from local storage
        localStorage.removeItem("userInfo");
        // remove two factor authentication from local storage
        localStorage.removeItem("twoFactorAuthentication");
        window.location.href = "/resetpasswordsetup";
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="nhsuk-width-container">
                    <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                        <div className="nhsuk-grid-row">
                            <div className="nhsuk-grid-column-two-thirds">
                                <h2>Email Confirmed!</h2>
                                <p>
                                    Your email has been confirmed. You can now login to the
                                    application. You will be redirected to the login page in{" "}
                                    {countdown} seconds...
                                </p>
                                <Button type="submit" onClick={handlePasswordReset}>
                                    Reset my password
                                </Button>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </React.Fragment>
    );
}

                            