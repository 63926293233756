export const SMTP_CONFIG_LIST_REQUEST = 'SMTP_CONFIG_LIST_REQUEST'
export const SMTP_CONFIG_LIST_SUCCESS = 'SMTP_CONFIG_LIST_SUCCESS'
export const SMTP_CONFIG_LIST_FAIL = 'SMTP_CONFIG_LIST_FAIL'


export const SMTP_CONFIG_DETAILS_REQUEST = 'SMTP_CONFIG_DETAILS_REQUEST'
export const SMTP_CONFIG_DETAILS_SUCCESS = 'SMTP_CONFIG_DETAILS_SUCCESS'
export const SMTP_CONFIG_DETAILS_FAIL = 'SMTP_CONFIG_DETAILS_FAIL'


export const SMTP_CONFIG_DELETE_REQUEST = 'SMTP_CONFIG_DELETE_REQUEST'
export const SMTP_CONFIG_DELETE_SUCCESS = 'SMTP_CONFIG_DELETE_SUCCESS'
export const SMTP_CONFIG_DELETE_FAIL = 'SMTP_CONFIG_DELETE_FAIL'

export const SMTP_CONFIG_CREATE_REQUEST = 'SMTP_CONFIG_CREATE_REQUEST'
export const SMTP_CONFIG_CREATE_SUCCESS = 'SMTP_CONFIG_CREATE_SUCCESS'
export const SMTP_CONFIG_CREATE_FAIL = 'SMTP_CONFIG_CREATE_FAIL'
export const SMTP_CONFIG_CREATE_RESET = 'SMTP_CONFIG_CREATE_RESET'


export const SMTP_CONFIG_UPDATE_REQUEST = 'SMTP_CONFIG_UPDATE_REQUEST'
export const SMTP_CONFIG_UPDATE_SUCCESS = 'SMTP_CONFIG_UPDATE_SUCCESS'
export const SMTP_CONFIG_UPDATE_FAIL = 'SMTP_CONFIG_UPDATE_FAIL'
export const SMTP_CONFIG_UPDATE_RESET = 'SMTP_CONFIG_UPDATE_RESET'

export const SMTP_CONFIG_REQUEST = 'SMTP_CONFIG_REQUEST'
export const SMTP_CONFIG_SUCCESS = 'SMTP_CONFIG_SUCCESS'
export const SMTP_CONFIG_FAIL = 'SMTP_CONFIG_FAIL'
export const SMTP_CONFIG_RESET = 'SMTP_CONFIG_RESET'

export const SMTP_CONFIG_COUNT_REQUEST = "SMTP_CONFIG_COUNT_REQUEST";
export const SMTP_CONFIG_COUNT_SUCCESS = "SMTP_CONFIG_COUNT_SUCCESS";
export const SMTP_CONFIG_COUNT_FAIL = "SMTP_CONFIG_COUNT_FAIL";


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'