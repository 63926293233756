import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    identityUserDetailsReducer,
    identityUserUpdateReducer,
    userUpdateReducer,
    userListReducer,
    userDeleteReducer,
    userAuthenticationCheckReducer,
    resetPasswordReducer,
    identityUserCreateReducer,
} from './reducers/userReducers';

// organisations
import { 
    organisationListReducer, 
    organisationCountReducer,
    organisationDetailsReducer,
    organisationDeleteReducer,
    organisationCreateReducer,
    organisationUpdateReducer,
} from './reducers/organisationReducers';

// patients
import {
    patientListReducer,
    patientCountReducer,
    patientDetailsReducer,  
    patientEmergencyDetailsReducer,
    patientDeleteReducer,
    patientCreateReducer,
    patientUpdateReducer,
    patientUpdateConsentIdsReducer,
    patientDtoReducer,
} from './reducers/patientReducers';

// ecgs
import {
    ecgListReducer,
    ecgListByIDsReducer,
    ecgCountReducer,
    ecgDetailsReducer,
    ecgDeleteReducer,
    ecgCreateReducer,
    ecgUpdateReducer,
} from './reducers/ecgReducers';

// symptoms
import {
    symptomListReducer,
    symptomListByIDsReducer,
    symptomCountReducer,
    symptomDetailsReducer,
    symptomDeleteReducer,
    symptomCreateReducer,
    symptomUpdateReducer,
} from './reducers/symptomReducers';

// aetiologies
import {
    aetiologyListReducer,
    aetiologyListByIDsReducer,
    aetiologyCountReducer,
    aetiologyDetailsReducer,
    aetiologyDeleteReducer,
    aetiologyCreateReducer,
    aetiologyUpdateReducer,
} from './reducers/aetiologyReducers';

// implants
import {
    implantListReducer,
    implantListByIDsReducer,
    implantCountReducer,
    implantDetailsReducer,
    implantDetailsByBarcodeReducer,
    implantDeleteReducer,
    implantCreateReducer,
    implantUpdateReducer,
    implantCreateOrUpdateReducer,
    implantDtoReducer,
} from './reducers/implantReducers';

// implantCentres
import {
    implantCentreListReducer,
    implantCentreListByIDsReducer,
    implantCentreCountReducer,
    implantCentreDetailsReducer,
    implantCentreDeleteReducer,
    implantCentreCreateReducer,
    implantCentreUpdateReducer,
} from './reducers/implantCentreReducers';

// followupCentres
import {
    followupCentreListReducer,
    followupCentreListByIDsReducer,
    followupCentreCountReducer,
    followupCentreDetailsReducer,
    followupCentreDeleteReducer,
    followupCentreCreateReducer,
    followupCentreUpdateReducer,
} from './reducers/followupCentreReducers';

// shortUrls
import {
    shortUrlListReducer,
    shortUrlCountReducer,
    shortUrlDetailsReducer,
    shortUrlDetailsByCodeReducer,
    shortUrlDetailsByUserIdReducer,
    shortUrlDeleteReducer,
    shortUrlCreateReducer,
    shortUrlUpdateReducer,
} from './reducers/shortUrlReducers';

// templates
import {
    templateListReducer,
    templateCountReducer,
    templateDetailsReducer,
    templateDetailsByNameReducer,
    templateDeleteReducer,
    templateCreateReducer,
    templateUpdateReducer,
} from './reducers/templateReducers';

// smtpConfigs
import {
    smtpConfigListReducer,
    smtpConfigCountReducer,
    smtpConfigDetailsReducer,
    smtpConfigDeleteReducer,
    smtpConfigCreateReducer,
    smtpConfigUpdateReducer,
} from './reducers/smtpConfigReducers';

// consents
import {
    consentListReducer,
    consentListByIDsReducer,
    consentCountReducer,
    consentDetailsReducer,
    consentDeleteReducer,
    consentCreateReducer,
    consentUpdateReducer,
} from './reducers/consentReducers';

// implantHistory
import {
    implantHistoryListReducer,
    implantHistoryListByNhsNumberReducer,
    implantHistoryCountReducer,
    implantHistoryDetailsReducer,
    implantHistoryDeleteReducer,
    implantHistoryCreateReducer,
    implantHistoryUpdateReducer,
} from './reducers/implantHistoryReducers';

// implantInstance
import {
    implantInstanceListReducer,
    implantInstanceCountReducer,
    implantInstanceDetailsReducer,
    implantInstanceDeleteReducer,
    implantInstanceCreateReducer,
    implantInstanceUpdateReducer,
} from './reducers/implantInstanceReducers';


import {
    layoutReducer,
} from './reducers/layoutReducers';


const reducer = combineReducers({
    // user auth
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdate: userUpdateReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userAuthenticated: userAuthenticationCheckReducer,
    updatePassword: resetPasswordReducer,
    // identity user
    identityUserCreate: identityUserCreateReducer,
    identityUserDetails: identityUserDetailsReducer,
    identityUserUpdate: identityUserUpdateReducer,
    // layout
    layoutProps: layoutReducer,
    // organisation
    organisationList: organisationListReducer,
    organisationCount: organisationCountReducer,
    organisationDetails: organisationDetailsReducer,
    organisationDelete: organisationDeleteReducer,
    organisationCreate: organisationCreateReducer,
    organisationUpdate: organisationUpdateReducer,
    // patient
    patientList: patientListReducer,
    patientCount: patientCountReducer,
    patientDetails: patientDetailsReducer,
    patientEmergencyDetails: patientEmergencyDetailsReducer,
    patientDelete: patientDeleteReducer,
    patientCreate: patientCreateReducer,
    patientUpdate: patientUpdateReducer,
    patientUpdateConsentIds: patientUpdateConsentIdsReducer,
    patientDto: patientDtoReducer,
    // ecg
    ecgList: ecgListReducer,
    ecgListByIDs: ecgListByIDsReducer,
    ecgCount: ecgCountReducer,
    ecgDetails: ecgDetailsReducer,
    ecgDelete: ecgDeleteReducer,
    ecgCreate: ecgCreateReducer,
    ecgUpdate: ecgUpdateReducer,
    // symptom
    symptomList: symptomListReducer,
    symptomListByIDs: symptomListByIDsReducer,
    symptomCount: symptomCountReducer,
    symptomDetails: symptomDetailsReducer,
    symptomDelete: symptomDeleteReducer,
    symptomCreate: symptomCreateReducer,
    symptomUpdate: symptomUpdateReducer,
    // aetiology
    aetiologyList: aetiologyListReducer,
    aetiologyListByIDs: aetiologyListByIDsReducer,
    aetiologyCount: aetiologyCountReducer,
    aetiologyDetails: aetiologyDetailsReducer,
    aetiologyDelete: aetiologyDeleteReducer,
    aetiologyCreate: aetiologyCreateReducer,
    aetiologyUpdate: aetiologyUpdateReducer,
    // implant
    implantList: implantListReducer,
    implantListByIDs: implantListByIDsReducer,
    implantCount: implantCountReducer,
    implantDetails: implantDetailsReducer,
    implantDetailsByBarcode: implantDetailsByBarcodeReducer,
    implantDelete: implantDeleteReducer,
    implantCreate: implantCreateReducer,
    implantUpdate: implantUpdateReducer,
    implantCreateOrUpdate: implantCreateOrUpdateReducer,
    implantDto: implantDtoReducer,
    // implantHistory
    implantHistoryList: implantHistoryListReducer,
    implantHistoryListByNhsNumber: implantHistoryListByNhsNumberReducer,
    implantHistoryCount: implantHistoryCountReducer,
    implantHistoryDetails: implantHistoryDetailsReducer,
    implantHistoryDelete: implantHistoryDeleteReducer,
    implantHistoryCreate: implantHistoryCreateReducer,
    implantHistoryUpdate: implantHistoryUpdateReducer,
    // implantInstance
    implantInstanceList: implantInstanceListReducer,
    implantInstanceListByNhsNumber: implantInstanceListReducer,
    implantInstanceCount: implantInstanceCountReducer,
    implantInstanceDetails: implantInstanceDetailsReducer,
    implantInstanceDelete: implantInstanceDeleteReducer,
    implantInstanceCreate: implantInstanceCreateReducer,
    implantInstanceUpdate: implantInstanceUpdateReducer,
    // implantCentre
    implantCentreList: implantCentreListReducer,
    implantCentreListByIDs: implantCentreListByIDsReducer,
    implantCentreCount: implantCentreCountReducer,
    implantCentreDetails: implantCentreDetailsReducer,
    implantCentreDelete: implantCentreDeleteReducer,
    implantCentreCreate: implantCentreCreateReducer,
    implantCentreUpdate: implantCentreUpdateReducer,
    // followupCentre
    followupCentreList: followupCentreListReducer,
    followupCentreListByIDs: followupCentreListByIDsReducer,
    followupCentreCount: followupCentreCountReducer,
    followupCentreDetails: followupCentreDetailsReducer,
    followupCentreDelete: followupCentreDeleteReducer,
    followupCentreCreate: followupCentreCreateReducer,
    followupCentreUpdate: followupCentreUpdateReducer,
    // shortUrl
    shortUrlList: shortUrlListReducer,
    shortUrlCount: shortUrlCountReducer,
    shortUrlDetails: shortUrlDetailsReducer,
    shortUrlDetailsByCode: shortUrlDetailsByCodeReducer,
    shortUrlDetailsByUserId: shortUrlDetailsByUserIdReducer,
    shortUrlDelete: shortUrlDeleteReducer,
    shortUrlCreate: shortUrlCreateReducer,
    shortUrlUpdate: shortUrlUpdateReducer,
    // template
    templateList: templateListReducer,
    templateCount: templateCountReducer,
    templateDetails: templateDetailsReducer,
    templateDetailsByName: templateDetailsByNameReducer,
    templateDelete: templateDeleteReducer,
    templateCreate: templateCreateReducer,
    templateUpdate: templateUpdateReducer,
    // smtpConfig
    smtpConfigList: smtpConfigListReducer,
    smtpConfigCount: smtpConfigCountReducer,
    smtpConfigDetails: smtpConfigDetailsReducer,
    smtpConfigDelete: smtpConfigDeleteReducer,
    smtpConfigCreate: smtpConfigCreateReducer,
    smtpConfigUpdate: smtpConfigUpdateReducer,
    // consent
    consentList: consentListReducer,
    consentListByIDs: consentListByIDsReducer,
    consentCount: consentCountReducer,
    consentDetails: consentDetailsReducer,
    consentDelete: consentDeleteReducer,
    consentCreate: consentCreateReducer,
    consentUpdate: consentUpdateReducer,
    
});

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
    userLogin: {
        userInfo: userInfoFromStorage,
    },
};



const middleware = [thunk];

const store = process.env.NODE_ENV === 'production' ?
    createStore(reducer, initialState, applyMiddleware(...middleware)) :
    createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;