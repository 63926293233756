import axios from 'axios';

import {
    TEMPLATE_LIST_REQUEST,
    TEMPLATE_LIST_SUCCESS,
    TEMPLATE_LIST_FAIL,

    TEMPLATE_DETAILS_REQUEST,
    TEMPLATE_DETAILS_SUCCESS,
    TEMPLATE_DETAILS_FAIL,

    TEMPLATE_DELETE_REQUEST,
    TEMPLATE_DELETE_SUCCESS,
    TEMPLATE_DELETE_FAIL,

    TEMPLATE_CREATE_REQUEST,
    TEMPLATE_CREATE_SUCCESS,
    TEMPLATE_CREATE_FAIL,

    TEMPLATE_UPDATE_REQUEST,
    TEMPLATE_UPDATE_SUCCESS,
    TEMPLATE_UPDATE_FAIL,

    TEMPLATE_REQUEST,
    TEMPLATE_FAIL,
    TEMPLATE_RESET,
    TEMPLATE_SUCCESS,

    TEMPLATE_COUNT_SUCCESS,
    TEMPLATE_COUNT_REQUEST,
    TEMPLATE_COUNT_FAIL,

    SET_CURRENT_PAGE,
    

} from "../constants/templateConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listTemplates = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEMPLATE_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/api/v1/template/all', config);

        dispatch({
            type: TEMPLATE_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: TEMPLATE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later templates",
        });
    }
}

// count templates
export const countTemplates = () => async (dispatch, getState) => {
    try {
      dispatch({ type: TEMPLATE_COUNT_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        },
      }
      const { data } = await axios.get('/api/v1/template/count', config);
  
      dispatch({
        type: TEMPLATE_COUNT_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: TEMPLATE_COUNT_FAIL,
        payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : "Failed to fetch template counts",
      });
    }
  }
  


export const getTemplateDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEMPLATE_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/api/v1/template/${_id}`, config);

        dispatch({
            type: TEMPLATE_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: TEMPLATE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getTemplateDetailsByName = (name) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEMPLATE_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/api/v1/template/name/${name}`, config);

        dispatch({
            type: TEMPLATE_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: TEMPLATE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteTemplate = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEMPLATE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/v1/template/${_id}/`,
            config
        )

        dispatch({
            type: TEMPLATE_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: TEMPLATE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createTemplate = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEMPLATE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const data = await axios.post(
            `/api/v1/template/`,
            changes,
            config
        )

        dispatch({
            type: TEMPLATE_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: TEMPLATE_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

const loadTemplateBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make template using a local template
export const makeTemplate = (file, templateId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const template = await loadTemplateBase64(file);
    const template_input = JSON.stringify({ "template": template });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const data = await axios.post(
        apiEndPoint,
        template_input,
        config
    )
    try {
        dispatch({
            type: TEMPLATE_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": template }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: TEMPLATE_SUCCESS
                });
                if (templateId && response.data) {
                    dispatch(updateTemplate(templateId, { template: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: TEMPLATE_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: TEMPLATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateTemplate = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TEMPLATE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/api/v1/template/${_id}/`,
            changes,
            config
        )

        dispatch({
            type: TEMPLATE_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: TEMPLATE_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: TEMPLATE_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}