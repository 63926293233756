import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineDone } from "react-icons/md";
import { BackLink, Button } from "nhsuk-react-components";
import axios from 'axios';


function ResetPasswordSetupScreen({ history, location }) {

    useEffect(() => {
        document.title = "Reset Password"; 
    }, []);


    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [resetMessage, setResetMessage] = useState("");


    const handleForgottenPassword = async () => {
        // use axios to send a request to the backend to send an email to the user
        // with a link to reset the password

        if (email === "") {
            alert("Please enter your email address");
            return;
        }

        try {
            setLoading(true);
            const response = await axios({
                method: 'post',
                url: `/api/v1/auth/forgotpassword`,
                data: {
                    email: email,
                    ClientURI: window.location.origin + "/resetpassword"
                }
            });
            setLoading(false);
            setResetMessage("Password reset link sent. Please check your email.");

        }

        catch (err) {
            setLoading(false);
            alert("An error occurred. Please try again later.");
        }

    }


    return (
        <React.Fragment>

            <div className="nhsuk-width-container">

                <main className="nhsuk-main-wrapper" id="maincontent" role="main">

                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">

                            <h1>Reset Password</h1>
                            <p> Enter the email address you used to register with us and we'll send you a link to reset your password.</p>
                            {resetMessage?
                                <div className="nhsuk-panel nhsuk-panel--confirmation nhsuk-u-margin-top-0 nhsuk-u-margin-bottom-4">
                                    <h4 className="nhsuk-panel__title" style={{color:"green"}}>
                                        <MdOutlineDone /> {resetMessage}
                                    </h4>
                                </div>
                                : null
                            }
                            <form onSubmit={handleForgottenPassword} className="nhsuk-form-group">

                                <label className="nhsuk-label" for="email">
                                    Email
                                </label>
                                <input
                                    className="nhsuk-input"
                                    id="email"
                                    disabled={loading}
                                    name="email"
                                    type="text"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />
                                <div className="nhsuk-u-padding-bottom-5"></div>
                                <Button type="submit" style={{ width: '100%' }} disabled={loading} onClick={handleForgottenPassword}>
                                    {loading ? 'Sending reset link ...' : 'Send reset link'}
                                </Button>
                            </form>


                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Go back

                                </BackLink>

                        </div>

                    </div>

                </main>
            </div >
        </React.Fragment >
    )
}

export default ResetPasswordSetupScreen;