export const ECG_LIST_REQUEST = 'ECG_LIST_REQUEST'
export const ECG_LIST_SUCCESS = 'ECG_LIST_SUCCESS'
export const ECG_LIST_FAIL = 'ECG_LIST_FAIL'


export const ECG_DETAILS_REQUEST = 'ECG_DETAILS_REQUEST'
export const ECG_DETAILS_SUCCESS = 'ECG_DETAILS_SUCCESS'
export const ECG_DETAILS_FAIL = 'ECG_DETAILS_FAIL'


export const ECG_DELETE_REQUEST = 'ECG_DELETE_REQUEST'
export const ECG_DELETE_SUCCESS = 'ECG_DELETE_SUCCESS'
export const ECG_DELETE_FAIL = 'ECG_DELETE_FAIL'

export const ECG_CREATE_REQUEST = 'ECG_CREATE_REQUEST'
export const ECG_CREATE_SUCCESS = 'ECG_CREATE_SUCCESS'
export const ECG_CREATE_FAIL = 'ECG_CREATE_FAIL'
export const ECG_CREATE_RESET = 'ECG_CREATE_RESET'


export const ECG_UPDATE_REQUEST = 'ECG_UPDATE_REQUEST'
export const ECG_UPDATE_SUCCESS = 'ECG_UPDATE_SUCCESS'
export const ECG_UPDATE_FAIL = 'ECG_UPDATE_FAIL'
export const ECG_UPDATE_RESET = 'ECG_UPDATE_RESET'

export const ECG_REQUEST = 'ECG_REQUEST'
export const ECG_SUCCESS = 'ECG_SUCCESS'
export const ECG_FAIL = 'ECG_FAIL'
export const ECG_RESET = 'ECG_RESET'

export const ECG_COUNT_REQUEST = "ECG_COUNT_REQUEST";
export const ECG_COUNT_SUCCESS = "ECG_COUNT_SUCCESS";
export const ECG_COUNT_FAIL = "ECG_COUNT_FAIL";

export const ECG_LIST_BYIDS_REQUEST = "ECG_LIST_BYIDS_REQUEST";
export const ECG_LIST_BYIDS_SUCCESS = "ECG_LIST_BYIDS_SUCCESS";
export const ECG_LIST_BYIDS_FAIL = "ECG_LIST_BYIDS_FAIL";
export const ECG_LIST_BYIDS_RESET = "ECG_LIST_BYIDS_RESET";

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'